import { Flex, Box, Image, Heading } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";
import { BiMessageSquareAdd, BiTable, BiHome, BiStats } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router";
import { AiOutlineDashboard } from "react-icons/ai";
// import blacklist from "../../assets/images/blacklist.svg";
import blacklist from "../../assets/appLogos/Blacklist.webp";

export default function NavbarBlacklist() {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  return (
    <Flex
      justify={`space-between`}
      align="center"
      h="70px"
      w="100%"
      boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
      color={"#000"}
      bg="#fff"
      px="20px">
      <Flex w="20%">
        {/* <Flex flexDir={"row"}> */}
        <Image src={blacklist} rounded={"xl"} h="60px" />
        <Image
          src={logo}
          w="auto"
          h="60px"
          opacity={1}
          onClick={() => navigate("/apps")}
        />
        <Image
          src={logo1}
          w="auto"
          h="60px"
          opacity={1}
          onClick={() => navigate("/apps")}
        />
        {/* </Flex> */}
        {/* <Image src={logo} h="50px" w="auto" /> */}
      </Flex>
      <Flex justify={`space-around`} align="center" color="gray.50" w="60%">
        <Flex
          // _hover={{
          //   bgColor: "gray.50",
          //   color: colors.ocean,
          //   FlexShadow: "lg",
          //   cursor: "pointer",
          // }}
          // p="10px"
          // borderRadius={20}
          className="nav__bar__link"
          onClick={() => navigate("/apps")}
          cursor="pointer"
          bgColor={location === "/apps" && "gray.50"}
          color={location === "/apps" && colors.ocean}
          boxShadow={location === "/apps" && "lg"}>
          <Box>
            <BiHome
              color={location === "/apps" ? "white" : "black"}
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading color={location === "/apps" ? "white" : "black"} size="xs">
              &nbsp; Apps
            </Heading>
          </Box>
        </Flex>

        <Flex
          // _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          // p="10px"
          // borderRadius={20}
          className={`${
            location === "/apps/tttblacklist/new-blacklisted"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          onClick={() => navigate("/apps/tttblacklist/new-blacklisted")}
          cursor="pointer"
          // bgColor={
          //   location === "/apps/tttblacklist/new-blacklisted" && "gray.50"
          // }
          // color={
          //   location === "/apps/tttblacklist/new-blacklisted" && colors.ocean
          // }
          boxShadow={location === "/apps/tttblacklist/new-blacklisted" && "lg"}>
          <Box>
            <BiMessageSquareAdd
              color={
                location === "/apps/tttblacklist/new-blacklisted"
                  ? "white"
                  : "black"
              }
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              size="xs"
              color={
                location === "/apps/tttblacklist/new-blacklisted"
                  ? "white"
                  : "black"
              }>
              &nbsp; New blacklisted
            </Heading>
          </Box>
        </Flex>

        <Flex
          // _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          // p="10px"
          // borderRadius={20}
          // className="nav__bar__link"
          className={`${
            location === "/apps/tttblacklist/blacklist"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          onClick={() => navigate("/apps/tttblacklist/blacklist")}
          cursor="pointer"
          bgColor={location === "/apps/tttblacklist/blacklist" && "gray.50"}
          color={location === "/apps/tttblacklist/blacklist" && colors.ocean}
          boxShadow={location === "/apps/tttblacklist/blacklist" && "lg"}>
          <Box>
            <BiTable
              color={
                location === "/apps/tttblacklist/blacklist" ? "white" : "black"
              }
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              size="xs"
              color={
                location === "/apps/tttblacklist/blacklist" ? "white" : "black"
              }>
              &nbsp; Blacklist
            </Heading>
          </Box>
        </Flex>

        {/* <Flex
          _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          p="10px"
          borderRadius={20}
          onClick={() => navigate("/apps/tttblacklist/available-licenses")}
          cursor="pointer"
          bgColor={location === "/apps/tttblacklist/available-licenses" && "gray.50"}
          color={location === "/apps/tttblacklist/available-licenses" && colors.ocean}
          boxShadow={location === "/apps/tttblacklist/available-licenses" && "lg"}
        >
          <Box>
            <BiTable size="20px" />
          </Box>
          <Box mt="2px">
            <Heading size="xs">&nbsp; Available Licenses</Heading>
          </Box>
        </Flex> */}
      </Flex>
    </Flex>
  );
}
