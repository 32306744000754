import { useState, useRef, useEffect } from "react";
import {
  Button,
  useToast,
  Heading,
  Text,
  Spinner,
  Badge,
} from "@chakra-ui/react";
import { client } from "../../utils/axios";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import {
  Box,
  Flex,
  Input,
  chakra,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { saveAs } from "file-saver";

import { BiSearch } from "react-icons/bi";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
//   import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../node_modules/react-super-responsive-table/dist/index";
import usePostActiveAccounts from "../../hooks/useMutations/usePostActiveAccounts";
import { useRecoilState, useRecoilValue } from "recoil";
import { colors } from "../../utils/colors";
import { filterCertifSelector, filterCertifState } from "../../recoil";

import "./style.css";
import useGetCertif from "../../hooks/useQueries/useGetCertif";
import { Document, Page, pdfjs } from "react-pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { PDFDocument } from "pdf-lib";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { scale } from "chroma-js";

// import SingleCoupon from "./SingleCoupon";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// const getFilePluginInstance = getFilePlugin();

const headers = [
  { label: "Account name", key: "name" },
  { label: "first Name", key: "user.name_f" },
  { label: "Last Name", key: "user.name_l" },
  { label: "Email", key: "user.email" },
  { label: "Phone", key: "user.phone" },
  { label: "UserId", key: "user.user_id" },
  { label: "Tradovate ID", key: "user.tradovate_id" },
];

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

const AccountCertificate = () => {
  const [email, setEmail] = useState("");

  const query = useRecoilValue(filterCertifSelector);

  const [filterCertif, setFilterCertif] = useRecoilState(filterCertifState);
  const [accountName, setAccountName] = useState(filterCertif.accountName);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);

  const base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = window.atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length).map((_, i) =>
      byteCharacters.charCodeAt(i)
    );
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };
  const { check, refetchCheck, error } = useGetCertif(query);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // const wihtouPrefix = check.substr("data:application/pdf;base64".length);
  // // btoa(unescape(encodeURIComponent(str)));
  // const pdfData = window.atob(wihtouPrefix); // Decode the Base64 or binary data

  // // Convert to Blob
  // const byteArray = new Uint8Array(
  //   Array.from(pdfData).map((char) => char.charCodeAt(0))
  // );
  useEffect(() => {
    // const processBlob = async () => {
    if (check) {
      // const test = check.slice(1);

      // const blob = base64ToBlob(test, "application/pdf");
      const pdfUrl = URL.createObjectURL(check);
      setPdfBlob(check);
      setPdfUrl(pdfUrl);

      // const pdfUrl = URL.createObjectURL(test);
      // return pdfUrl;
      // setPdfBlob(test);
      // setPdfUrl(pdfUrl);
    } else {
      setPdfBlob(null);
      setPdfUrl(null);
    }
    // processBlob();
  }, [check]);

  let toast = useToast();
  document.title = "TTTSubscriptions | Target Reach Checker";
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => accountName + "_certificate.pdf",
  });
  const { Download } = getFilePluginInstance;
  //   console.log("data = ", check);
  const handleDownload = () => {
    if (pdfBlob) {
      // saveAs(pdfBlob, `${accountName}_certificate.pdf`);
      // const file = new Blob([pdfBlob], { type: "application/pdf" });
      // // Build a URL from the file
      const fileURL = URL.createObjectURL(pdfBlob);
      // Open the URL on new Window
      window.open(pdfBlob);
    }
  };
  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Flex align="center" justify="space-evenly" pb="40px">
          <Flex w={"30%"} flexDir={"column"}>
            <Box>
              <Heading color={"black"} size="md">
                certificate Checker
              </Heading>
            </Box>
          </Flex>

          <Flex align="center" className="coupon" justify={`center`} w="70%">
            {/* <Box w="90%">
              <Input
                type="text"
                placeholder={`Search by email...`}
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  setSearchBy("email");
                  setSearch(e.target.value);
                }}
              />
            </Box> */}
            <Box w="90%">
              <Input
                type="text"
                placeholder={`Enter  Account Name...`}
                borderColor={colors.blue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  //   setSearchBy("coupon");
                  setAccountName(e.target.value);
                  if (e.target.value === "")
                    setFilterCertif({
                      accountName: "",
                    });
                }}
              />
            </Box>
          </Flex>
          <Box w="50%" ml={2}>
            <Button
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              className="app_btn"
              color="#ffff"
              width={"30%"}
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              isDisabled={!!accountName ? false : true}
              onClick={() => {
                setFilterCertif({
                  accountName: accountName,
                });
              }}>
              <BiSearch size="20px" />
            </Button>
          </Box>

          {/* <Buttonconst handleDownload = () => {
    if (pdfBlob) {
      saveAs(pdfBlob, `${accountName}_certificate.pdf`);
    }
  };
            padding={"10px 30px"}
            rightIcon={<AiOutlineEye size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn"
            onClick={() => {
            
            }}>
            View all
          </Button> */}
        </Flex>

        <Box borderRadius={15} bgColor="gray.50" color="#000" maxH="550px">
          {pdfBlob ? (
            <>
              <Badge
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                p={8}
                colorScheme={"whatsapp"}>
                Certificate Available
              </Badge>

              {/* Display the PDF */}
              <Box
                display="flex"
                flexDir={"column"}
                justifyContent="center"
                alignItems={"center"}
                w={"100%"}
                gap={10}
                h={"50%"}
                mt={4}>
                {/* <Document
                  file={{
                    data: pdfUrl,
                  }}>
                  <Page pageNumber={1} />
                </Document> */}
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Box display="flex" gap={10} justifyContent="center" mt={4}>
                    {/* <Button
                  colorScheme="blue"
                  onClick={handleDownload}
                  borderRadius={20}>
                  Download Certificate
                </Button> */}
                    <div style={{ marginTop: "10px" }}>
                      <Download>
                        {(props) => (
                          <button
                            style={{
                              backgroundColor: "#4CAF50",
                              color: "white",
                              padding: "10px 20px",
                            }}
                            onClick={() => {
                              props.onClick();
                            }}>
                            Download
                          </button>
                        )}
                      </Download>
                    </div>
                  </Box>
                  <Viewer
                    style={{ width: "100%", height: "100%", scale: 0.7 }}
                    fileUrl={pdfUrl}
                    plugins={[getFilePluginInstance, defaultLayoutPlugin]}
                  />
                </Worker>
                {/* <iframe
                  src={pdfUrl}
                  width="100%"
                  height="600px"
                  title="PDF Document"
                /> */}
              </Box>

              {/* Download Button */}
            </>
          ) : (
            <Badge
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              p={8}
              colorScheme={"red"}>
              No Certificate Found
            </Badge>
          )}
        </Box>
      </Box>

      {/* <Flex ml="7%" mt={5}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<BiFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn">
            Per Page: {limit}
          </MenuButton>
          <MenuList color={colors.ocean} fontSize="14px">
            <MenuItem onClick={() => setLimit(10)}>10</MenuItem>
            <MenuItem onClick={() => setLimit(15)}>15</MenuItem>
            <MenuItem onClick={() => setLimit(20)}>20</MenuItem>
            <MenuItem onClick={() => setLimit(25)}>25</MenuItem>
            <MenuItem onClick={() => setLimit(30)}>30</MenuItem>
            <MenuItem onClick={() => setLimit(35)}>35</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex align="center" marginTop={-10} justify="center">
        <Pagination1
          pagesQuantity={Math.ceil(email?.total / limit)}
          curPage={page}
          setCurPage={setPage}
        />
      </Flex> */}

      <Modal
        isOpen={isOpen}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: "6xl",
        }}
        onClose={() => {
          onClose();
          setEmail("");
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">Coupon Detail {email}</ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody>{/* <SingleCoupon coupon={coupon} /> */}</ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AccountCertificate;
