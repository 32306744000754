export const arrayToCSV = (arr) => {
	const csvRows = [];
	// Get headers
	const headers = Object.keys(arr[0]);
	csvRows.push(headers.join(','));

	// Loop over the rows
	for (const row of arr) {
		const values = headers.map(header => {
			const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape double quotes
			return `"${escaped}"`;
		});
		csvRows.push(values.join(','));
	}

	return csvRows.join('\n');
};
