import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const useLicenseMutation = () => {
  const auth = useAuth();
  const { mutateAsync: addLicense } = useMutation((data) => {
    console.log(auth.user);
    console.log(data);
    return client.post(
      `${process.env.REACT_APP_API_HOST}/Licenses`,
      { ...data },
      {
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      }
    );
  });

  const { mutateAsync: deleteLicense } = useMutation((id) =>
    client.delete(`${process.env.REACT_APP_API_HOST}/Licenses/${id}`, {
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    })
  );

  return { addLicense, deleteLicense };
};

export default useLicenseMutation;
