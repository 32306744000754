import React, { useState } from "react";
import { Box, Heading, VStack, Image } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { colors } from "../../utils/colors";
import useSubscriptions, {
  useSubscriptionCount,
} from "../../hooks/useQueries/useSubscriptions";
import jigsaw from "../../assets/jigsaw.png";
import bookmap from "../../assets/bookmap.png";
import tradingView from "../../assets/tradingview.png";
import ninjaTrader from "../../assets/ninjatrader.webp";
import axios from "axios";
import { useAuth } from "../../utils/auth";

export default function TTTSubscriptionsHomepage() {
  const { subscriptionsCount } = useSubscriptionCount();

  const counts = {};

  subscriptionsCount?.forEach((item) => {
    counts[item._id] = item.count;
  });

  const jigsawSub = counts.Jigsaw;
  const tradingViewSub = counts["Trading view"];
  const bookmapSub = counts["Bookmap"];
  const ninjaTraderSub = counts["Ninja Trader"];
  // const { jigsawSub, tradingViewSub, hello, bye } = Object.fromEntries(
  //   array.map(({ id, count }) => [id, count])
  // );
  document.title = "TTTSubscriptions | Stats";

  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(4, 1fr)"
      gap={4}
      h="calc(100vh - 70px)"
      p="50px"
    >
      <GridItem
        colSpan={2}
        // bgGradient={`linear(to-l, ${colors.ocean}, ${colors.lightBlue})`}
        className="sidebar_nav"
        borderRadius={20}
        display="flex"
        justifyContent={`center`}
        alignItems="center"
        color={colors.light}
      >
        <VStack align={"center"} spacing={5}>
          <Image
            src={bookmap}
            h="40px"
            w="auto"
            style={{ filter: "invert(1)" }}
          />
          <Heading size="lg" color="black">
            {" "}
            {bookmapSub} subscriptions
          </Heading>
        </VStack>
      </GridItem>
      <GridItem
        colSpan={2}
        color={colors.light}
        borderRadius={20}
        // bgGradient={`linear(to-r, ${colors.ocean}, ${colors.lightBlue})`}
        className="sidebar_nav"
        display="flex"
        justifyContent={`center`}
        alignItems="center"
      >
        <VStack align={"center"} spacing={5}>
          <Image src={jigsaw} h="50px" w="auto" />
          <Heading size="lg" color="black">
            {" "}
            {jigsawSub} subscriptions
          </Heading>
        </VStack>
      </GridItem>
      <GridItem
        colSpan={2}
        // bgGradient={`linear(to-l, ${colors.ocean}, ${colors.lightBlue})`}
        className="sidebar_nav"
        borderRadius={20}
        display="flex"
        justifyContent={`center`}
        alignItems="center"
        color={colors.light}
      >
        <VStack align={"center"} spacing={5}>
          <Image src={ninjaTrader} h="70px" w="auto" />
          <Heading size="lg" color="black">
            {" "}
            {ninjaTraderSub} subscriptions
          </Heading>
        </VStack>
      </GridItem>
      <GridItem
        colSpan={2}
        color={colors.light}
        borderRadius={20}
        // bgGradient={`linear(to-r, ${colors.ocean}, ${colors.lightBlue})`}
        className="sidebar_nav"
        display="flex"
        justifyContent={`center`}
        alignItems="center"
      >
        <VStack align={"center"} spacing={5}>
          <Image src={tradingView} h="70px" w="auto" />
          <Heading size="lg" color="black">
            {" "}
            {tradingViewSub} subscriptions
          </Heading>
        </VStack>
      </GridItem>
    </Grid>
  );
}
