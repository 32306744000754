import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  FormLabel,
  FormControl,
  Switch,
  Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import React, { useState, useRef } from "react";
import { colors } from "../../utils/colors";
import { BiChevronDown, BiDetail, BiSearch, BiTrash } from "react-icons/bi";
// import {
//    } from "react-super-responsive-table";

import "../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useBlacklist from "../../hooks/useQueries/useBlacklist";
import useBlacklistMutation from "../../hooks/useMutations/useBlacklistMutation";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  filterJournalytixSelector,
  filterJournalytixState,
} from "../../recoil";
import { BiFilter } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import useJournalytixList from "../../hooks/useQueries/useJournalytix";
import { useAuth } from "../../utils/auth";
import axios from "axios";
import { useNavigate } from "react-router";
import { countriesList } from "./CreateJournalytix";
import { useEffect } from "react";
import { client } from "../../utils/axios";
import EditJournalitix from "./EditJournalytix";
import Session from "supertokens-auth-react/recipe/session";
import Pagination1 from "../../utils/pagination";
import { Spinner } from "@chakra-ui/react";

const headers = [
  { label: "First Name", key: "FirstName" },
  { label: "Last Name", key: "LastName" },
  { label: "Email", key: "Email" },
  { label: "login", key: "Username" },
];

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

function ListJournalytix() {
  const [searchBy, setSearchBy] = useState("Email");
  const [search, setSearch] = useState("");
  let sessionContext = Session.useSessionContext();
  const [importDone, setImportDone] = useState(false);

  const query = useRecoilValue(filterJournalytixSelector);
  const toast = useToast();
  const [filterBlacklist, setFilterBlacklist] = useRecoilState(
    filterJournalytixState
  );
  const [csvReport, setSCVReport] = useState({ data: [] });

  const { jounalytixList, refetchJounalytixList } = useJournalytixList(query);

  const auth = useAuth()?.user;
  const clickRef = useRef();
  const [exportCheck, setExportCheck] = useState(false);

  function formatDateDifference(date) {
    const endDate = new Date(date);
    const diffInMilliseconds = Date.parse(endDate) - Date.parse(new Date());

    const years = Math.floor(diffInMilliseconds / (365 * 24 * 60 * 60 * 1000));
    const months = Math.floor(
      (diffInMilliseconds % (365 * 24 * 60 * 60 * 1000)) /
        (30 * 24 * 60 * 60 * 1000)
    );
    const days = Math.floor(
      (diffInMilliseconds % (30 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
    );

    console.log("diff mili", diffInMilliseconds, "year", years);
    const formattedDifference = [];

    if (years > 0) {
      formattedDifference.push(`${years} year${years > 1 ? "s" : ""}`);
    }

    if (months > 0) {
      formattedDifference.push(`${months} month${months > 1 ? "s" : ""}`);
    }

    if (days > 0) {
      formattedDifference.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (formattedDifference.length === 0) return "Expired";

    console.log("forma", formattedDifference);
    return formattedDifference.join(", ");
  }

  const importData = async () => {
    setImportDone(true);
    toast({
      position: "top",
      duration: 5 * 1000,
      status: "info",
      render: () => (
        <Box
          bgColor="blue.400"
          borderRadius="5px"
          color="#f5f5f5"
          p="20px"
          textAlign="center">
          <Heading fontSize="sm" textTransform="uppercase">
            Journalitix Data is being imported
          </Heading>
        </Box>
      ),
    });
    const res = await client.get(
      `${process.env.REACT_APP_API_HOST}/journalitix/import`
    );
    console.log("res import = ", res);
    refetchJounalytixList();
    setImportDone(false);
    if (res.status === 200)
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              Journalitix Data is imported
            </Heading>
          </Box>
        ),
      });
  };

  useEffect(() => {
    return () => {
      setSearch("");
      setFilterBlacklist({
        ...filterBlacklist,
        Username: "",
        Email: "",
        [searchBy]: search,
        page: 1,
      });
      refetchJounalytixList();
    };
  }, []);
  useEffect(() => {
    if (exportCheck)
      if (csvReport?.data.length > 0) {
        clickRef.current.link.click();
        setExportCheck(false);
      }
  }, [csvReport]);

  const exportReport = async () => {
    console.log("exportReport");
    setExportCheck(true);
    await client
      .get(`${process.env.REACT_APP_API_HOST}/journalitix?noPagin=true`, {
        // headers: {
        //   "auth-token": auth.accessToken,
        // },
      })
      .then((res) => {
        setSCVReport({
          data: res?.data?.docs.map((e) => e),
          headers: headers,
          filename: `Journalytix - ${new Date()}.csv`,
        });
        console.log("exportReport", res);
        // csvReport = {
        //   data: res?.data?.map((e) => e),
        //   headers: headers,
        //   filename: `Bookmap Licenses - ${new Date()}.csv`,
        // if (res.data.length > 0)
        // if (csvReport?.data.length > 0) clickRef.current.link.click();
        // };
      });
  };
  const setPage = () => {
    if (jounalytixList?.hasNextPage) {
      setFilterBlacklist({
        ...filterBlacklist,
        page: jounalytixList?.nextPage,
      });
    }
  };

  const inputRef = useRef(null);

  return (
    <div>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 70px)"
        color="gray.50"
        overflow="auto">
        <Flex align="center" justify="space-between" pb="40px">
          <Box w="20%">
            <Heading size="lg">Journalytix list</Heading>
          </Box>
          <Flex align="center" justify={`center`} w="100%">
            <Box w="20%">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiFilter size="20px" />}
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  boxShadow="lg"
                  color="#ffff"
              className="app_btn"

                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  >
                  Search by: {searchBy}
                </MenuButton>
                <MenuList color={colors.ocean} fontSize="14px">
                  {/* <MenuItem onClick={() => setSearchBy("fullName")}>
                    First Name
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("country")}>
                    Last Name
                  </MenuItem> */}
                  <MenuItem onClick={() => setSearchBy("Email")}>
                    Email
                  </MenuItem>
                  {/* <MenuItem onClick={() => setSearchBy("accountType")}>
                    product
                  </MenuItem> */}
                  <MenuItem onClick={() => setSearchBy("Login")}>
                    login
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>

            <Box w="70%">
              <Input
                type="text"
                placeholder={`Search by ${searchBy}...`}
                borderColor={colors.blue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => setSearch(e.target.value)}
                ref={inputRef}
              />
            </Box>

            <Box w="10%" ml="5px">
              <Button
                borderRadius={50}
                fontSize="12px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="app_btn"
                boxShadow="lg"
                color="#ffff"
                // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                isDisabled={!!search ? false : true}
                onClick={() => {
                  console.log("search by ", searchBy, " search , ", search);
                  if (searchBy === "Email")
                    setFilterBlacklist({
                      ...filterBlacklist,
                      // firstName: "",
                      Email: search,
                      // lastName: "",
                      Username: "",
                      page: 1,
                    });
                  if (searchBy === "Login")
                    setFilterBlacklist({
                      ...filterBlacklist,
                      // firstName: "",
                      Email: "",
                      // lastName: "",
                      Username: search,
                      page: 1,
                    });
                  //   refetchJounalytixList();
                }}>
                <BiSearch size="15px" />
              </Button>
            </Box>

            <Button
              rightIcon={<AiOutlineEye size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"

              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setFilterBlacklist({
                  Email: "",
                  login: "",
                  page: 1,
                });
              }}>
              View all
            </Button>
          </Flex>
          <Box pt="0px">
            <Button
              onClick={() => exportReport()}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, green.400, green.500)`}
              boxShadow="lg"
              className="app_btn"

              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, green.400, green.500)` }}
              w="100%">
              Export All
            </Button>
            {!!csvReport?.data.length > 0 && (
              <CSVLink ref={clickRef} {...csvReport}></CSVLink>
            )}
          </Box>
          <Box pt="0px">
            <Button
              onClick={() => importData()}
              disabled={importDone}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, green.400, green.500)`}
              className="app_btn"

              boxShadow="lg"
              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, green.400, green.500)` }}
              w="100%">
              {importDone ? (
                <>
                  <Flex alignItems={"center"} gap={3}>
                    <Text>Please wait</Text>
                    <Spinner />
                  </Flex>
                </>
              ) : (
                <Text>Import All </Text>
              )}
            </Button>
          </Box>
        </Flex>
        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px">
          {jounalytixList?.docs?.length > 0 ? (
            <Table borderRadius={20} variant='striped'>
              <Thead
                fontSize="15px"
                h="40px"
                className="table__head"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                >
                <Tr
                  textAlign="center"
                  textTransform="capitalize"
                  className="table__head"
                  h="30px"
                  borderBottomWidth={3}
                  borderRadius="20px">
                  <Th color='#fff'>First Name</Th>
                  <Th color='#fff'>Last Name</Th>
                  <Th color='#fff'>Email</Th>
                  <Th color='#fff'>Login</Th>
                  <Th color='#fff'>Experation Date</Th>
                  {(sessionContext?.accessTokenPayload["st-role"]?.v.includes(
                    "MASTER"
                  ) ||
                    sessionContext?.accessTokenPayload["st-role"]?.v.includes(
                      "JOURNALITIX"
                    )) && <Th textAlign="center" color='#fff'>Action</Th>}
                  {/* <Th>Actions</Th> */}
                </Tr>
              </Thead>
              <Tbody
                // bgGradient={`linear(to-l, gray.100, gray.300)`}
                color='black'>
                {jounalytixList?.docs?.map((journalitix, i) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                    >
                    <Td py="7px">{journalitix?.FirstName}</Td>
                    <Td py="7px">{journalitix?.LastName}</Td>
                    <Td py="7px">{journalitix?.Email}</Td>
                    <Td py="7px">{journalitix?.Username}</Td>
                    <Td py="7px">
                      {formatDateDifference(journalitix?.endDate)}
                    </Td>
                    {(sessionContext?.accessTokenPayload["st-role"]?.v.includes(
                      "MASTER"
                    ) ||
                      sessionContext?.accessTokenPayload["st-role"]?.v.includes(
                        "JOURNALITIX"
                      )) && (
                      <Td>
                        <Flex justify="space-evenly" align="center">
                          {/* <EditSubscription subscription={subscription} /> */}
                          <EditJournalitix journalitix={journalitix} />
                        </Flex>
                      </Td>
                    )}
                    {/* <Td py="7px">{jounalytix?.accountID}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}>
              No data to display !!
            </Flex>
          )}
        </Box>

        <Box>
          <Flex justify="center" gap={10} mt={5}>
            <Box>
              <Button
                onClick={() => {
                  if (jounalytixList?.hasPrevPage) {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      page: jounalytixList?.prevPage,
                    });
                  }
                }}
                disabled={!jounalytixList?.hasPrevPage}
              className="app_btn"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                color="#ffff"
                // _hover={{
                //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                // }}
                >
                Previous
              </Button>
            </Box>
            <Box>
              {/* <Flex gap={5}>
                {Array.from(
                  {
                    length: jounalytixList?.totalPages,
                  },
                  (_, i) => i + 1
                ).map((i) => (
                  <Button
                    key={i}
                    onClick={() => {
                      setFilterBlacklist({
                        ...filterBlacklist,
                        page: i,
                      });
                    }}
                    disabled={jounalytixList?.page === i}
                    bgGradient={
                      jounalytixList?.page === i
                        ? `linear(to-b, #46ced8, #008ab4)`
                        : `linear(to-b, #f5f5f5, #f5f5f5)`
                    }
                    color={
                      jounalytixList?.page === i
                        ? `#ffff`
                        : `rgba(0, 0, 0, 0.65)`
                    }
                    _hover={{
                      bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                    }}>
                    {i}
                  </Button>
                ))}
              </Flex> */}
              <Pagination1
                pagesQuantity={jounalytixList?.totalPages}
                curPage={jounalytixList?.page}
                setCurPage={setPage}
              />
            </Box>
            <Box>
              <Button
                onClick={() => {
                  if (jounalytixList?.hasNextPage) {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      page: jounalytixList?.nextPage,
                    });
                  }
                }}
                disabled={!jounalytixList?.hasNextPage}
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                color="#ffff"
              className="app_btn"

                // _hover={{
                //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                // }}
                >
                Next
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
    </div>
  );
}

export default ListJournalytix;
