import { Image, Stack, Text, Heading, Button } from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
const AppCardLayout = ({ img, appName, description, Link }) => {
  const redirection = () => {
    window.open(Link, "_blank");
  };
  return (
    <>
      <Card
        h={"300px"}
        bg={"gray.100"}
        w={"full"}
        shadow={"xl"}
        p={8}
        boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
        rounded={"xl"}
        direction={{ base: "column", sm: "row" }}
        _hover={{
          background: "#CBD5E0",
          boxShadow: "0px 0px 8px rgba(66, 153, 225, 0.6)",
          // padding: "24px",
          // borderRadius: "5%", // Same as the card's border-radius
          // transform: "translateY(-4px)",
          // transition: "all 0.3s ease-in-out",
        }}
        overflow="hidden"
        variant="outline">
        <Image
          my={"auto"}
          p={5}
          objectFit="cover"
          rounded={"full"}
          h={"200px"}
          maxW={{ base: "100%", sm: "200px" }}
          src={img}
          alt={`${appName} Logo`}
        />

        <Stack h={"full"}>
          <CardBody minH={"180px"} h={"180px"}>
            <Heading size="md">{appName}</Heading>

            <Text py="10px">{description}</Text>
          </CardBody>

          <CardFooter>
            <Button
              style={{
                backgroundImage:
                  "radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%)",
              }}
              color="white"
              w="full"
              onClick={redirection}
              variant="solid">
              Read The Doc
            </Button>
          </CardFooter>
        </Stack>
      </Card>
    </>
  );
};
export default AppCardLayout;
