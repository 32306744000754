import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery, useQueryClient } from "react-query";
import { getstrquery } from "../../utils/query.js";

const getMultipleAccounts = (query, auth) => async () => {
	  const postData = getstrquery(query);
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/funded/multiple-account?${postData}`,
    {
      method: "GET",
    }
  );
  return data;
};

const useAllMultipleAccountlists = (query) => {
	const queryClient = useQueryClient();
  const auth = useAuth();
  return {
    multipleAccounts: useQuery(
      ["multiple-account", getstrquery(query)],
      getMultipleAccounts(query, auth),
      {
        staleTime: ms("1s"),
        refetchInterval: ms("5m"),
        refetchOnMount: "always",
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchMultipleAccountlists: () => queryClient.refetchQueries(["multiple-account", getstrquery(query)]),
  };
};

export default useAllMultipleAccountlists;
