import React, { useEffect, useState } from "react";
import { getCoupon, useCouponUsers } from "../../hooks/useQueries/useCoupons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Toast,
  Tr,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { colors } from "../../utils/colors";
import { BiTrash } from "react-icons/bi";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import useCouponlistMutation from "../../hooks/useMutations/useCouponlistMutation";

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

const SingleCoupon = ({ coupon }) => {
  const { couponUsers, refetchCouponUsers } = useCouponUsers(coupon);

  const { deleteCouponlist } = useCouponlistMutation();
  const toast = useToast();

  const roles = useSessionContext().accessTokenPayload["st-role"]?.v;

  const deleteCoupon = async (couponDelete) => {
    try {
      console.log(couponDelete["custom_coupon_id"]);
      await deleteCouponlist(couponDelete["custom_coupon_id"]);
      refetchCouponUsers();

      toast({
        position: "bottom",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="red.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="10px"
            px="10px"
            textAlign="center"
          >
            <Heading fontSize="sm">Coupon deleted !!</Heading>
          </Box>
        ),
      });
    } catch (error) {
      console.dir("error", error);
    }
  };

  return (
    <Grid gap={2} w="100%" divider={<StackDivider borderColor="gray.200" />}>
      {couponUsers?.length > 0 && (
        <CustomTable borderRadius={20}>
          <CustomThead
            fontSize="15px"
            h="15px"
            bgGradient={`linear(to-b, #46ced8, #008ab4)`}
          >
            <CustomTr
              textAlign="center"
              textTransform="capitalize"
              h="30px"
              borderBottomWidth={1}
              borderRadius="20px"
            >
              <CustomTh textAlign="center">Email</CustomTh>
              <CustomTh textAlign="center">Action</CustomTh>
            </CustomTr>
          </CustomThead>

          <CustomTbody
            bgGradient={`linear(to-l, gray.100, gray.300)`}
            color={colors.ocean}
          >
            {couponUsers.map((coupon, i) => (
              <CustomTr
                textAlign="center"
                fontSize={{
                  "2xl": "13px",
                  xl: "13px",
                  md: "12px",
                  lg: "12px",
                  sm: "10px",
                }}
                key={i}
                borderBottom="1px"
                borderColor={colors.lightBlue}
              >
                <CustomTd h="15px" textAlign="center">
                  {coupon.email}{" "}
                </CustomTd>
                <CustomTd p={0}>
                  <Flex justify="space-evenly" align="center">
                    <Popover>
                      <PopoverTrigger>
                        <Button
                          disabled={!roles?.includes("MASTER", "COUPON")}
                          bgColor="transparent"
                          color="red.600"
                          align="center"
                          _hover={{ cursor: "pointer", color: "red.500" }}
                        >
                          <BiTrash size="20px" />
                        </Button>
                      </PopoverTrigger>

                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader
                          fontSize="16px"
                          color="red.500"
                          fontWeight={`bold`}
                        >
                          Confirmation!
                        </PopoverHeader>
                        <PopoverBody>
                          <Text size="12px" pb="10px">
                            Are you sure you want to delete this coupon ?
                          </Text>
                          <Button
                            disabled={
                              //   !roles?.includes("PayoutMaster") &&
                              !roles?.includes("MASTER", "COUPON")
                            }
                            size="sm"
                            bgGradient={`linear(to-b, red.300, red.500)`}
                            color="gray.50"
                            _hover={{
                              bgGradient: `linear(to-t, red.300, red.500)`,
                            }}
                            boxShadow="lg"
                            onClick={() => deleteCoupon(coupon)}
                          >
                            Yes, I'm sure
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                </CustomTd>
              </CustomTr>
            ))}
          </CustomTbody>
        </CustomTable>
      )}
    </Grid>
  );
};

export default SingleCoupon;
