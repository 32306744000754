import {
  Box,
  Heading,
  Grid,
  GridItem,
  Image,
  Flex,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import blacklist from "../../../assets/appLogos/Blacklist.webp";
import bookmap from "../../../assets/appLogos/Bookmap.jpg";
import subscriptions from "../../../assets/appLogos/Subscriptions.webp";
import payout from "../../../assets/appLogos/PayoutApp.webp";
import payment from "../../../assets/appLogos/PaymentsApp.webp";
import funded from "../../../assets/appLogos/PerformanceAccount.webp";
import reset from "../../../assets/appLogos/ResetAccounts.webp";

import journalitix from "../../../assets/appLogos/Journalityx.png";
import { useAuth } from "../../../utils/auth";
import Session from "supertokens-auth-react/recipe/session";
import axios from "axios";
import coupons from "../../../assets/appLogos/CouponWhitelist.webp";
import { client } from "../../../utils/axios";
import contactSupport from "../../../assets/images/contact-img.png";
import AppCardLayout from "../../../components/HomePageLayouts/appCardLayout";
import { CustomBox } from "../../../components/HomePageLayouts/oldDesignLayout";
import { BsGridFill } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import tradovate from "../../../assets/appLogos/tradovate.png";
import activeAccounts from "../../../assets/appLogos/activeAccounts.jpg";
import TR from "../../../assets/appLogos/TR.jpg";
import certif from "../../../assets/appLogos/certif.png";
export default function Apps() {
  const navigate = useNavigate();
  let auth = useAuth();

  const [user, setUser] = useState();
  let sessionContext = Session.useSessionContext();
  // console.log("session s context", sessionContext);

  async function getUser() {
    try {
      // console.log("session", sessionContext);

      // if (sessionContext.loading === false) {
      // const user1 = await axios.get(
      //   `http://localhost:4000/user/${response?.user.id}`
      // );
      const user = await client.get(`${process.env.REACT_APP_API_HOST}/whoami`);
      setUser(user.data);
    } catch (e) {
      console.log("e", e);
    }
    // return user;
    // console.log("user", user);
  }

  useEffect(() => {
    getUser();
  }, []);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [isOld, setIsOld] = useState(() => {
    // Load the initial state from localStorage if available
    const storedIsOld = localStorage.getItem("isOld");
    return storedIsOld ? JSON.parse(storedIsOld) : false;
  });

  const toggleView = () => {
    setIsOld((prevValue) => {
      // Toggle the value
      const newValue = !prevValue;
      // Save the new value to localStorage
      localStorage.setItem("isOld", JSON.stringify(newValue));
      return newValue;
    });
  };

  document.title = "Tick Tick Trader | Apps";

  return (
    <>
      <Flex w="100%" overflow={"scroll"} justifyContent={"end"} my="20px">
        <Tooltip
          label="Click To Change Display Layout"
          placement={"right-start"}>
          <button onClick={toggleView} color={textColorPrimary}>
            {isOld ? <BsGridFill size={25} /> : <FaListUl size={25} />}
          </button>
        </Tooltip>
      </Flex>
      {!sessionContext ||
        !sessionContext?.accessTokenPayload ||
        (!sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") &&
          !sessionContext.accessTokenPayload["st-role"].v.includes("BL") &&
          !sessionContext.accessTokenPayload["st-role"].v.includes("LC") &&
          !sessionContext.accessTokenPayload["st-role"].v.includes("BM") &&
          !sessionContext.accessTokenPayload["st-role"].v.includes("PAYOUT") &&
          !sessionContext.accessTokenPayload["st-role"].v.includes(
            "PAYOUTMASTER"
          ) &&
          !sessionContext.accessTokenPayload["st-role"].v.includes(
            "JOURNALITIX"
          ) &&
          !sessionContext.accessTokenPayload["st-role"].v.includes(
            "COUPON"
          ) && (
            <Flex
              w={"full"}
              h={"full"}
              flexDir={"column"}
              gap={5}
              justifyContent={"center"}
              alignItems={"center"}
              color="gray.50"
              sx={{
                img: { filter: "brightness(90%)" },
                "img:hover": { filter: "brightness(100%)" },
              }}>
              <Image
                src={contactSupport}
                /* opacity="0.8" _hover={{opacity:1}} */ h="250px"
              />
              <Heading size="md" textAlign={`center`}>
                You have not an assigned role. Please Contact the Master
                Administrator
              </Heading>
            </Flex>
          ))}

      <Grid
        h="calc(100vh - 40px)"
        p={2}
        templateColumns={
          isOld
            ? "repeat(3, 1fr)"
            : {
                base: "repeat(1, 1fr)",
                xl: "repeat(2, 1fr)",
                "3xl": "repeat(3,1fr)",
              }
        }
        templateRows="repeat(3, 1fr)"
        gap={4}
        overflow="scroll">
        {/* {auth &&
        auth?.user &&
        (auth?.user?.roles?.includes("MASTER") ||
          auth?.user?.roles?.includes("SUB")) && ( */}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes("SUB")) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        bgGradient={`linear(to-t, gray.200, gray.400)`} */
              color="#000"
              gap={4}
              /*         _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }}
               */ display="flex"
              alignItems={`center`}
              justifyContent="center"
              onClick={() => navigate("/apps/tttsubscriptions")}
              cursor="pointer">
              {/* <Box
                color="gray.50"
                sx={{
                  img: { filter: "brightness(90%)" },
                  "img:hover": { filter: "brightness(100%)" },
                }}>
                <Image
                  src={subscriptions}
                  borderRadius={"full"}
                  h="250px"
                />
                <Heading size="md" mt={5} color={"#000"} textAlign={`center`}>
                  Subscriptions
                </Heading>
              </Box> */}
              {isOld ? (
                <CustomBox
                  img={subscriptions}
                  Link={
                    "https://docs.google.com/document/d/19EQk8F87XKaOqVmeFpzokJRkJj0iL2ML/edit#heading=h.mn3r46neaa0h"
                  }
                  appName={"Subscriptions"}
                  description={
                    "Manages licenses for trading platforms like Jigsaw, Ninja Trader, and TradingView."
                  }
                />
              ) : (
                <AppCardLayout
                  img={subscriptions}
                  description={
                    "Manages licenses for trading platforms like Jigsaw, Ninja Trader, and TradingView."
                  }
                  Link={
                    "https://docs.google.com/document/d/19EQk8F87XKaOqVmeFpzokJRkJj0iL2ML/edit#heading=h.mn3r46neaa0h"
                  }
                  appName={"Subscriptions"}
                />
              )}
            </GridItem>
          )}

        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes("BL")) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`} */
              /* bgGradient={`linear(to-t, gray.200, gray.400)`}
        _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }} */
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              onClick={() => navigate("/apps/tttblacklist/new-blacklisted")}
              cursor="pointer">
              {isOld ? (
                <CustomBox
                  img={blacklist}
                  description={
                    "Maintains a comprehensive list of users who are blacklisted from using services provided by TickTickTrader. Allows admins to add, update, or delete users from the list."
                  }
                  Link={
                    "https://docs.google.com/document/d/11jB3mQ1yRikfiuu30NVQdoI1jbYfp6Cb/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Blacklist"}
                />
              ) : (
                <AppCardLayout
                  img={blacklist}
                  description={
                    "Maintains a comprehensive list of users who are blacklisted from using services provided by TickTickTrader. Allows admins to add, update, or delete users from the list."
                  }
                  Link={
                    "https://docs.google.com/document/d/11jB3mQ1yRikfiuu30NVQdoI1jbYfp6Cb/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Blacklist"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes("LC") ||
            sessionContext.accessTokenPayload["st-role"].v.includes("BM")) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        bgGradient={`linear(to-t, gray.200, gray.400)`}
        _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }} */
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/tttbookmap/add-bookmap-license")}>
              {isOld ? (
                <CustomBox
                  img={bookmap}
                  description={
                    "Manages the assignment and administration of Bookmap licenses, ensuring proper distribution and usage tracking."
                  }
                  Link={
                    "https://docs.google.com/document/d/1alvU6smts6w6MTX6zmfF69QhoAFFXL8R/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Bookmap"}
                />
              ) : (
                <AppCardLayout
                  img={bookmap}
                  description={
                    "Manages the assignment and administration of Bookmap licenses, ensuring proper distribution and usage tracking."
                  }
                  Link={
                    "https://docs.google.com/document/d/1alvU6smts6w6MTX6zmfF69QhoAFFXL8R/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Bookmap"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes("PAYOUT") ||
            sessionContext.accessTokenPayload["st-role"].v.includes(
              "PAYOUTMASTER"
            )) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        bgGradient={`linear(to-t, gray.200, gray.400)`}
        _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }} */
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/tttpayout/list")}>
              {isOld ? (
                <CustomBox
                  img={payout}
                  description={
                    "Helps manage and process all payout requests from users. The app supports various payout methods including cryptocurrency and bank transfers."
                  }
                  Link={
                    "https://docs.google.com/document/d/1_CHBvl1i8lUnRXe14BF-m6P2nb9TVLbR/edit"
                  }
                  appName={"Payouts"}
                />
              ) : (
                <AppCardLayout
                  img={payout}
                  description={
                    "Helps manage and process all payout requests from users. The app supports various payout methods including cryptocurrency and bank transfers."
                  }
                  Link={
                    "https://docs.google.com/document/d/1_CHBvl1i8lUnRXe14BF-m6P2nb9TVLbR/edit"
                  }
                  appName={"Payouts"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes(
              "JOURNALITIX"
            )) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        bgGradient={`linear(to-t, gray.200, gray.400)`}
        _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }} */
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/tttjournalytix/list")}>
              {isOld ? (
                <CustomBox
                  img={journalitix}
                  description={
                    "Manages and assigns Journalytix licenses and trading needs of users."
                  }
                  Link={
                    "https://docs.google.com/document/d/15TEWv7FYkvF6Jm-W4inoOnT9sMvXfwjO/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Journalytix"}
                />
              ) : (
                <AppCardLayout
                  img={journalitix}
                  description={
                    "Manages and assigns Journalytix licenses and trading needs of users."
                  }
                  Link={
                    "https://docs.google.com/document/d/15TEWv7FYkvF6Jm-W4inoOnT9sMvXfwjO/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Journalytix"}
                />
              )}
            </GridItem>
          )}

        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes(
              "COUPON"
            )) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/coupons/list")}>
              {isOld ? (
                <CustomBox
                  img={coupons}
                  description={
                    "Facilitates the management of whitelists for specific coupon codes, ensuring only authorized users can access certain promotions."
                  }
                  Link={
                    "https://docs.google.com/document/d/1DWGlv8CJkPwDGw2Lv7jrQnqB-mxGBc0M/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Coupons Eligibility"}
                />
              ) : (
                <AppCardLayout
                  img={coupons}
                  description={
                    "Facilitates the management of whitelists for specific coupon codes, ensuring only authorized users can access certain promotions."
                  }
                  Link={
                    "https://docs.google.com/document/d/1DWGlv8CJkPwDGw2Lv7jrQnqB-mxGBc0M/edit?usp=sharing&ouid=109387331543284140299&rtpof=true&sd=true"
                  }
                  appName={"Coupons Eligibility"}
                />
              )}
            </GridItem>
          )}

        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes(
              "PAYMENT"
            )) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/payments/list")}>
              {isOld ? (
                <CustomBox
                  img={payment}
                  description={
                    "Provides a detailed view of all transactions processed through payment processors."
                  }
                  Link={""}
                  appName={"Payment Transactions"}
                />
              ) : (
                <AppCardLayout
                  img={payment}
                  description={
                    "Provides a detailed view of all transactions processed through payment processors."
                  }
                  Link={""}
                  appName={"Payment Transactions"}
                />
              )}
            </GridItem>
          )}

        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes(
              "FUNDED"
            )) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/funded/list")}>
              {isOld ? (
                <CustomBox
                  img={funded}
                  description={
                    "Manages TTTPerformance accounts and resolves related user issues. It verifies user eligibility, KYC, and contract signatures, and allows manual account setup by support staff."
                  }
                  Link={""}
                  appName={"Performance Accounts"}
                />
              ) : (
                <AppCardLayout
                  img={funded}
                  description={
                    "Manages TTTPerformance accounts and resolves related user issues. It verifies user eligibility, KYC, and contract signatures, and allows manual account setup by support staff."
                  }
                  Link={""}
                  appName={"Performance Accounts"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          (sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") ||
            sessionContext.accessTokenPayload["st-role"].v.includes(
              "RESET"
            )) && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/resetAccount/list")}>
              {isOld ? (
                <CustomBox
                  img={reset}
                  description={
                    "Helps manage and process all payout requests from users. The app supports various payout methods including cryptocurrency and bank transfers."
                  }
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Reset Accounts"}
                />
              ) : (
                <AppCardLayout
                  img={reset}
                  description={
                    "Helps manage and process all payout requests from users. The app supports various payout methods including cryptocurrency and bank transfers."
                  }
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Reset Accounts"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/activeAccounts/")}>
              {isOld ? (
                <CustomBox
                  img={tradovate}
                  description={"Export Tradovate Active Accounts"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Tradovate Active Accounts"}
                />
              ) : (
                <AppCardLayout
                  img={tradovate}
                  description={"Export Tradovate Active Accounts"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Tradovate Active Accounts"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/allActiveAccounts/")}>
              {isOld ? (
                <CustomBox
                  img={activeAccounts}
                  description={"Get All User Active Accounts"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"User Active Accounts"}
                />
              ) : (
                <AppCardLayout
                  img={activeAccounts}
                  description={"Get All User Active Accounts"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"User Active Accounts"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/TRAccountChecker/")}>
              {isOld ? (
                <CustomBox
                  img={TR}
                  description={"Check if account has reached the target"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Target Reached Checker"}
                />
              ) : (
                <AppCardLayout
                  img={TR}
                  description={"Check if account has reached the target"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Target Reached Checker"}
                />
              )}
            </GridItem>
          )}
        {sessionContext &&
          sessionContext?.accessTokenPayload &&
          sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") && (
            <GridItem
              colSpan={1}
              borderRadius="15px"
              color="#000"
              display="flex"
              alignItems={`center`}
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate("/apps/accountCertificate/")}>
              {isOld ? (
                <CustomBox
                  img={certif}
                  description={"View and download accounts certificate"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Accounts Certificate"}
                />
              ) : (
                <AppCardLayout
                  img={certif}
                  description={"View and download accounts certificate"}
                  Link={
                    "https://docs.google.com/document/d/147U6T1-oC714ZihErf0A4OTYhdBfct6I/edit#heading=h.1fob9te"
                  }
                  appName={"Accounts Certificate"}
                />
              )}
            </GridItem>
          )}
      </Grid>
    </>
  );
}
