import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery, useQueryClient } from "react-query";

export const getDailyPnl = (id, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/funded/DailyPnL/${id}`,
    {
      method: "GET",
    }
  );
  return data;
};

const useGetDailPnl = (id) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  return {
    DailyPnl: useQuery(["DailyPnl", id], getDailyPnl(id, auth), {
      staleTime: ms("1s"),
      refetchInterval: ms("5m"),
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      retry: false,
    }).data,
    refetchDailyPnl: () => queryClient.refetchQueries(["DailyPnl"]),
  };
};

export default useGetDailPnl;
