import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { colors } from "../../utils/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useAuth } from "../../utils/auth";
import "../TTTblacklist/new-blacklisted/datepicker1.css";
import { type } from "@testing-library/user-event/dist/type";
import { client } from "../../utils/axios";
// first name ,  last name , email / login , password ,Time of subscription
const schema = yup.object().shape({
  FirstName: yup.string().required("First Name is required"),
  LastName: yup.string().required("Last Name is required"),
  Email: yup.string().email().required("Email is required"),
  Username: yup.string().required("login ID is required"),
  Password: yup.string().required("password is required"),
  //   product: yup.string().required("product is required"),
  timeOfSubscription: yup.number().required("Time of Subscription is required"),
});

function CreateJournalytix() {
  const auth = useAuth();
  const toast = useToast();
  //   const [date, setDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    // console.log("data", data);
    // data.Products = 53;
    let { timeOfSubscription, ...data1 } = data;
    const currentDate = new Date();

    // Get the input number of days (nbDays)
    // const nbDays = 10; // Replace this with your input value

    // Calculate the target date by adding nbDays to the current date
    const targetDate = new Date(currentDate);
    targetDate.setMonth(currentDate.getMonth() + timeOfSubscription);

    // Format the targetDate as "YYYY-MM-DD"
    data1.endDate = targetDate.toISOString().split("T")[0];

    // console.log("data1", data1.endDate, "time of Subs", timeOfSubscription);
    try {
      await client
        .post(`${process.env.REACT_APP_API_HOST}/journalitix`, data1, {
          // headers: {
          //   "auth-token": auth.user.accessToken,
          // },
        })
        .then(() => {
          toast({
            position: "top",
            duration: 5 * 1000,
            status: "success",
            render: () => (
              <Box
                bgColor="yellow.400"
                borderRadius="5px"
                color="#f5f5f5"
                p="20px"
                textAlign="center">
                <Heading fontSize="sm" textTransform="uppercase">
                  Journalytix added successfully
                </Heading>
              </Box>
            ),
          });
          reset();
        })
        .catch((error) => {
          if (typeof error.response.data.message === "object")
            for (const key in error.response.data.message) {
              console.log(
                "key = ",
                key,
                " message ",
                error.response.data.message[key]
              );
              if (
                Object.hasOwnProperty.call(error.response.data.message, key)
              ) {
                // Print the dynamic key and its value
                toast({
                  position: "top",
                  duration: 5 * 1000,
                  status: "error",
                  render: () => (
                    <Box
                      bgColor="red.400"
                      borderRadius="5px"
                      color="#f5f5f5"
                      p="20px"
                      textAlign="center">
                      <Heading fontSize="sm" textTransform="uppercase">
                        {error.response.data.message[key]}
                      </Heading>
                    </Box>
                  ),
                });
                // break;
                //   console.log(`Key: ${key}, Value: ${error.response.message[key]}`);
              }
            }
          else {
            toast({
              position: "top",
              duration: 5 * 1000,
              status: "error",
              render: () => (
                <Box
                  bgColor="red.400"
                  borderRadius="5px"
                  color="#f5f5f5"
                  p="20px"
                  textAlign="center">
                  <Heading fontSize="sm" textTransform="uppercase">
                    {error.response.data.message}
                  </Heading>
                </Box>
              ),
            });
          }
          console.log("error ", error);
        });
      clearErrors();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          align="stretch"
          spacing={10}
          px="50px"
          pb="50px"
          mb="50px"
          mt="100px"
          borderRadius={20}
          className="sidebar_nav"
          maxW="900px"
          mx="auto"
          color="gray.50"
        >
          <Heading size="lg" py="10px" color='black'>
            Add a new Journalytix
          </Heading>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl>
              <FormLabel color='#000'>First name</FormLabel>
              <Input
                name="FirstName"
                {...register("FirstName", { required: true })}
                type="text"
                placeholder="Enter First Name"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />
              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.FirstName?.message}
              </Badge>
            </FormControl>
            <FormControl>
              <FormLabel color='#000'>Last Name</FormLabel>
              <Input
                name="LastName"
                {...register("LastName", { required: true })}
                type="text"
                placeholder="Enter Last Name"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />
              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.LastName?.message}
              </Badge>
            </FormControl>
          </Flex>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl>
              <FormLabel color='#000'>Email</FormLabel>
              <Input
                name="Email"
                {...register("Email", { required: true })}
                type="email"
                placeholder="Enter email"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.Email?.message}
              </Badge>
            </FormControl>

            <FormControl>
              <FormLabel color='#000'>login</FormLabel>
              <Input
                name="Username"
                {...register("Username", { required: true })}
                type="text"
                placeholder="Enter Username"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.Username?.message}
              </Badge>
            </FormControl>
          </Flex>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl>
              <FormLabel color='#000'>Password</FormLabel>
              <Input
                name="Password"
                {...register("Password", { required: true })}
                type="password"
                placeholder="Enter password"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.Password?.message}
              </Badge>
            </FormControl>
            {/* <FormControl w="25%">
              <FormLabel>product</FormLabel>
              <Input
                name="product"
                {...register("product", { required: true })}
                type="text"
                placeholder="Enter product"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#ffff",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.product?.message}
              </Badge>
            </FormControl> */}
          </Flex>
          // ! time of sub
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl>
              <FormLabel color='#000'>Time of Subscription</FormLabel>
              <Input
                name="timeOfSubscription"
                {...register("timeOfSubscription", { required: true })}
                type="number"
                placeholder="Enter Ticket Number"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.timeOfSubscription?.message}
              </Badge>
            </FormControl>
          </Flex>
          <Flex align="center" justify={`center`} pt="50px">
            <Button
              w="100%"
              borderRadius={15}
              fontSize="12px"
              // bgGradient={`linear(to-b, #01298B, #01298C)`}
              className="app_btn"
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #01298B, #01298B)` }}
              type="submit">
              + Add Journalytix
            </Button>
          </Flex>
        </VStack>
      </form>
    </div>
  );
}

export default CreateJournalytix;

export const countriesList = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
