import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const useBookmapLicenseMutation = () => {
  const auth = useAuth();
  const { mutateAsync: addBookmapLicense } = useMutation((data) =>
    client.post(
      `${process.env.REACT_APP_API_HOST}/BookmapLicenses`,
      { ...data },
      {
        //   method: "GET",
        //   headers: {
        // 	"auth-token": auth.user.accessToken,
        //   },
      }
    )
  );

  const { mutateAsync: deleteBookmapLicense } = useMutation((id) =>
    client.delete(`${process.env.REACT_APP_API_HOST}/BookmapLicenses/${id}`, {
      //   method: "GET",
      //   headers: {
      // 	"auth-token": auth.user.accessToken,
      //   },
    })
  );

  return { addBookmapLicense, deleteBookmapLicense };
};

export default useBookmapLicenseMutation;
