import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  chakra,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { colors } from "../../../utils/colors";
// import { } from "react-super-responsive-table";

import useLicenses from "../../../hooks/useQueries/useLicenses";
import Pagination1 from "../../../utils/pagination";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

export default function BookmapAvailableLicensesList() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const { licenses } = useLicenses();
  console.log("licences", licenses);
  document.title = "Bookmap Licenses | Available licenses";

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto"
      >
        <Flex align="center" justify="space-between" pb="40px">
          <Box w="50%">
            <Heading size="lg">Bookmap Available Licenses</Heading>
          </Box>
          <Box>
            <Badge
              colorScheme="yellow"
              fontSize={16}
              borderRadius="15px"
              p="10px"
              boxShadow={"xl"}
            >
              {licenses?.length > 0
                ? `${licenses?.length} available licenses`
                : "No available license"}
            </Badge>
          </Box>
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px"
        >
          {/* {licenses?.docs?.length > 0 ? ( */}
          {licenses?.length > 0 ? (
            <Table borderRadius={20} variant="striped">
              <Thead className="table__head" fontSize="15px" h="40px">
                <Tr
                  textTransform="capitalize"
                  h="30px"
                  borderBottomWidth={1}
                  borderRadius="20px"
                >
                  <Th textAlign="center" color="white">
                    License Key
                  </Th>
                  <Th textAlign="center" color="white">
                    Username
                  </Th>

                  <Th textAlign="center" color="white">
                    Password
                  </Th>
                </Tr>
              </Thead>

              <Tbody
                // bgGradient={`linear(to-l, gray.100, gray.300)`}
                color="black"
              >
                {/* {licenses?.docs?.map((license, i) => ( */}
                {licenses?.map((license, i) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    <Td textAlign="center">{license.licenseKey}</Td>
                    <Td textAlign="center">{license.username}</Td>

                    <Td textAlign="center">{license.password}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}
            >
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>
      {/* <Pagination1
        pagesQuantity={licenses?.totalPages}
        curPage={page}
        setCurPage={setPage}
      /> */}
    </Flex>
  );
}
