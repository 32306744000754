import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
import moment from "moment";

const DetailPayment = ({ payment }) => {
  const renderAction = () => {
    if (Array.isArray(payment?.action)) {
      return payment?.action.map((act, i) => (
        <GridItem
          border="1px solid lightgray"
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          key={i}
        >
          <Table variant="simple">
            <Tbody className="sidebar_nav">
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Amount:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.amount || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Action type:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.action_type || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  {" "}
                  Date:
                </Td>
                <Td p="10px 25px 10px 25px">
                  {act?.date ? moment(act?.date).format("YYYY/MM/DD") : "-"}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Username:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.username || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Response text:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.response_text || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  API Method:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.api_method || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  API Method:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.api_method || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Batch Id:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.batch_id || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Device License Number:
                </Td>
                <Td p="10px 25px 10px 25px">
                  {act?.device_license_number || "-"}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Device Nickname:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.device_nickname || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  IP Address:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.ip_address || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Processor Batch ID:
                </Td>
                <Td p="10px 25px 10px 25px">
                  {act?.processor_batch_id || "-"}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Processor Response Code:
                </Td>
                <Td p="10px 25px 10px 25px">
                  {act?.processor_response_code || "-"}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Processor Response Text:
                </Td>
                <Td p="10px 25px 10px 25px">
                  {act?.processor_response_text || "-"}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Source:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.source || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Response Text:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.response_text || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Response Code:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.response_code || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  Success:
                </Td>
                <Td p="10px 25px 10px 25px">{act?.success || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" p="10px 25px 10px 25px">
                  TAP To Mobile::
                </Td>
                <Td p="10px 25px 10px 25px">{act?.tap_to_mobile || "-"}</Td>
              </Tr>
            </Tbody>
          </Table>
        </GridItem>
      ));
    } else {
      return (
        <GridItem border="1px solid lightgray">
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td fontWeight="bold">Amount:</Td>
                <Td>{payment?.action?.amount || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Action type:</Td>
                <Td>{payment?.action?.action_type || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold"> Date:</Td>
                <Td>
                  {payment?.action?.date
                    ? moment(payment?.action?.date).format("YYYY/MM/DD")
                    : "-"}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Username:</Td>
                <Td>{payment?.action?.username || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Response text:</Td>
                <Td>{payment?.action?.response_text || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">API Method:</Td>
                <Td>{payment?.action?.api_method || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">API Method:</Td>
                <Td>{payment?.action?.api_method || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Batch Id:</Td>
                <Td>{payment?.action?.batch_id || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Device License Number:</Td>
                <Td>{payment?.action?.device_license_number || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Device Nickname:</Td>
                <Td>{payment?.action?.device_nickname || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">IP Address:</Td>
                <Td>{payment?.action?.ip_address || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Processor Batch ID:</Td>
                <Td>{payment?.action?.processor_batch_id || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Processor Response Code:</Td>
                <Td>{payment?.action?.processor_response_code || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Processor Response Text:</Td>
                <Td>{payment?.action?.processor_response_text || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Source:</Td>
                <Td>{payment?.action?.source || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Response Text:</Td>
                <Td>{payment?.action?.response_text || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Response Code:</Td>
                <Td>{payment?.action?.response_code || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Success:</Td>
                <Td>{payment?.action?.success || "-"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">TAP To Mobile::</Td>
                <Td>{payment?.action?.tap_to_mobile || "-"}</Td>
              </Tr>
            </Tbody>
          </Table>
        </GridItem>
      );
    }
  };

  return (
    <div>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          shadow="md"
          colSpan={2}
          className="sidebar_nav"
          borderWidth="2px"
          p={4}
          //   w="100%"
        >
          <Flex
            flexDir={"column"}
            justifyContent={"space-evenly"}
            gap={5}
            alignItems={"flex-start"}
          >
            <Heading color={colors.blue} as="h4" size={"md"}>
              Account Information
            </Heading>
            <Stack spacing={0}>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      First name:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.first_name || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Last name:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.last_name || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Initiated By:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.initiated_by || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Address 1:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.address_1 || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Address 2:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.address_2 || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Postal Code:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.postal_code || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      City:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.city || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Country:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.country || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Email:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.email || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Account Holder Type:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.account_holder_type || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Account Type:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.account_type || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Alternate Tax Amount:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.alternate_tax_amount || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Authorization Code:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.authorization_code || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      AVS Response :{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.avs_response || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Card Available Balance :{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.card_available_balance || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Card Balance :{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.card_balance || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Cardholder Auth:{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.cardholder_auth || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Cash Discount :{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.cash_discount || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CAVV :{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.cavv || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CAVV Result :{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.cavv_result || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CC Bin :{" "}
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.cc_bin || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CC Exp :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.cc_exp || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CC Hash :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.cc_hash || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CC Issue Number :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.cc_issue_number || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CC Number :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.cc_number || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CC Start Date :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.cc_start_date
                        ? moment(payment?.cc_start_date).format("YYYY-MM-DD")
                        : "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CC Type :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.cc_type || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Phone :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.cell_phone || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Check ABA :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.check_aba || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Check Account :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.check_account || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Check Hash :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.check_hash || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Check Name :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.check_name || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      COF Supported:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.cof_supported ? ` true` : " false"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Company :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.company || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      FAX :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.fax || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      State :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.state || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Stored Credential Indicator :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.stored_credential_indicator || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Summary Commodity Code :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.summary_commodity_code || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Surcharge :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.surcharge || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      TAX :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.tax || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Three DS Version :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.three_ds_version || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      TIP :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.tip || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      {" "}
                      Tracking Number :
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.tracking_number || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Website :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.website || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      XID :
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.xid || "-"}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Stack>
          </Flex>
        </GridItem>
        <GridItem
          hadow="md"
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          colSpan={2}
          borderWidth="2px"
          p={4}
          w="100%"
          className="sidebar_nav"
        >
          <Flex
            flexDir={"column"}
            justifyContent={"space-evenly"}
            gap={5}
            alignItems={"flex-start"}
          >
            <Heading color={colors.blue} as="h4" size={"md"}>
              Transaction Information
            </Heading>
            <Stack spacing={0}>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Transaction ID:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.transaction_id || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Platform ID:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.platform_id || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Transaction type:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.transaction_type || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Condition:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.condition || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Country:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.country || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      CSC Response:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.csc_response || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Currency:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.currency || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Customer Id:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.customerid || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Customer Tax Id:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.customertaxid || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Directory Server Id:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.directory_server_id || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Discount Amount:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.discount_amount || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Drivers License DOB:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.drivers_license_dob || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Drivers License Number:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.drivers_license_number || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Drivers License State:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.drivers_license_state || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Duty Amount:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.duty_amount || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      ECI:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.eci || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Entry Mode:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.entry_mode || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      National Tax Amount:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.national_tax_amount || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Order Description:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.order_description || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Order Id:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.order_id || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Partial Payment Balance:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.partial_payment_balance || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Partial Payment Id:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.partial_payment_id || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      {" "}
                      Platform Id:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.platform_id || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Phone:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.phone || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Ponumber:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.ponumber || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Processor Id:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.processor_id || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      SEC Code:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.sec_code || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping:
                    </Td>
                    <Td p="10px 25px 10px 25px">{payment?.shipping || "-"}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Address 1:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_address_1 || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Address 2:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_address_2 || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Carrier:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_carrier || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping City:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_city || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Company:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_company || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Country:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_country || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Date:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_date
                        ? moment(payment?.shipping_date).format("YYYY-MM-DD")
                        : "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Email:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_email || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping First Name:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_first_name || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Last Name:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_last_name || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Phone:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_phone || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping Postal Code:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_postal_code || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Shipping State:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.shipping_state || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Signature:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.signature_image || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      Social Security Number:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.social_security_number || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      VAT Tax Amount:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.vat_tax_amount || "-"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold" p="10px 25px 10px 25px">
                      VAT Tax Rate:
                    </Td>
                    <Td p="10px 25px 10px 25px">
                      {payment?.vat_tax_rate || "-"}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Stack>
          </Flex>
        </GridItem>
        <GridItem
          // className="sidebar_nav"
          colSpan={4}
          borderWidth="2px"
          p={4}
          w="100%"
        >
          <Heading color={colors.blue} as="h4" size={"md"}>
            Action
          </Heading>

          <Grid
            gap={4}
            colSpan={2}
            shadow="md"
            templateColumns="repeat(3, 1fr)"
          >
            {renderAction()}
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
};

export default DetailPayment;
