
import qs from "qs";

export const getstrquery = (query) => {
	if(!query) return "";
	const filteredParams = Object.fromEntries(
		Object.entries(query).filter(([_, value]) => (typeof value === "boolean")? true : (value !== undefined && value !== "" && value != []))
	);
	return  qs.stringify(filteredParams);

}