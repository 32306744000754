import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Badge,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

import React, { useState, useEffect } from "react";
import { colors } from "../../../utils/colors";
import { BiFilter, BiSearch, BiTrash } from "react-icons/bi";
// import {
//  } from "react-super-responsive-table";
import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useBookmapLicenses from "../../../hooks/useQueries/useBookmapLicenses";
import useBookmapAllLicenses, {
  bookmapAllLicensesFetcher,
} from "../../../hooks/useQueries/useBookmapAllLicenses";
import { CSVExporter } from "react-csv";

import { CSVLink } from "react-csv";
import { useRecoilValue, useRecoilState } from "recoil";
import axios from "axios";
import {
  filterBookmapLicenseSelector,
  filterBookmapLicenseState,
} from "../../../recoil";
import useBookmapLicenseMutation from "../../../hooks/useMutations/useBookmapLicensesMutation";
import EditBookmapLicense from "../editLicenseStatus";
import { useAuth } from "../../../utils/auth";
import Pagination1 from "../../../utils/pagination";
import { useRef } from "react";
import Session from "supertokens-auth-react/recipe/session";
import { client } from "../../../utils/axios";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

const headers = [
  { label: "User ID", key: "accountName" },
  { label: "Email", key: "email" },
  { label: "Date", key: "date" },
  { label: "License Key", key: "licenseKey" },
  { label: "Status", key: "isActif" },
  { label: "Passed to inactif", key: "toInactif" },
];

export default function BookmapLicensesList() {
  let auth = useAuth();
  const query = useRecoilValue(filterBookmapLicenseSelector);
  const [filterBookmapLicense, setFilterBookmapLicense] = useRecoilState(
    filterBookmapLicenseState
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  let sessionContext = Session.useSessionContext();

  const [searchBy, setSearchBy] = useState("accounts");
  const { bookmapLicenses, refetchBookmapLicenses } = useBookmapLicenses(
    query + `&page=${page}&limit=${limit}`
  );

  const [csvReport, setSCVReport] = useState({ data: [] });
  // let csvReport = {};
  const [exportCheck, setExportCheck] = useState(false);
  const clickRef = useRef();
  // const { bookmapAllLicenses, refetchBookmapAllLicenses } =
  //   useBookmapAllLicenses();
  useEffect(() => {
    if (exportCheck)
      if (csvReport?.data.length > 0) {
        clickRef.current.link.click();
        setExportCheck(false);
      }
  }, [csvReport]);

  const [accountName, setAccountName] = useState("");

  const exportReport = async () => {
    setExportCheck(true);
    await client
      .get(`${process.env.REACT_APP_API_HOST}/bookmapLicenses/all?${query}`, {
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      })
      .then((res) => {
        setSCVReport({
          data: res?.data?.docs?.map((e) => e),
          headers: headers,
          filename: `Bookmap Licenses - ${new Date()}.csv`,
        });
        // csvReport = {
        //   data: res?.data?.map((e) => e),
        //   headers: headers,
        //   filename: `Bookmap Licenses - ${new Date()}.csv`,
        // if (res.data.length > 0)
        // if (csvReport?.data.length > 0) clickRef.current.link.click();
        // };
      });
    // <CSVLink {...csvReport}>
    // clickRef.current = null;
    // setExportCheck(false);
    //   <button>Export CSV</button>
    // </CSVLink>;

    // const csvExporter = new CSVExporter();
    // csvExporter.export(csvReport.data); // Pass your data to the exporter
    // csvExporter.download("data.csv"); //
  };

  const { deleteBookmapLicense } = useBookmapLicenseMutation();

  let toast = useToast();

  document.title = "Bookmap Licenses | Licenses List";

  // console.log(auth.user.roles);
  return (
    <Box px="100px" h="calc(100vh - 70px)" color="gray.50" overflow="auto">
      <Flex flexDir={"column"} justify={"space-evenly"}>
        <Box
          px="100px"
          py="40px"
          h="calc(100vh - 200px)"
          color="gray.50"
          overflow="auto"
        >
          <Flex align="center" justify="space-between" py="30px">
            <Box w="30%">
              <Heading size="lg" color="black">
                Bookmap Licenses
              </Heading>
            </Box>
            <Flex w="70%" justify="space-evenly" align="center">
              <Box w="20%">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<BiFilter size="20px" />}
                    borderRadius={50}
                    fontSize="12px"
                    // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                    className="app_btn"
                    boxShadow="lg"
                    color="#ffff"
                    // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  >
                    Search by: {searchBy}
                  </MenuButton>
                  <MenuList color={colors.ocean} fontSize="14px">
                    <MenuItem onClick={() => setSearchBy("accounts")}>
                      Accounts
                    </MenuItem>
                    <MenuItem onClick={() => setSearchBy("email")}>
                      Email
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>

              <Flex align="center" justify={`center`} w="100%">
                <Box w="70%">
                  <Input
                    type="text"
                    placeholder={`Search by ${searchBy}...`}
                    borderColor="#0177D9"
                    // borderColor={colors.lightBlue}
                    color={colors.lightBlue}
                    _focus={{ borderColor: "#0177D9" }}
                    bgColor="gray.100"
                    borderRadius={20}
                    boxShadow="lg"
                    _placeholder={{
                      opacity: 0.7,
                      color: colors.ocean,
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                    onChange={(e) => setAccountName(e.target.value)}
                  />
                </Box>

                <Box w="10%" ml="5px">
                  {searchBy === "accounts" ? (
                    <Button
                      borderRadius={50}
                      fontSize="12px"
                      // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                      boxShadow="lg"
                      color="#ffff"
                      className="app_btn"
                      // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                      isDisabled={!!accountName ? false : true}
                      onClick={() => {
                        setFilterBookmapLicense({
                          ...filterBookmapLicense,
                          accountName: `type=accounts&search=${accountName}`,
                          email: ``,
                        });
                      }}
                    >
                      <BiSearch size="15px" />
                    </Button>
                  ) : (
                    <Button
                      borderRadius={50}
                      fontSize="12px"
                      bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                      boxShadow="lg"
                      color="#ffff"
                      _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                      isDisabled={!!accountName ? false : true}
                      onClick={() => {
                        setFilterBookmapLicense({
                          ...filterBookmapLicense,
                          email: `type=email&search=${accountName}`,
                          accountName: ``,
                        });
                      }}
                    >
                      <BiSearch size="15px" />
                    </Button>
                  )}
                </Box>
              </Flex>

              <Box w="20%">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<BiFilter size="20px" />}
                    borderRadius={50}
                    fontSize="12px"
                    // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                    boxShadow="lg"
                    color="#ffff"
                    className="app_btn"

                    // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  >
                    Filter by
                  </MenuButton>
                  <MenuList color={colors.ocean} fontSize="14px">
                    <MenuItem
                      onClick={() => {
                        setFilterBookmapLicense({
                          ...filterBookmapLicense,
                          accountName: ``,
                          email: ``,
                          isActif: ``,
                        });
                      }}
                    >
                      All
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setFilterBookmapLicense({
                          ...filterBookmapLicense,
                          accountName: ``,
                          tradingSoftware: ``,
                          isActif: "1",
                        });
                      }}
                    >
                      Actif
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setFilterBookmapLicense({
                          ...filterBookmapLicense,
                          accountName: ``,
                          email: ``,
                          isActif: "0",
                        });
                      }}
                    >
                      Inactif
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
          </Flex>
          {bookmapLicenses?.totalDocs > 0 && (
            <Box pb="5px" align="right">
              <Badge
                colorScheme="orange"
                borderRadius={15}
                py="5px"
                px="10px"
                fontSize={12}
              >
                {bookmapLicenses?.totalDocs} License(s)
              </Badge>
            </Box>
          )}

          <Box
            borderRadius={15}
            // bgColor="gray.50"
            color="#000"
            // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
            overflow={`scroll`}
            maxH="550px"
          >
            {bookmapLicenses?.docs?.length > 0 ? (
              <Table borderRadius={20} variant="striped">
                <Thead
                  fontSize="13px"
                  h="40px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                >
                  <Tr
                    textAlign="center"
                    textTransform="capitalize"
                    className="table__head"
                    h="30px"
                    borderBottomWidth={1}
                    borderRadius="20px"
                  >
                    <Th textAlign="center" color="white">
                      Date
                    </Th>
                    <Th textAlign="center" color="white">
                      User ID
                    </Th>

                    <Th textAlign="center" color="white">
                      License Key
                    </Th>
                    <Th textAlign="center" color="white">
                      Login
                    </Th>
                    <Th textAlign="center" color="white">
                      Password
                    </Th>

                    {/* <Th>Password</Th> */}
                    <Th textAlign="center" color="white">
                      Status
                    </Th>
                    <Th textAlign="center" color="white">
                      Passed to inactif
                    </Th>
                    {sessionContext?.accessTokenPayload["st-role"].v.includes(
                      "MASTER"
                    ) && (
                      <Th textAlign="center" color="white">
                        Action
                      </Th>
                    )}
                  </Tr>
                </Thead>

                <Tbody
                  // bgGradient={`linear(to-l, gray.100, gray.300)`}
                  color={"black"}
                >
                  {bookmapLicenses?.docs.map((bl, i) => (
                    <Tr
                      textAlign="center"
                      fontSize={{
                        "2xl": "13px",
                        xl: "13px",
                        md: "12px",
                        lg: "12px",
                        sm: "10px",
                      }}
                      key={i}
                      // borderBottom="1px"
                      // borderColor={colors.lightBlue}
                    >
                      <Td textAlign="center">{bl.date.slice(0, 10)}</Td>
                      <Td textAlign="center" py="7px">
                        {bl.accountName}
                      </Td>
                      <Td textAlign="center">{bl.licenseKey}</Td>
                      <Td textAlign="center">{bl.username || "---"}</Td>
                      <Td textAlign="center">{bl.password}</Td>

                      {/* <Td textAlign="center" >{bl.password}</Td> */}
                      <Td textAlign="center">
                        {bl.isActif === true ? (
                          <Text color="green.500">Actif</Text>
                        ) : (
                          <Text color="red.500">Inactif</Text>
                        )}
                      </Td>
                      <Td textAlign="center">
                        {!!bl.toInactif && bl.isActif === false
                          ? bl.toInactif.slice(0, 10)
                          : "-"}
                      </Td>

                      {sessionContext?.accessTokenPayload["st-role"].v.includes(
                        "MASTER"
                      ) && (
                        <Td textAlign="center">
                          <Flex justify="space-evenly" align="center">
                            <EditBookmapLicense bl={bl} />

                            {/* ------------ DELETE USER ------------- */}

                            <Popover>
                              <PopoverTrigger>
                                <Button
                                  bgColor="transparent"
                                  color="red.600"
                                  align="center"
                                  _hover={{
                                    cursor: "pointer",
                                    color: "red.500",
                                  }}
                                >
                                  <BiTrash size="20px" />
                                </Button>
                              </PopoverTrigger>

                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader
                                  fontSize="16px"
                                  color="red.500"
                                  fontWeight={`bold`}
                                >
                                  Confirmation!
                                </PopoverHeader>
                                <PopoverBody>
                                  <Text size="12px" pb="10px">
                                    Are you sure you want to delete this
                                    subscription?
                                  </Text>
                                  <Button
                                    size="sm"
                                    bgGradient={`linear(to-b, red.300, red.500)`}
                                    color="gray.50"
                                    _hover={{
                                      bgGradient: `linear(to-t, red.300, red.500)`,
                                    }}
                                    boxShadow="lg"
                                    onClick={async () => {
                                      try {
                                        await deleteBookmapLicense(bl?._id);
                                        await refetchBookmapLicenses();
                                        // await refetchBookmapAllLicenses();
                                        toast({
                                          position: "bottom",
                                          duration: 5 * 1000,
                                          status: "success",
                                          render: () => (
                                            <Box
                                              bgColor="red.500"
                                              borderRadius="5px"
                                              color="#f5f5f5"
                                              p="10px"
                                              px="10px"
                                              textAlign="center"
                                            >
                                              <Heading fontSize="sm">
                                                License deleted !!
                                              </Heading>
                                            </Box>
                                          ),
                                        });
                                      } catch (error) {
                                        console.dir("error", error);
                                      }
                                    }}
                                  >
                                    Yes, I'm sure
                                  </Button>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Flex
                borderRadius={15}
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color={colors.ocean}
                boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
                p="20px"
                h="500px"
                justify={`center`}
                align="center"
                fontSize={30}
              >
                No data to display !!
              </Flex>
            )}
          </Box>
        </Box>
        <Pagination1
          pagesQuantity={bookmapLicenses?.totalPages}
          curPage={page}
          setCurPage={setPage}
        />
      </Flex>

      {/* { > 0 && ( */}
      <Box pt="20px">
        <Button
          onClick={() => exportReport()}
          borderRadius={50}
          fontSize="12px"
          // bgGradient={`linear(to-b, green.400, green.500)`}
          boxShadow="lg"
          color="#ffff"
          className="app_btn"
          // _hover={{ bgGradient: `linear(to-r, green.400, green.500)` }}
          w="100%"
        >
          Export All
        </Button>
        {!!csvReport?.data.length > 0 && (
          <CSVLink ref={clickRef} {...csvReport}></CSVLink>
        )}
      </Box>
    </Box>
  );
}
