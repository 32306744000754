import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import { BiSearch, BiTrash, BiDetail } from "react-icons/bi";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
//   import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../node_modules/react-super-responsive-table/dist/index";
import useBlacklist from "../../hooks/useQueries/useBlacklist";
import useBlacklistMutation from "../../hooks/useMutations/useBlacklistMutation";
import {
  filterBlacklistSelector,
  filterBlacklistState,
  filterCouponlistState,
  filterCouponslistSelector,
} from "../../recoil";

import { BiFilter } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import Pagination1 from "../../utils/pagination";
import { useAuth } from "../../utils/auth";
import { colors } from "../../utils/colors";
import { useRecoilState, useRecoilValue } from "recoil";
import getCoupons, { getCoupon } from "../../hooks/useQueries/useCoupons";
import useCouponlists from "../../hooks/useQueries/useCoupons";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import useCouponlistMutation from "../../hooks/useMutations/useCouponlistMutation";
import "./coupons.css";
import SingleCoupon from "./SingleCoupon";

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

const ListCoupons = () => {
  const [searchBy, setSearchBy] = useState("");
  const [search, setSearch] = useState("");
  const [coupon, setCoupon] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const query = useRecoilValue(filterCouponslistSelector);

  const [filterCouponlist, setFilterCouponlist] = useRecoilState(
    filterCouponlistState
  );

  const { coupons, refetchCouponlists } = useCouponlists(
    query + `&page=${page}&limit=${limit}`
  );
  const auth = useAuth()?.user;
  // const roles = auth?.role;
  const roles = useSessionContext().accessTokenPayload["st-role"]?.v;

  useEffect(() => {
    return () => {
      setSearch("");
      setFilterCouponlist({
        ...filterCouponlist,
        coupon: "",
        email: "",
      });
      refetchCouponlists();
    };
  }, []);
  const { deleteCouponlist } = useCouponlistMutation();

  let toast = useToast();
  document.title = "TTTSubscriptions | Coupons List";

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto"
      >
        <Flex align="center" justify="space-evenly" pb="40px">
          <Flex flexDir={"column"}>
            {/* <Box>
              <Heading size="lg">Email</Heading>
            </Box>
            <Box>
              <Heading size="lg">Coupons</Heading>
            </Box> */}
          </Flex>
          <Flex align="center" className="coupon" justify={`center`} w="100%">
            {/* <Box w="90%">
              <Input
                type="text"
                placeholder={`Search by email...`}
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  setSearchBy("email");
                  setSearch(e.target.value);
                }}
              />
            </Box> */}
            <Box w="90%">
              <Input
                type="text"
                placeholder={`Search by coupons...`}
                borderColor={colors.blue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  setSearchBy("coupon");
                  setSearch(e.target.value);
                }}
              />
            </Box>
          </Flex>
          <Box w="50%" ml={2}>
            <Button
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              className="app_btn"
              color="#ffff"
              width={"30%"}
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              isDisabled={!!search ? false : true}
              onClick={() => {
                refetchCouponlists();
                searchBy === "email"
                  ? setFilterCouponlist({
                      ...filterCouponlist,
                      email: `email=${search}`,
                      coupon: ``,
                    })
                  : setFilterCouponlist({
                      ...filterCouponlist,
                      coupon: `coupon=${search}`,
                      email: ``,
                    });
              }}
            >
              <BiSearch size="20px" />
            </Button>
          </Box>

          <Button
            padding={"10px 30px"}
            rightIcon={<AiOutlineEye size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn"
            onClick={() => {
              setFilterCouponlist({
                ...filterCouponlist,
                coupon: ``,
                email: ``,
              });
            }}
          >
            View all
          </Button>
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px"
        >
          {coupons?.data?.length > 0 ? (
            <CustomTable borderRadius={20}>
              <CustomThead
                fontSize="15px"
                h="40px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              >
                <CustomTr
                  textAlign="center"
                  textTransform="capitalize"
                  h="30px"
                  className="table__head"
                  borderBottomWidth={1}
                  borderRadius="20px"
                >
                  <CustomTh>coupon</CustomTh>
                  <CustomTh>Users</CustomTh>
                  <CustomTh>Action</CustomTh>
                </CustomTr>
              </CustomThead>

              <CustomTbody
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color={colors.ocean}
              >
                {coupons?.data?.map((coupon, i) => (
                  <CustomTr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    {/* <CustomTd>{coupon.email} </CustomTd> */}
                    <CustomTd>{coupon.coupon} </CustomTd>
                    <CustomTd>{coupon["Nbr Users"]}</CustomTd>
                    <CustomTd>
                      {/* <Flex justify="space-evenly" align="center">


                        <Popover>
                          <PopoverTrigger>
                            <Button
                              disabled={
                                // !roles?.includes("PayoutMaster") &&
                                !roles?.includes("MASTER", "COUPON")
                              }
                              bgColor="transparent"
                              color="red.600"
                              align="center"
                              _hover={{ cursor: "pointer", color: "red.500" }}
                            >
                              <BiTrash size="20px" />
                            </Button>
                          </PopoverTrigger>

                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader
                              fontSize="16px"
                              color="red.500"
                              fontWeight={`bold`}
                            >
                              Confirmation!
                            </PopoverHeader>
                            <PopoverBody>
                              <Text size="12px" pb="10px">
                                Are you sure you want to whitelist this coupon ?
                              </Text>
                              <Button
                                disabled={
                                  //   !roles?.includes("PayoutMaster") &&
                                  !roles?.includes("MASTER", "COUPON")
                                }
                                size="sm"
                                bgGradient={`linear(to-b, red.300, red.500)`}
                                color="gray.50"
                                _hover={{
                                  bgGradient: `linear(to-t, red.300, red.500)`,
                                }}
                                boxShadow="lg"
                                onClick={async () => {
                                  try {
                                    await deleteCouponlist(coupon);

                                    toast({
                                      position: "bottom",
                                      duration: 5 * 1000,
                                      status: "success",
                                      render: () => (
                                        <Box
                                          bgColor="red.500"
                                          borderRadius="5px"
                                          color="#f5f5f5"
                                          p="10px"
                                          px="10px"
                                          textAlign="center"
                                        >
                                          <Heading fontSize="sm">
                                            Coupon whitelisted !!
                                          </Heading>
                                        </Box>
                                      ),
                                    });
                                    setFilterCouponlist({
                                      ...filterCouponlist,
                                      coupon: ``,
                                      email: ``,
                                    });
                                  } catch (error) {
                                    console.dir("error", error);
                                  }
                                }}
                              >
                                Yes, I'm sure
                              </Button>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex> */}
                      <Button
                        onClick={() => {
                          setCoupon(coupon.coupon);
                          onOpen();
                        }}
                        disabled={!roles?.includes("MASTER", "COUPON")}
                        bgColor="transparent"
                        color="green.600"
                        align="center"
                        _hover={{ cursor: "pointer", color: "green.500" }}
                      >
                        <BiDetail size="20px" />
                      </Button>
                    </CustomTd>
                  </CustomTr>
                ))}
              </CustomTbody>
            </CustomTable>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}
            >
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>

      <Flex ml="7%" mt={5}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<BiFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn"
          >
            Per Page: {limit}
          </MenuButton>
          <MenuList color={colors.ocean} fontSize="14px">
            <MenuItem onClick={() => setLimit(10)}>10</MenuItem>
            <MenuItem onClick={() => setLimit(15)}>15</MenuItem>
            <MenuItem onClick={() => setLimit(20)}>20</MenuItem>
            <MenuItem onClick={() => setLimit(25)}>25</MenuItem>
            <MenuItem onClick={() => setLimit(30)}>30</MenuItem>
            <MenuItem onClick={() => setLimit(35)}>35</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex align="center" marginTop={-10} justify="center">
        <Pagination1
          pagesQuantity={Math.ceil(coupons?.total / limit)}
          curPage={page}
          setCurPage={setPage}
        />
      </Flex>

      <Modal
        isOpen={isOpen}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: "6xl",
        }}
        onClose={() => {
          onClose();
          setCoupon("");
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">Coupon Detail {coupon}</ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody>
            <SingleCoupon coupon={coupon} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ListCoupons;
