import React, { useState } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  VStack,
  Input,
  Button,
  Box,
  Image,
  useToast,
  Text,
  Badge,
  useMediaQuery,
  useBreakpointValue,
} from "@chakra-ui/react";
import logo3D from "../../assets/3d.png";
import { colors } from "../../utils/colors";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import logo from "../../assets/flatWhite.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../utils/auth";
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdparty";
import GoogleButton from "react-google-button";

async function googleSignInClicked() {
  try {
    const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
      thirdPartyId: "google",

      // This is where Google should redirect the user back after login or error.
      // This URL goes on the Google's dashboard as well.
      frontendRedirectURI: `${process.env.REACT_APP_REDIRECT_URL}`,
    });

    /*
        Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
        */
    console.log("test", authUrl);
    // we redirect the user to google for auth.
    window.location.assign(authUrl);
  } catch (err) {
    if (err.isSuperTokensGeneralError === true) {
      // this may be a custom error message sent from the API by you.
      window.location.assign("/");
      window.alert(err.message);
    } else {
      window.alert("Oops! Something went wrong.");
    }
  }
}

export default function LoginPage(props) {
  const isDesktop = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  });
  // const isDesktop = useMediaQuery("(min-width: 1000px)");
  console.log("min wi = ", isDesktop);
  const sub2 = (data) => console.log(data);
  return (
    <Flex
      bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      h="100vh"
      color="gray.50"
      justify="center"
      align="center">
      {/* {isDesktop ? ( */}
      <VStack
        display={{ base: "none", xl: "flex" }}
        align={`center`}
        w="40%"
        mb="50px"
        spacing={5}>
        <Box align={"center"} pb="10px">
          <Heading size="md">Dashboard Apps</Heading>
          <Image src={logo} h="250px" w="auto" textAlign="center" />
        </Box>
        <VStack
          align={`stretch`}
          p="10px"
          bgGradient={`linear(to-b, ${colors.blue}, ${colors.lightBlue})`}
          boxShadow="xl"
          borderRadius={15}
          borderWidth="1px"
          borderColor={`gray.400`}
          w="70%"
          spacing={3}>
          <Heading
            size="xs"
            textAlign={`center`}
            textTransform="uppercase"
            color={colors.darkblue}>
            Welcome back
          </Heading>
          <Heading size="md" textAlign={`center`}>
            Log into your account
          </Heading>
          <Flex flexDir={"row"} justifyContent={"center"} alignItems={"center"}>
            <GoogleButton
              type="light" // can be light or dark
              onClick={() => {
                googleSignInClicked();
              }}
            />
          </Flex>
          {/* <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel fontSize={14}>Email</FormLabel>
              <Input
                name="email"
                {...register("email", { required: true })}
                placeholder="Put your email here..."
                size="md"
                borderRadius={10}
                bgColor="gray.100"
                color={colors.ocean}
                type="email"
              />
              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors.email?.message}
              </Badge>
            </FormControl>
            <FormControl position="relative">
              <FormLabel fontSize={14}>Password</FormLabel>
              <Input
                name="password"
                {...register("password", { required: true })}
                placeholder="Put your password here..."
                size="md"
                borderRadius={10}
                bgColor="gray.100"
                type={!showPass && "password"}
                color={colors.ocean}
              />
              <Box
                color={colors.ocean}
                onClick={() => setShowPass(!showPass)}
                position="absolute"
                top="10"
                right="5"
                cursor="pointer"
                zIndex={1}>
                {!!showPass ? (
                  <AiOutlineEyeInvisible size="20px" />
                ) : (
                  <AiOutlineEye size="20px" />
                )}
              </Box>
              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors.password?.message}
              </Badge>
            </FormControl>
            {errordata && (
              <Box
                bgColor="red.400"
                borderRadius="5px"
                color="#f5f5f5"
                p="10px"
                textAlign="center">
                <Heading fontSize="sm" textTransform="uppercase">
                  Error Authentication
                </Heading>
                <Text fontSize="12px">Email | Password incorrect</Text>
              </Box>
            )}
            <Box py="10px">
              <Button
                color={colors.darkblue}
                w="100%"
                size="sm"
                borderRadius={10}
                boxShadow="xl"
                bgGradient={`linear(to-b, yellow.300, yellow.500)`}
                _hover={{ bgGradient: `linear(to-t, yellow.300, yellow.500)` }}
                type="submit">
                Login
              </Button>
            </Box>
          </form> */}
        </VStack>
      </VStack>
      {/* ) : ( */}
      <>
        <Flex
          display={{ base: "flex", xl: "none" }}
          w={"full"}
          h={"full"}
          flexDir={"column"}
          bg={"#010a20"}
          alignItems={"center"}
          px={4}
          py={24}>
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            w={"40%"}>
            {/* <Image src={logo} h="250px" w="auto" textAlign="center" /> */}
            <Image src={logo3D} h="150px" w="auto" textAlign="center" />
            <Heading size="lg">TickTickTrader</Heading>
          </Flex>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            h={"full"}
            px={12}
            w={"full"}>
            <Box
              w={"fit-content"}
              h={"fit-content"}
              maxH={"fit-content"}
              p={4}
              gap={6}
              rounded={"2xl"}
              bg={"#132045"}>
              <Heading textAlign={"center"} mb={4} size={"md"}>
                Welcome to TTTApps platform!
              </Heading>
              <Text fontWeight={100} textAlign={"center"}>
                Please ensure you're accessing the platform via the Web view for
                optimal performance and access. <br /> This dedicated platform
                is tailored to streamline your support processes and enhance
                your efficiency.{" "}
              </Text>
              <Text textAlign={"center"} fontWeight={100} mt={3}>
                Kindly switch to the Web view to make the most out of its
                features.
              </Text>
            </Box>
          </Flex>
        </Flex>
      </>
      {/* )} */}
    </Flex>
  );
}
