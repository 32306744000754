import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useDisclosure,
  Box,
  chakra,
  Tooltip,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import { AiOutlineEye } from "react-icons/ai";
import { useQuery, useQueryClient } from "react-query";
import { colors } from "../../utils/colors";

import useGetDailPnl, {
  getDailyPnl,
} from "../../hooks/useQueries/useGetDailyPnl";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from "moment";
function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

function DailyPnlModal({ id, isOpen, onClose }) {
  const { DailyPnl, refetchDailyPnl } = useGetDailPnl(id);
  console.log("test = ", DailyPnl);

  return (
    <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={18}>DailyPnL of {id}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box
            borderRadius={15}
            overflow={"scroll"}
            bgColor="gray.50"
            color="#000"
            maxH="550px">
            {DailyPnl?.length > 0 ? (
              <CustomTable borderRadius={20}>
                <CustomThead
                  fontSize="15px"
                  h="40px"
                  bgGradient={`linear(to-b, #46ced8, #008ab4)`}>
                  <CustomTr
                    textAlign="center"
                    textTransform="capitalize"
                    h="30px"
                    borderBottomWidth={1}
                    borderRadius="20px">
                    <CustomTh fontSize={15}>AccountId</CustomTh>
                    <CustomTh fontSize={15}>UserId</CustomTh>
                    <CustomTh fontSize={15}>balance</CustomTh>
                    <CustomTh fontSize={15}>lastCashOnHand</CustomTh>
                    <CustomTh fontSize={15}>Pnl</CustomTh>
                    <CustomTh fontSize={15}>CreatedAt</CustomTh>
                  </CustomTr>
                </CustomThead>

                <CustomTbody
                  overflow={`scroll`}
                  bgGradient={`linear(to-l, gray.100, gray.300)`}
                  color={colors.ocean}>
                  {DailyPnl?.map((pnl, i) => (
                    <CustomTr
                      textAlign="center"
                      fontSize={{
                        "2xl": "13px",
                        xl: "13px",
                        md: "12px",
                        lg: "12px",
                        sm: "10px",
                      }}
                      key={i}
                      borderBottom="1px"
                      borderColor={colors.lightBlue}>
                      <CustomTd>{pnl?.AccountId}</CustomTd>
                      <CustomTd>{pnl.UserId}</CustomTd>
                      <CustomTd>{pnl.balance}</CustomTd>
                      <CustomTd>
                        {pnl?.lastCashOnHand ? pnl?.lastCashOnHand : " - "}
                      </CustomTd>
                      <CustomTd>{pnl?.Pnl?.toFixed(2)} </CustomTd>
                      <CustomTd>
                        {moment(pnl?.CreatedAt).format("YYYY-MM-DD")}
                      </CustomTd>
                    </CustomTr>
                  ))}
                </CustomTbody>
              </CustomTable>
            ) : (
              <Flex
                borderRadius={15}
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color={colors.ocean}
                boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
                p="20px"
                h="500px"
                justify={`center`}
                align="center"
                fontSize={30}>
                No data to display !!
              </Flex>
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} size="sm" bgColor="gray.300">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default function DailyPnLList({ id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log("black date")

  return (
    <>
      <Tooltip label="Read reason" placement="auto">
        <Box
          color={colors.ocean}
          align="center"
          _hover={{ cursor: "pointer", color: colors.lightBlue }}>
          <AiOutlineEye
            size="20px"
            onClick={() => {
              onOpen();
            }}
          />
        </Box>
      </Tooltip>
      {isOpen === true && (
        <DailyPnlModal id={id} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
}
