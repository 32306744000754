import {
  Box,
  Flex,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Divider,
  Badge,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineFilter } from "react-icons/ai";
import { BiEdit, BiFilter, BiCheck, BiPlus } from "react-icons/bi";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { colors } from "../../utils/colors";
import { useRecoilState, useRecoilValue } from "recoil";
import DailyPnLList from "./DailyPnlList";
import {
  filterConsistencyListcSelector,
  filterConsistencyListState,
} from "../../recoil";
import "../../../node_modules/react-super-responsive-table/dist/index";

// import useAllMultipleAccountlists from "../../hooks/useQueries/useMultipleAccount";
import useConsistencyList from "../../hooks/useQueries/useConsistencyList";
import Pagination1 from "../../utils/pagination";
import useMultipleAccountMutation from "../../hooks/useMutations/useMultipleAccountMutation";
import UpdateMultipleAccount from "./UpdateMultipleAccount";
import AddMultipleAccount from "./AddMultipleAccount";
import { getResolvedStatus } from "../../utils/commen";

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

const ConsitencyList = () => {
  let toast = useToast();

  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [accountId, setAccountId] = useState("");
  const [userId, setUserId] = useState("");
  const [provider, setProvider] = useState("all");
  const [providerCheck, setProviderCheck] = useState("all");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const query = useRecoilValue(filterConsistencyListcSelector);

  document.title = "TTTSubscriptions | Multiple Accounts List";

  const [filterMultipleAccountlist, setFilterMultipleAccountlist] =
    useRecoilState(filterConsistencyListState);

  const { constistencyList, refetchConsistenctList } = useConsistencyList(
    query + `&page=${page}&limit=${limit}`
  );

  useEffect(() => {
    return () => {
      setFilterMultipleAccountlist({
        AccountId: "",
        UserId: "",
        provider: "",
      });
      refetchConsistenctList();
    };
  }, []);

  const filterPayment = () => {
    return (
      <div>
        <div
          style={{
            margin: "1.5rem 0",
          }}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "1.5rem 0",
              // width: "100%",
            }}>
            <p
              style={{
                width: "100px",
              }}>
              AccountId:
            </p>
            <Input
              style={{
                margin: "0 1.5rem",
                minWidth: "340px",
              }}
              w={"340px"}
              type={"text"}
              name="publicId"
              value={accountId}
              onChange={(e) => {
                setAccountId(e.target.value);
              }}
            />
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "1.5rem 0",
              // width: "100%",
            }}>
            <p
              style={{
                width: "100px",
              }}>
              UserID :
            </p>
            <Input
              style={{
                margin: "0 1.5rem",
                minWidth: "340px",
              }}
              w={"340px"}
              name="userId"
              type={"text"}
              value={userId}
              onChange={(e) => {
                setUserId(e.target.value);
              }}
            />
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "1.5rem 0",
              // width: "100%",
            }}>
            <p
              style={{
                width: "100px",
                maxWidth: "76px",
              }}>
              Provider :
            </p>

            <Select
              style={{
                minWidth: "340px",
              }}
              w={"340px"}
              name="provider"
              value={provider}
              onChange={(e) => {
                setProvider(e.target.value);
              }}>
              <option value={"all"}>All</option>
              <option value={"rithmic"}>RITHMIC</option>
              <option value={"cqg"}>CQG</option>
            </Select>
          </label>
        </div>
      </div>
    );
  };
  //   console.log("data =", constistencyList[0]);
  return (
    <>
      <Flex flexDir={"column"} justify={"space-evenly"}>
        <Box
          px="100px"
          py="40px"
          h="calc(100vh - 200px)"
          color="gray.50"
          overflow="auto">
          <Flex align="end" justify="end" pb="40px">
            <Button
              padding={"10px 30px"}
              mx="20px"
              rightIcon={<AiOutlineFilter size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setIsFilter(true);
                onOpen();
              }}>
              Filter
            </Button>
            <Button
              rightIcon={<AiOutlineEye size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setFilterMultipleAccountlist({
                  AccountId: "",
                  UserId: "",
                  provider: "",
                });
              }}>
              View all
            </Button>
          </Flex>

          <Box
            borderRadius={15}
            overflow={"scroll"}
            bgColor="gray.50"
            color="#000"
            maxH="550px">
            {constistencyList?.consistency?.length > 0 ? (
              <CustomTable borderRadius={20}>
                <CustomThead
                  fontSize="15px"
                  h="40px"
                  className="table__head"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                >
                  <CustomTr
                    textAlign="center"
                    textTransform="capitalize"
                    className="table__head"
                    h="30px"
                    borderBottomWidth={1}
                    borderRadius="20px">
                    <CustomTh fontSize={15}>AccountId</CustomTh>
                    <CustomTh fontSize={15}>UserId</CustomTh>
                    <CustomTh fontSize={15}>Provider</CustomTh>
                    {/* <CustomTh fontSize={15}>Public Id</CustomTh> */}
                    <CustomTh fontSize={15}>isConsistent</CustomTh>
                    <CustomTh fontSize={15}>maxPnl</CustomTh>
                    <CustomTh fontSize={15}>totalPnl</CustomTh>
                    <CustomTh fontSize={15}>Show details</CustomTh>
                  </CustomTr>
                </CustomThead>

                <CustomTbody
                  overflow={`scroll`}
                  bgGradient={`linear(to-l, gray.100, gray.300)`}
                  color={colors.ocean}>
                  {constistencyList?.consistency?.map((consistent, i) => (
                    <CustomTr
                      textAlign="center"
                      fontSize={{
                        "2xl": "13px",
                        xl: "13px",
                        md: "12px",
                        lg: "12px",
                        sm: "10px",
                      }}
                      key={i}
                      // borderBottom="1px"
                      // borderColor={colors.lightBlue}
                    >
                      <CustomTd>{consistent?.AccountId}</CustomTd>
                      <CustomTd>{consistent.UserId}</CustomTd>
                      <CustomTd>
                        {consistent.UserId?.startsWith("TTT")
                          ? "RITHMIC"
                          : "CQG"}
                      </CustomTd>
                      <CustomTd>
                        <Badge
                          variant={"outline"}
                          colorScheme={
                            consistent.isConsistent === true ? "green" : "red"
                          }>
                          {consistent.isConsistent === true
                            ? "Consistent"
                            : "Not Consistent"}
                        </Badge>
                      </CustomTd>
                      <CustomTd>{consistent?.maxPnl?.toFixed(2)}</CustomTd>
                      <CustomTd>{consistent?.totalPnl?.toFixed(2)} </CustomTd>
                      <CustomTd>
                        <DailyPnLList id={consistent.AccountId} />
                      </CustomTd>
                    </CustomTr>
                  ))}
                </CustomTbody>
              </CustomTable>
            ) : (
              <Flex
                borderRadius={15}
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color={colors.ocean}
                boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
                p="20px"
                h="500px"
                justify={`center`}
                align="center"
                fontSize={30}>
                No data to display !!
              </Flex>
            )}
          </Box>
        </Box>

        <Pagination1
          pagesQuantity={constistencyList?.total / limit}
          curPage={page}
          setCurPage={setPage}
        />

        {/* <Flex ml="7%">
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<BiFilter size="20px" />}
              borderRadius={50}
              fontSize="12px"
              bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}>
              Per Page: {limit === 9 ? 10 : limit}
            </MenuButton>
            <MenuList color={colors.ocean} fontSize="14px">
              <MenuItem onClick={() => setLimit(10)}>10</MenuItem>
              <MenuItem onClick={() => setLimit(15)}>15</MenuItem>
              <MenuItem onClick={() => setLimit(20)}>20</MenuItem>
              <MenuItem onClick={() => setLimit(25)}>25</MenuItem>
              <MenuItem onClick={() => setLimit(30)}>30</MenuItem>
              <MenuItem onClick={() => setLimit(35)}>35</MenuItem>
            </MenuList>
          </Menu>
        </Flex> */}
        {/* {multipleAccounts?.totalPages && (
          <Pagination1
            pagesQuantity={multipleAccounts?.totalPages}
            curPage={page}
            setCurPage={setPage}
          />
        )} */}
        <Modal
          isOpen={isOpen}
          scrollBehavior={"inside"}
          size={{
            sm: "md",
            lg: "lg",
          }}
          onClose={() => {
            onClose();
            setIsFilter(false);
          }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color="gray.500">{isFilter && "Filters"}</ModalHeader>
            <Divider style={{ borderBottom: "1.5px solid black" }} />
            <ModalCloseButton />
            <ModalBody h="100vh">
              <div>{isFilter && filterPayment()}</div>
            </ModalBody>
            <ModalFooter>
              {isFilter && (
                <Button
                  colorScheme="blue"
                  className="app_btn"
                  mr={3}
                  onClick={() => {
                    if (provider !== providerCheck) {
                      setProviderCheck(provider);
                      setPage(1);
                    }
                    setFilterMultipleAccountlist({
                      AccountId: accountId,
                      UserId: userId,
                      provider: provider,
                    });

                    setIsFilter(false);
                    onClose();
                  }}>
                  Apply
                </Button>
              )}

              {isFilter && (
                <Button
                  onClick={() => {
                    setAccountId("");
                    setUserId("");
                  }}>
                  Reset
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

export default ConsitencyList;
