import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  VStack,
  Heading,
  Text,
  Avatar,
  Button,
} from "@chakra-ui/react";
import { colors } from "../../utils/colors";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";
import { MdOutlineDashboard, MdSettings } from "react-icons/md";
import {
  AiOutlineUser,
  AiOutlineLineChart,
  AiOutlineLogout,
} from "react-icons/ai";
import { VscSettings } from "react-icons/vsc";
import { IoDocumentsOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
import "./sidebar.css";

import axios from "axios";
import { client } from "../../utils/axios";
const locations = ["/", "/list-admin", "/apps"];

const Selected = () => {
  return (
    <Box
      w="95%"
      h="100%"
      // bgColor="gray.50"
      borderTopRightRadius={110}
      borderBottomRightRadius={110}
      alignItems="center"
      justifyContent={`center`}
      display="flex"
    >
      <Box
        bgGradient={`linear(to-l, ${colors.blue}, ${colors.lightBlue})`}
        w="10px"
        h="10px"
        borderRadius={100}
      />
    </Box>
  );
};

const ListItem = ({ Icon, Item, loc, onClick }) => {
  let location = useLocation().pathname;

  return (
    <Flex
      align="center"
      justify={`center`}
      w="85%"
      cursor="pointer"
      _hover={{
        bgColor: "#ffff",
        color: colors.lightBlue,
        borderRadius: "20px",
        boxShadow: "lg",
        fontWeight: "bold",
      }}
      h="40px"
      fontSize={14}
      onClick={onClick}
      bgColor={location === loc && "#ffff"}
      color={location === loc && colors.lightBlue}
      borderRadius={location === loc && "20px"}
      boxShadow={location === loc && "lg"}
      fontWeight={location === loc && "bold"}
    >
      <Box w="20%">{Icon}</Box>
      <Box w="70%">
        <Text>{Item}</Text>
      </Box>
    </Flex>
  );
};

export default function Sidebar() {
  const [user, setUser] = useState();

  let sessionContext = Session.useSessionContext();
  useEffect(() => {
    getUser();
  }, []);

  let auth = useAuth();
  async function getUser() {
    try {
      const user = await client.get(`${process.env.REACT_APP_API_HOST}/whoami`);
      setUser(user.data);
    } catch (e) {
      console.log("e", e);
    }
    // return user;
    // console.log("user", user);
  }

  async function onLogout() {
    const a = await Session.signOut();
    // console.log("a", a);
    window.location.href = "/";
  }

  const navigate = useNavigate();
  let location = useLocation().pathname;

  const substringName = (text, end) =>
    text && text.length > end ? text.substring(0, end) + "..." : text;
  return (
    <VStack
      // bgGradient={`linear(to-br, transparent, rgba(217, 217, 217, 0.5)), transparent)`}
      className="sidebar_nav"
      // h="calc(100vh - 20px)"
      h="100vh"
      borderTopLeftRadius={15}
      borderRightRadius={15}
      // borderWidth="1px"
      // borderColor="#a3f0ee"
      boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
      alignItems="center"
      align={`center`}
      spacing={10}
      color="#000"
      bg="#fff"
    >
      <Flex flexDir={"row"} align={`center`} justify="center" pt="50px">
        <Image src={logo} w="40%" h="auto" opacity={1} />
        <Image src={logo1} w="40%" h="auto" opacity={1} />
      </Flex>

      <Box align="center">
        {/* <Avatar
          size="xl"
          src="https://portal.tickticktrader.com/src/assets/icons/light/avatar.png"
        /> */}
        <img src="https://portal.tickticktrader.com/src/assets/icons/light/avatar.png" />
        <Heading size="sm" py="10px">
          {substringName(user?.fullName, 15)}
        </Heading>
      </Box>

      {/* -------------- Apps -------------- */}
      {/* <ListItem
            Item="Apps"
            Icon={<VscSettings size="20px" />}
            loc="/apps"
            onClick={() => navigate("/apps")}
          /> */}
      <Flex
        // className="sidebar_links"
        className={`${
          location === "/apps"
            ? "side__bar_link_active sidebar_links"
            : "sidebar_links"
        }`}
        onClick={() => navigate("/apps")}
      >
        <Box onClick={() => navigate("/apps")}>
          <div className="sidebar_link">
            <div className="sidebar_link_item">
              <VscSettings size="20px" />
              <p>Apps</p>
            </div>
            <div className="sidebar_icons">
              {location === "/apps" && <div className="active-selector" />}
            </div>
          </div>
        </Box>
      </Flex>

      {/* -------------- New Admin -------------- */}

      {/* -------------- List Admins -------------- */}

      {sessionContext &&
        sessionContext?.accessTokenPayload &&
        sessionContext.accessTokenPayload["st-role"].v.includes("MASTER") && (
          <Flex
            // className="sidebar_links"
            className={`${
              location === "/admins"
                ? "side__bar_link_active sidebar_links"
                : "sidebar_links"
            }`}
            onClick={() => navigate("/admins")}
          >
            <Box onClick={() => navigate("/admins")}>
              <div className="sidebar_link">
                <div className="sidebar_link_item">
                  <IoDocumentsOutline size="20px" />
                  <p>Admins</p>
                </div>
                <div className="sidebar_icons ">
                  {location === "/admins" && (
                    <div className="active-selector" />
                  )}
                </div>
              </div>
            </Box>
          </Flex>
        )}
      {/* -------------- Profile -------------- */}

      <Flex
        // className="sidebar_links"
        className={`${
          location === "/profile"
            ? "side__bar_link_active sidebar_links"
            : "sidebar_links"
        }`}
        onClick={() => navigate("/profile")}
      >
        <Box onClick={() => navigate("/profile")}>
          <div className="sidebar_link">
            <div className="sidebar_link_item">
              <AiOutlineUser size="20px" />
              <p>Profile</p>
            </div>
            <div className="sidebar_icons">
              {location === "/profile" && <div className="active-selector" />}
            </div>
          </div>
        </Box>
      </Flex>

      <Button
        onClick={async () => onLogout()}
        bgGradient={`linear(180deg, #E0354A 0%, #8B0101 100%)`}
        color="#fff"
        _focus={{}}
        boxShadow={`lg`}
        h="40px"
        w="80%"
        borderRadius={20}
        _hover={{ bgGradient: `linear(to-r, red.500, red.800)` }}
        size="md"
      >
        Logout
      </Button>
    </VStack>
  );
}
