import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery, useQueryClient } from "react-query";

const checkerTR = (query, auth) => async () => {
  try {
    const params = new URLSearchParams(query);
    const accountName = params.get("accountName");
    if (!accountName) {
      throw "AccountName is required";
    }
    const { data } = await client.get(
      `${process.env.REACT_APP_TRADOVATE_URL}/accounts/getTargetReachedEmail?${query}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_TV_API_KEY,
        },
        method: "GET",
      }
    );
    return data;
  } catch (error) {
    // Re-throw error so it's caught by the useQuery's onError handler
    throw error;
  }
};

const useGetTRChecker = (query) => {
  const auth = useAuth();

  const {
    data,
    error,
    refetch,
    isError, // This is a boolean that is true when an error occurs
  } = useQuery(["coupons", query], checkerTR(query, auth), {
    staleTime: ms("1s"),
    refetchInterval: ms("5m"),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    keepPreviousData: true,
    retry: false, // Retry can be set to false, or you can customize retries
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  return {
    check: data,
    refetchCheck: refetch,
    error, // Return the error object
    isError, // Return a boolean indicating if there's an error
  };
};

export default useGetTRChecker;
