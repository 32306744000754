import { Flex, Box, Image, Heading } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
// import logo from "../../assets/logo.png";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";
import { BiMessageSquareAdd, BiTable, BiHome, BiStats } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router";
import { AiOutlineDashboard } from "react-icons/ai";
// import bookmap from "../../assets/images/bookmap.svg";
import bookmap from "../../assets/appLogos/Bookmap.jpg";

export default function NavbarBookmap() {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  return (
    <Flex
      justify={`space-between`}
      align="center"
      h="70px"
      w="100%"
      boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
      color={"#000"}
      bg="#fff"
      px="20px">
      <Flex w="20%">
        <Image src={bookmap} rounded={"xl"} h="60px" />
        {/* <Image src={logo} h="50px" w="auto" /> */}
        <Image
          src={logo}
          w="auto"
          h="60px"
          opacity={1}
          onClick={() => navigate("/apps")}
        />
        <Image src={logo1} w="auto" h="60px" opacity={1} />
      </Flex>
      <Flex
        justify={`space-around`}
        align="center"
        color="gray.50"
        w="60%"
        onClick={() => {
          //console.log("location D", location);
        }}>
        <Flex
          // _hover={{
          //   bgColor: "gray.50",
          //   color: colors.ocean,
          //   FlexShadow: "lg",
          //   cursor: "pointer",
          // }}
          // p="10px"
          cursor="pointer"
          // borderRadius={20}
          className="nav__bar__link"
          onClick={() => navigate("/apps")}
          bgColor={location === "/apps" && "gray.50"}
          color={location === "/apps" && colors.ocean}
          boxShadow={location === "/apps" && "lg"}>
          <Box>
            <BiHome color="#000" size="20px" />
          </Box>
          <Box mt="2px">
            <Heading color="#000" size="xs">
              &nbsp; Apps
            </Heading>
          </Box>
        </Flex>

        <Flex
          // _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          // p="10px"
          // borderRadius={20}
          className={`${
            location === "/apps/tttbookmap/add-bookmap-license"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          onClick={() => navigate("/apps/tttbookmap/add-bookmap-license")}
          cursor="pointer"
          // bgColor={
          //   location === "/apps/tttbookmap/add-bookmap-license" && "gray.50"
          // }
          // color={
          //   location === "/apps/tttbookmap/add-bookmap-license" && colors.ocean
          // }
          // boxShadow={
          //   location === "/apps/tttbookmap/add-bookmap-license" && "lg"
          // }
        >
          <Box>
            <BiMessageSquareAdd
              color={
                location === "/apps/tttbookmap/add-bookmap-license"
                  ? "white"
                  : "black"
              }
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              color={
                location === "/apps/tttbookmap/add-bookmap-license"
                  ? "white"
                  : "black"
              }
              size="xs">
              &nbsp; Add licence
            </Heading>
          </Box>
        </Flex>

        <Flex
          // _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          // p="10px"
          // borderRadius={20}
          className={`${
            location === "/apps/tttbookmap/list-licenses"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          onClick={() => {
            navigate("/apps/tttbookmap/list-licenses");
          }}
          cursor="pointer"
          bgColor={location === "/apps/tttbookmap/list-licenses" && "gray.50"}
          color={location === "/apps/tttbookmap/list-licenses" && colors.ocean}
          boxShadow={location === "/apps/tttbookmap/list-licenses" && "lg"}>
          <Box>
            <BiTable
              color={
                location === "/apps/tttbookmap/list-licenses"
                  ? "white"
                  : "black"
              }
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              color={
                location === "/apps/tttbookmap/list-licenses"
                  ? "white"
                  : "black"
              }
              size="xs">
              &nbsp; Assigned Licenses List
            </Heading>
          </Box>
        </Flex>

        <Flex
          // _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          // p="10px"
          // borderRadius={20}
          onClick={() => navigate("/apps/tttbookmap/available-licenses")}
          cursor="pointer"
          className={`${
            location === "/apps/tttbookmap/available-licenses"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          // bgColor={
          //   location === "/apps/tttbookmap/available-licenses" && "gray.50"
          // }
          // color={
          //   location === "/apps/tttbookmap/available-licenses" && colors.ocean
          // }
          // boxShadow={location === "/apps/tttbookmap/available-licenses" && "lg"}
        >
          <Box>
            <BiTable
              color={
                location === "/apps/tttbookmap/available-licenses"
                  ? "white"
                  : "black"
              }
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              color={
                location === "/apps/tttbookmap/available-licenses"
                  ? "white"
                  : "black"
              }
              size="xs">
              &nbsp; Available Licenses
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
