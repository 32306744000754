export const colors = {
  blue: "#0088ab",
  darkblue:"#0f0f29",
  ocean: "#006887",
  green: "#20a000",
  yellow: "#fecc02",
  lightBlue: "#00a6b1",
  lightRed: "#fc1d0094",
  lightGreen: "#8ab227",
  lightYellow: "#fecc028f",
  dark: "#444",
  light: "gray.200"
};
