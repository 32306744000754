import axios from "axios";
/* import sleep from "utils/sleep";
 */
export const POST = async (path, data) =>
  await axios.post(`${process.env.REACT_APP_API_URL}${path}`, data);

export const GET = async (path) => {
  /* await sleep(4); */
  return await axios.get(`${process.env.REACT_APP_API_URL}${path}`);
};

export const PATCH = async (path, data) =>
  await axios.patch(`${process.env.REACT_APP_API_URL}${path}`, data);

export const DELETE = async (path, data) =>
  await axios.delete(`${process.env.REACT_APP_API_URL}${path}`, {
    data,
  });
