import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { colors } from "../../utils/colors";
import { Box, Heading, VStack, Image } from "@chakra-ui/react";
import bookmap from "../../assets/bookmap.png";
import { Link } from "@chakra-ui/react";

const Funded = () => {
  return (
    <>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(2, 1fr)"
        gap={4}
        h="calc(100vh - 70px)"
        p="50px"
      >
        <Link
          rel="noreferrer"
          href="/apps/funded/list"
          colSpan={2}
          bgGradient={`linear(to-l, ${colors.ocean}, ${colors.lightBlue})`}
          borderRadius={20}
          display="flex"
          justifyContent={`center`}
          alignItems="center"
          color={colors.light}
          w="100%"
        >
          <GridItem>
            <VStack align={"center"} spacing={5}>
              <Heading size="lg">Funded List</Heading>
            </VStack>
          </GridItem>
        </Link>
        <Link
          rel="noreferrer"
          //   href="/funded/multiple-accounts/list"
          href="/apps/funded/multiple-accounts/list"
          colSpan={2}
          bgGradient={`linear(to-l, ${colors.ocean}, ${colors.lightBlue})`}
          borderRadius={20}
          display="flex"
          justifyContent={`center`}
          alignItems="center"
          color={colors.light}
          w="100%"
        >
          <GridItem>
            <VStack align={"center"} spacing={5}>
              <Heading size="lg">Multiple Accounts List</Heading>
            </VStack>
          </GridItem>
        </Link>
      </Grid>
    </>
  );
};

export default Funded;
