import { Flex, Box, Image, Heading } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
// import logo from "../../assets/logo.png";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";
import coupons from "../../assets/images/funded.webp";
import { BiMessageSquareAdd, BiTable, BiHome, BiStats } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router";
import funded from "../../assets/appLogos/PerformanceAccount.webp";
const NavbarFunded = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  return (
    <>
      <Flex
        justify={`space-between`}
        align="center"
        h="70px"
        w="100%"
        boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
        color={"#000"}
        px="20px">
        <Flex w="20%">
          <Image
            src={funded}
            h="50px"
            rounded={"xl"}
            w="auto"
            style={{ marginTop: "6px" }}
          />
          <Image
            src={logo}
            h="60px"
            w="auto"
            onClick={() => navigate("/apps")}
          />
          <Image
            src={logo1}
            h="60px"
            w="auto"
            onClick={() => navigate("/apps")}
          />
        </Flex>
        <Flex justify={`space-around`} align="center" color="gray.50" w="60%">
          <Flex
            cursor="pointer"
            className="nav__bar__link"
            onClick={() => navigate("/apps")}
            bgColor={location === "/apps" && "gray.50"}
            color={location === "/apps" && colors.ocean}
            boxShadow={location === "/apps" && "lg"}>
            <Box>
              <BiHome color={"#000"} size="20px" />
            </Box>
            <Box mt="2px">
              <Heading size="xs" color={"#000"}>
                &nbsp; Apps
              </Heading>
            </Box>
          </Flex>

          <Flex
            className={`${
              location === "/apps/funded/list"
                ? "sidebar_link_active"
                : "nav__bar__link"
            }`}
            onClick={() => navigate("/apps/funded/list")}
            cursor="pointer">
            <Box>
              <BiTable
                color={location === "/apps/funded/list" ? "white" : "black"}
                size="20px"
              />
            </Box>
            <Box mt="2px">
              <Heading
                size="xs"
                color={location === "/apps/funded/list" ? "white" : "black"}>
                &nbsp; Performance
              </Heading>
            </Box>
          </Flex>
          <Flex
            className={`${
              location === "/apps/funded/multiple-accounts/list"
                ? "sidebar_link_active"
                : "nav__bar__link"
            }`}
            onClick={() => navigate("/apps/funded/multiple-accounts/list")}
            cursor="pointer">
            <Box>
              <BiTable
                color={
                  location === "/apps/funded/multiple-accounts/list"
                    ? "white"
                    : "black"
                }
                size="20px"
              />
            </Box>
            <Box mt="2px">
              <Heading
                size="xs"
                color={
                  location === "/apps/funded/multiple-accounts/list"
                    ? "white"
                    : "black"
                }>
                &nbsp; Multiple Accounts
              </Heading>
            </Box>
          </Flex>
          <Flex
            className={`${
              location === "/apps/funded/Consitency/list"
                ? "sidebar_link_active"
                : "nav__bar__link"
            }`}
            onClick={() => navigate("/apps/funded/Consitency/list")}
            cursor="pointer">
            <Box>
              <BiTable
                color={
                  location === "/apps/funded/Consitency/list"
                    ? "white"
                    : "black"
                }
                size="20px"
              />
            </Box>
            <Box mt="2px">
              <Heading
                size="xs"
                color={
                  location === "/apps/funded/Consitency/list"
                    ? "white"
                    : "black"
                }>
                &nbsp; Consistency Table
              </Heading>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default NavbarFunded;
