import ThirdParty, {
  Github,
  Apple,
  Google,
} from "supertokens-auth-react/recipe/thirdparty";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
  const apiUrl = process.env.REACT_APP_AUTH_HOST;
  return apiUrl;
}

export function getWebsiteDomain() {
  const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3008;
  const websiteUrl =
    process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
  return websiteUrl;
}

export const SuperTokensConfig = {
  appInfo: {
    appName: "ticktickAuth",
    apiDomain: getApiDomain(),
    apiBasePath: process.env.REACT_APP_API_BASE_PATH,
    websiteBasePath: "/",
    websiteDomain: getWebsiteDomain(),
  },
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    ThirdParty.init({
      style: `
                [data-supertokens~=container] {
                    width: 500px;
                }
            `,
      useShadowDom: false,
      signInAndUpFeature: {
        providers: [Google.init()],
      },
      getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return context.redirectToPath;
          }
          return process.env.REACT_APP_REDIRECT_PATH;
          // window.location.href = "http://159.69.142.232:3008/";
        }
        return undefined;
      },

      // override: {
      //   functions: (oI) => {
      //     return {
      //       ...oI,
      //       getAuthStateFromURL: (input) => {
      //         if (input.userContext.state) {
      //           return input.userContext.state;
      //         }
      //         return oI.getAuthStateFromURL(input);
      //       },
      //     };
      //   },
      // },
      // preAPIHook: async (context) => {
      //   if (context.action === "THIRD_PARTY_SIGN_IN_UP") {
      //     if (typeof context.requestInit.body !== "string") {
      //       throw new Error("should not happen");
      //     }
      //     let body = JSON.parse(context.requestInit.body);

      //     body!.redirectURIInfo = undefined;
      //     body!.oAuthTokens = { id_token: context.userContext.id_token };

      //     context.requestInit.body = JSON.stringify(body);
      //   }
      //   return context;
      // },
    }),
    Session.init({
      sessionTokenBackendDomain: process.env.REACT_APP_AUTH_HOST,
      tokenTransferMethod: "header",
      // override: {
      //   functions: (originalImplementation) => {
      //     return {
      //       ...originalImplementation,
      //       createNewSession: async function (input) {
      //         let userId = input.userId;
      //         // This goes in the access token, and is availble to read on the frontend.
      //         input.accessTokenPayload = {
      //           ...input.accessTokenPayload,
      //           someKey: "someValue",
      //         };
      //         return originalImplementation.createNewSession(input);
      //       },
      //     };
      //   },
      // },
    }),
  ],
};

export const recipeDetails = {
  docsLink: "https://supertokens.com/docs/thirdparty/introduction",
};
