import React, { useState, useEffect } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import {
  Previous,
  Paginator,
  PageGroup,
  Page,
  Next,
  generatePages,
} from "chakra-paginator";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { useNavigate } from "react-router";
import Pagination from "@choc-ui/paginator";

// import ItemList from "./ItemList";

// function Pagination(props) {
//   const items = [
//     { name: "Apple", quantity: 3 },
//     { name: "Banana", quantity: 5 },
//     { name: "Carrot", quantity: 4 },
//     { name: "Chocolate", quantity: 1 },
//     { name: "Marshmallow", quantity: 5 },
//     { name: "Cinnamon", quantity: 10 },
//     { name: "Lasagna", quantity: 10 },
//     { name: "Linguine", quantity: 20 },
//   ];
//   const itemLimit = props.limit;
//   //   const [pagesQuantity, setPagesQuantity] = useState(0);
//   //   const [curPage, setCurPage] = useState(0);

//   //   const normalStyles = {
//   //     bg: "white",
//   //   };

//   //   const activeStyles = {
//   //     bg: "blue.300",
//   //   };
//   const normalStyles = {
//     w: 7,
//     bg: "red.300",
//     fontSize: "sm",
//     _hover: {
//       bg: "green.300",
//     },
//   };

//   const activeStyles = {
//     w: 7,
//     bg: "green.300",
//     fontSize: "sm",
//     _hover: {
//       bg: "blue.300",
//     },
//   };

//   const separatorStyles = {
//     w: 7,
//     bg: "green.200",
//   };

//   const handlePageChange = (page) => {
//     props.setCurPage(page);
//   };

//   useEffect(() => {
//     // console.log("rip", props.pagesQuantity);
//     // const pagesTotal = Math.ceil(items.length / itemLimit);
//     // props.setPagesQuantity(props.pagesTotal);
//   }, [props.pagesQuantity, props.isChecked]);

//   return (
//     <Box>
//       {/* <ItemList items={items} curPage={curPage} itemLimit={itemLimit} /> */}
//       <Flex p={2}>
//         {/* <Spacer /> */}
//         {props.pagesQuantity > 1 && (
//           <Paginator
//             onPageChange={handlePageChange}
//             pagesQuantity={props.pagesQuantity - 1}>
//             <Previous bg="white">
//               <CgChevronLeft size={3} />
//             </Previous>
//             <PageGroup>
//               {generatePages(props.pagesQuantity)?.map((page) => (
//                 <Page
//                   color={"transparent"}
//                   size={3}
//                   key={`paginator_page_${page}`}
//                   page={page}
//                   normalStyles={normalStyles}
//                   activeStyles={activeStyles}
//                   separatorStyles={separatorStyles}
//                 />
//               ))}
//             </PageGroup>
//             <Next bg="white">
//               <CgChevronRight size={3} />
//             </Next>
//           </Paginator>
//         )}
//       </Flex>
//     </Box>
//   );
// }
export function Pagination1(props) {
  const items = [
    { name: "Apple", quantity: 3 },
    { name: "Banana", quantity: 5 },
    { name: "Carrot", quantity: 4 },
    { name: "Chocolate", quantity: 1 },
    { name: "Marshmallow", quantity: 5 },
    { name: "Cinnamon", quantity: 10 },
    { name: "Lasagna", quantity: 10 },
    { name: "Linguine", quantity: 20 },
  ];
  const itemLimit = props.limit;
  //   const [pagesQuantity, setPagesQuantity] = useState(0);
  //   const [curPage, setCurPage] = useState(0);

  //   const normalStyles = {
  //     bg: "white",
  //   };

  //   const activeStyles = {
  //     bg: "blue.300
  //   };

  // const [selected, setSelected] = useState("");
  const normalStyles = {
    w: 7,
    bg: "red.300",
    fontSize: "sm",
    _hover: {
      bg: "green.300",
    },
  };

  const activeStyles = {
    w: 7,
    bg: "green.300",
    fontSize: "sm",
    _hover: {
      bg: "blue.300",
    },
  };

  const separatorStyles = {
    w: 7,
    bg: "green.200",
  };

  const handlePageChange = (page) => {
    props.setCurPage(page);
  };

  const [start, setStart] = useState(props.curPage);
  const [end, setEnd] = useState(props.pagesQuantity - 4);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("rip", props.pagesQuantity);
    // if (props.curPage)
    // const pagesTotal = Math.ceil(items.length / itemLimit);
    // props.setPagesQuantity(props.pagesTotal);
  }, [props.pagesQuantity, props.isChecked]);
  // console.log(Number(props.pagesQuantity));
  return (
    <>
      {Number(props.pagesQuantity) > 1 && (
        <div className="bg-gradient-to-l from-sideBarOne to-SideBarTwo px-4 py-3 rounded-lg flex items-center justify-between  sm:px-6">
          <Flex
            w="full"
            // className="table__head"
            // style={{
            //   marginTop: "1.5rem",
            // }}
            _dark={{
              bg: "gray.600",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              // className="table__head"
              current={props.curPage}
              onChange={(page) => {
                console.log({ page });
                props.setCurPage(page);
              }}
              defaultCurrent={props.curPage}
              total={props.pagesQuantity * 10}
              paginationProps={{
                display: "flex",
              }}
              colorScheme="blue"
              pageNeighbours={2}
            />
          </Flex>
        </div>
      )}
    </>
  );
}

export default Pagination1;
