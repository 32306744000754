import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonGroup,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import Select from "react-select";

import { BiSearch, BiTrash, BiDetail } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";

// import {
// } from "react-super-responsive-table";
//   import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../node_modules/react-super-responsive-table/dist/index";
import useBlacklist from "../../hooks/useQueries/useBlacklist";
import useBlacklistMutation from "../../hooks/useMutations/useBlacklistMutation";
import {
  filterBlacklistSelector,
  filterBlacklistState,
  filterCouponlistState,
  filterCouponslistSelector,
  filterPaymentlistSelector,
  filterResetAccountListSelector,
  filterResetAccountListState,
} from "../../recoil";

import { BiFilter } from "react-icons/bi";
import { AiOutlineEye, AiOutlineFilter } from "react-icons/ai";
import Pagination1 from "../../utils/pagination";
import { useAuth } from "../../utils/auth";
import { colors } from "../../utils/colors";
import { useRecoilState, useRecoilValue } from "recoil";
import getCoupons, { getCoupon } from "../../hooks/useQueries/useCoupons";
import useCouponlists from "../../hooks/useQueries/useCoupons";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import useCouponlistMutation from "../../hooks/useMutations/useCouponlistMutation";
import usePaymentslists from "../../hooks/useQueries/usePayments";
// import DetailPayment from "./DetailPayment";
// import FilterPayment from "./FilterResetAccount";
import useGetResetAccounts from "../../hooks/useQueries/useResetAccountGet";
import useGetAddCramAccount from "../../hooks/useQueries/useAddCramAccount";
import { client } from "../../utils/axios";
import { IoMdAddCircle } from "react-icons/io";

// import "./coupons.css";
// import SingleCoupon from "./SingleCoupon";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

const AddCramReset = ({ accountId, setAccountIdReset, refetch, onClose }) => {
  let toast = useToast();
  const sendRequest = async () => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_HOST}/accountResetEnable?accountId=${accountId}`,
      {
        method: "GET",
      }
    );
    if (!data.error)
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              Account Added Successfully
            </Heading>
          </Box>
        ),
      });
    else
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {data.error}
            </Heading>
          </Box>
        ),
      });
    onClose();
    refetch();
    setAccountIdReset("");
  };
  return (
    <Flex flexDir={"column"} alignItems={"center"}>
      <Text>Are You Sure You want to add this Account ?</Text>
      <ButtonGroup mt={10} gap={3}>
        <Button
          className="app_btn"
          onClick={() => {
            setAccountIdReset("");
            onClose();
          }}>
          Cancel
        </Button>
        <Button className="app_btn" onClick={sendRequest}>
          Confirm
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

const ListResetAccounts = () => {
  const [search, setSearch] = useState("");
  const [coupon, setCoupon] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [actionType, setActionType] = useState("");
  const [condition, setCondition] = useState("");
  const [order, setOrder] = useState("");
  const [email, setEmail] = useState("");
  const [accountId, setAccountId] = useState("");
  const [accountIdReset, setAccountIdReset] = useState("");
  const [rithmicId, setRithmicId] = useState("");
  const [ccType, setCCType] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [filterBy, setFilterBy] = useState("");

  const [payment, setPayment] = useState(null);

  const options = [
    { value: "sale", label: "sale" },
    { value: "refund", label: "refund" },
    { value: "auth", label: "auth" },
    { value: "capture", label: "capture" },
  ];

  const conditionOptions = [
    { value: "failed", label: "failed" },
    { value: "canceled", label: "canceled" },
    { value: "unknown", label: "unknown" },
    { value: "complete", label: "complete" },
  ];

  const orderOptions = [
    { value: "standard", label: "standard" },
    { value: "reverse", label: "reverse" },
  ];
  const ccTypeOptions = [
    { value: "Visa", label: "Visa" },
    { value: "Mastercard", label: "Mastercard" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const query = useRecoilValue(filterResetAccountListSelector);

  const [filterPaymentlist, setResetAccountList] = useRecoilState(
    filterResetAccountListState
  );

  const { resetAccounts, refetchResetAccountslists } = useGetResetAccounts(
    query + `&page=${page}&limit=${limit}`
  );

  //   const { addCramAccounts, refetchAddCramAccounts } = useGetAddCramAccount(
  //     query + `&page=${page}&limit=${limit}`
  //   );

  const auth = useAuth()?.user;
  // const roles = auth?.role;
  const roles = useSessionContext().accessTokenPayload["st-role"]?.v;

  const filterPayment = () => {
    return (
      <div>
        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <p
            style={{
              width: "25%",
            }}>
            AccountID:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            type={"text"}
            name="accountId"
            value={accountId}
            onChange={(e) => {
              setAccountId(e.target.value);
            }}
          />

          <p
            style={{
              width: "20%",
            }}>
            Rithmic ID:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            name="rithmicId"
            type={"text"}
            value={rithmicId}
            onChange={(e) => {
              setRithmicId(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <p
            style={{
              width: "5%",
            }}>
            Email:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            type={"email"}
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      setResetAccountList({
        email: "",
        rithmic_id: "",
        accountId: "",
      });
      // refetchResetAccountslists();
    };
  }, []);
  useEffect(() => {
    setResetAccountList({
      email: "",
      rithmic_id: "",
      accountId: "",
    });
  }, [filterBy]);

  let toast = useToast();
  document.title = "TTTSubscriptions | Payment List";

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Flex align="end" justify="end" pb="40px">
          <Button
            padding={"10px 30px"}
            mx="20px"
            rightIcon={<AiOutlineFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            className="app_btn"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            onClick={() => {
              setIsFilter(true);
              onOpen();
            }}>
            Filter
          </Button>

          {/*  */}

          {/*  */}
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          overflow={`scroll`}
          maxH="550px">
          {resetAccounts?.data?.length > 0 ? (
            // <Table borderRadius={20}>
            <Table borderRadius={20} variant="striped">
              <Thead
                fontSize="15px"
                h="40px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="table__head">
                <Tr
                  textAlign="center"
                  className="table__head"
                  textTransform="capitalize"
                  h="30px"
                  borderBottomWidth={1}
                  borderRadius="20px">
                  {/* <Th color="white" fontSize={11}>
                    Account Id
                  </Th> */}
                  <Th color="white" fontSize={11}>
                    Rithmic Id
                  </Th>
                  <Th color="white" fontSize={11}>
                    Rithmic Account
                  </Th>
                  <Th color="white" fontSize={11}>
                    Email
                  </Th>
                  <Th color="white" fontSize={11}>
                    Item Title
                  </Th>
                  <Th color="white" fontSize={11}>
                    Status
                  </Th>

                  <Th color="white" fontSize={11}>
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody
                // bgGradient={`linear(to-l, gray.100, gray.300)`}
                color="black">
                {resetAccounts?.data?.map((payment, i) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    <Td>{payment?.rithmic_id} </Td>
                    <Td>{payment?.rithmic_account} </Td>
                    <Td>{payment?.email} </Td>
                    <Td>{payment?.item_title}</Td>
                    <Td>
                      {payment?.status != 2
                        ? "Invoice is not active"
                        : payment.accountId != null
                        ? "Enabled"
                        : "Account Not crammed"}
                    </Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setAccountIdReset(payment?.rithmic_account);
                          onOpen();
                        }}
                        disabled={
                          !roles?.includes("MASTER", "RESET") ||
                          payment.status != 2 ||
                          payment.accountId != null
                        }
                        bgColor="transparent"
                        color="green.600"
                        align="center"
                        _hover={{ cursor: "pointer", color: "green.500" }}>
                        <IoMdAddCircle size="20px" />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}>
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>

      <Flex ml="7%" mt={5}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<BiFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            className="app_btn"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
          >
            Per Page: {limit}
          </MenuButton>
          <MenuList color={colors.ocean} fontSize="14px">
            <MenuItem onClick={() => setLimit(30)}>30</MenuItem>
            <MenuItem onClick={() => setLimit(50)}>50</MenuItem>
            <MenuItem onClick={() => setLimit(100)}>100</MenuItem>
            <MenuItem onClick={() => setLimit(200)}>200</MenuItem>
            <MenuItem onClick={() => setLimit(500)}>500</MenuItem>
            <MenuItem onClick={() => setLimit(1000)}>1000</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Flex align="center" marginTop={-10} justify="center">
        {/* <Box>
          <Button
            onClick={() => {
              let i = page;
              i--;
              if (i > 0) {
                setPage(i);
              }
            }}
            disabled={page === 1}
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            color="#ffff"
            className="app_btn"
            mx="5px"
            // _hover={{
            //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
            // }}
          >
            Previous
          </Button>
        </Box> */}
        <Pagination1
          pagesQuantity={resetAccounts?.total / limit}
          curPage={page}
          setCurPage={setPage}
        />
        {/* <Box>
          <Button
            onClick={() => {
              if (resetAccounts?.data.length === 0) {
                toast({
                  position: "top",
                  duration: 5 * 1000,
                  status: "error",
                  render: () => (
                    <Box
                      bgColor="red.400"
                      borderRadius="5px"
                      color="#f5f5f5"
                      p="20px"
                      textAlign="center">
                      <Heading fontSize="sm" textTransform="uppercase">
                        There is no more data
                      </Heading>
                    </Box>
                  ),
                });
                return;
              }
              let i = page;
              i++;
              setPage(i);
            }}
            disabled={resetAccounts?.transaction?.length < page}
            mx="5px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            className="app_btn"
            color="#ffff"
            // _hover={{
            //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
            // }}
          >
            Next
          </Button>
        </Box> */}
      </Flex>

      <Modal
        isOpen={isOpen}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: isFilter ? "6xl" : "md",
        }}
        onClose={() => {
          //  setResetAccountList({
          //    rithmic_id: "",
          //    accountId: "",
          //    email: "",
          //  });

          onClose();
          setIsFilter(false);
          //  setEmail("");
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">
            {isFilter ? "Filters" : "Details"}
          </ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody h="100vh">
            <div>
              {isFilter ? (
                filterPayment()
              ) : (
                <AddCramReset
                  refetch={refetchResetAccountslists}
                  accountId={accountIdReset}
                  onClose={onClose}
                  setAccountIdReset={setAccountIdReset}
                />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            {isFilter && (
              <Button
                colorScheme="blue"
                className="app_btn"
                mr={3}
                onClick={() => {
                  setResetAccountList({
                    rithmic_id: rithmicId,
                    accountId: accountId,
                    email: email,
                  });
                  setIsFilter(false);
                  onClose();
                }}>
                Apply
              </Button>
            )}

            {isFilter && (
              <Button
                onClick={() => {
                  setEmail("");
                  setRithmicId("");
                  setAccountId("");
                }}>
                Reset
              </Button>
            )}
            {/* {!isFilter && (
              <Button
                colorScheme="blue"
                className="app_btn"
                mr={3}
                onClick={() => {
                 
                  onClose();
                }}>
                Apply
              </Button>
            )}

            {!isFilter && (
                <Button
                onClick={() => {
                    setAccountIdReset("");
                    onClose();
                }}>
                cancel
              </Button>
            )} */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ListResetAccounts;
