import { Flex, Box, Image, Heading } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
// import logo from "../../assets/logo.png";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";
import coupons from "../../assets/images/card-payment.png";
import { BiMessageSquareAdd, BiTable, BiHome, BiStats } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router";
import payment from "../../assets/appLogos/PaymentsApp.webp";

export default function NavbarPayment() {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  return (
    <Flex
      justify={`space-between`}
      align="center"
      h="70px"
      w="100%"
      boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
      color={"#000"}
      bg="#fff"
      px="20px">
      <Flex w="20%">
        <Image
          src={payment}
          rounded={"xl"}
          h="50px"
          w="auto"
          style={{ marginTop: "6px" }}
        />
        <Image src={logo} h="60px" w="auto" onClick={() => navigate("/apps")} />
        <Image
          src={logo1}
          h="60px"
          w="auto"
          onClick={() => navigate("/apps")}
        />
      </Flex>
      <Flex justify={`space-around`} align="center" color="gray.50" w="60%">
        <Flex
          // _hover={{
          //   bgColor: "gray.50",
          //   color: colors.ocean,
          //   FlexShadow: "lg",
          //   cursor: "pointer",
          // }}
          // p="10px"
          // borderRadius={20}
          className="nav__bar__link"
          cursor="pointer"
          onClick={() => navigate("/apps")}
          bgColor={location === "/apps" && "gray.50"}
          color={location === "/apps" && colors.ocean}
          boxShadow={location === "/apps" && "lg"}>
          <Box>
            <BiHome color={"#000"} size="20px" />
          </Box>
          <Box mt="2px">
            <Heading size="xs" color={"#000"}>
              &nbsp; Apps
            </Heading>
          </Box>
        </Flex>

        {/* <Flex
          _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          p="10px"
          borderRadius={20}
          onClick={() => navigate("/apps/coupons/add-coupon")}
          cursor="pointer"
          bgColor={location === "/apps/coupons/add-coupon" && "gray.50"}
          color={location === "/apps/coupons/add-coupon" && colors.ocean}
          boxShadow={location === "/apps/coupons/add-coupon" && "lg"}> */}
        {/* <Box>
            <BiMessageSquareAdd size="20px" />
          </Box> */}
        {/* <Box mt="2px">
            <Heading size="xs">&nbsp; Add Coupon</Heading>
          </Box> */}
        {/* </Flex> */}
        <Flex
          // _hover={{ bgColor: "gray.50", color: colors.ocean, FlexShadow: "lg" }}
          // p="10px"
          // borderRadius={20}
          className={`${
            location === "/apps/payments/list"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          onClick={() => navigate("/apps/payments/list")}
          cursor="pointer"
          bgColor={location === "/apps/payments/list" && "gray.50"}
          color={location === "/apps/payments/list" && colors.ocean}
          boxShadow={location === "/apps/payments/list" && "lg"}>
          <Box>
            <BiTable
              color={location === "/apps/payments/list" ? "white" : "black"}
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              size="xs"
              color={location === "/apps/payments/list" ? "white" : "black"}>
              &nbsp; Payments
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
