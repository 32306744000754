import { useQuery, useQueryClient } from "react-query";
import { GET } from "../../utils/request";
import ms from "ms";
import axios from "axios";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const subscriptionsFetcher = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/subscriptions?${query}`,
    {
      method: "GET",
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    }
  );
  return data;
};

const useSubscriptions = (query) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  return {
    subscriptions: useQuery(
      ["subscriptions", query],
      subscriptionsFetcher(query, auth),
      {
        staleTime: ms("1s"),
        refetchInterval: ms("5m"),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchSubscriptions: () => queryClient.refetchQueries(["subscriptions"]),
  };
};

const subscriptionsCountFetcher = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/subscriptions/count`,
    {
      method: "GET",
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    }
  );
  // console.log(data);
  return data;
};

export const useSubscriptionCount = (query) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  return {
    subscriptionsCount: useQuery(
      ["subscriptionsCount", query],
      subscriptionsCountFetcher(query, auth),
      {
        staleTime: ms("5m"),
        refetchInterval: ms("5m"),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchSubscriptions: () =>
      queryClient.refetchQueries(["subscriptionsCount"]),
  };
};

export default useSubscriptions;
