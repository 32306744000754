import { useQuery, useQueryClient } from "react-query";
import { GET } from "../../utils/request";
import ms from "ms";
import axios from "axios";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

export const bookmapAllLicensesFetcher = (auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/bookmapLicenses/all`,
    {
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    }
  );
  return data;
};
export const useBookmapAllLicenses = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  return {
    bookmapAllLicenses: useQuery(
      ["bookmapAllLicenses"],
      bookmapAllLicensesFetcher(auth),
      {
        staleTime: ms("1s"),
        refetchInterval: ms("5m"),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchBookmapAllLicenses: () =>
      queryClient.refetchQueries(["bookmapAllLicenses"]),
  };
};

export default useBookmapAllLicenses;
