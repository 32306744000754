export const convertedArrayToOptions = (keys, isNoneValueExists = false) => {
  let result = [];

  keys.map((key) => {
    result.push({
      value: key.code,
      label: key.code,
    });
  });

  if (isNoneValueExists)
    result.unshift({
      value: "none",
      label: "None",
    });

  return result;
};

export const getInvoiceStatus = (target) => {
  const obj = {
    1: "PAID",
    2: "ACTIVE",
    3: "CANCELLED",
    4: "FAILED",
    5: "FINISHED",
  };
  return obj[target];
};

export const getPayedStatus = (target) => {
  const obj = {
    0: "UNPAID",
    1: "PAID",
  };
  return obj[target];
};
export const getSignInStatus = (target) => {
  const obj = {
    0: "UNSIGNED",
    1: "SIGNED",
  };
  return obj[target];
};

export const substringContent = (text, end) =>
  text.length > end ? text.substring(0, end) + "..." : text;

export const getResolvedStatus = (target) => {
  const obj = {
    true: "Resolved",
    false: "UnResolved",
  };
  return obj[target];
};
