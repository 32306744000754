import { useQuery, useQueryClient } from "react-query";
import { GET } from "../../utils/request";
import ms from "ms";
import axios from "axios";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const licensesFetcher = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/licenses?${query}`,
    {
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    }
  );
  return data;
};

const useLicenses = (query) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  // console.log("query = ", query);
  return {
    licenses: useQuery(["licenses", query], licensesFetcher(query, auth), {
      staleTime: ms("1s"),
      refetchInterval: ms("5m"),
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      retry: false,
    }).data,
    refetchLicenses: () => queryClient.refetchQueries(["licenses"]),
  };
};

export default useLicenses;
