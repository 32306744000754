import { useState, useRef, useEffect } from "react";
import {
  Button,
  useToast,
  Heading,
  Text,
  Spinner,
  Badge,
} from "@chakra-ui/react";
import { client } from "../../utils/axios";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import {
  Box,
  Flex,
  Input,
  chakra,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
} from "@chakra-ui/react";
import React from "react";

import { BiSearch } from "react-icons/bi";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
//   import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../node_modules/react-super-responsive-table/dist/index";
import usePostActiveAccounts from "../../hooks/useMutations/usePostActiveAccounts";
import { useRecoilState, useRecoilValue } from "recoil";
import { colors } from "../../utils/colors";
import { filterTRCheckerSelector, filterTRCheckerState } from "../../recoil";

import "./style.css";
import useGetTRChecker from "../../hooks/useQueries/useGetChecker";
// import SingleCoupon from "./SingleCoupon";

const headers = [
  { label: "Account name", key: "name" },
  { label: "first Name", key: "user.name_f" },
  { label: "Last Name", key: "user.name_l" },
  { label: "Email", key: "user.email" },
  { label: "Phone", key: "user.phone" },
  { label: "UserId", key: "user.user_id" },
  { label: "Tradovate ID", key: "user.tradovate_id" },
];

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

const TRchecker = () => {
  const [email, setEmail] = useState("");
  const [accountName, setAccountName] = useState("");

  const query = useRecoilValue(filterTRCheckerSelector);

  const [filterTRChecker, setFilterTRChecker] =
    useRecoilState(filterTRCheckerState);

  const { check, refetchCheck, error } = useGetTRChecker(query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  //   const query = useRecoilValue(filterCouponslistSelector);

  //   const { mutate, isLoading, data, error } = usePostActiveAccounts();

  let toast = useToast();
  document.title = "TTTSubscriptions | Target Reach Checker";
  //   console.log("data = ", check);

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Flex align="center" justify="space-evenly" pb="40px">
          <Flex w={"30%"} flexDir={"column"}>
            <Box>
              <Heading color={"black"} size="md">
                Target Reached Checker
              </Heading>
            </Box>
          </Flex>

          <Flex align="center" className="coupon" justify={`center`} w="70%">
            {/* <Box w="90%">
              <Input
                type="text"
                placeholder={`Search by email...`}
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  setSearchBy("email");
                  setSearch(e.target.value);
                }}
              />
            </Box> */}
            <Box w="90%">
              <Input
                type="text"
                placeholder={`Enter  Account Name...`}
                borderColor={colors.blue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  //   setSearchBy("coupon");
                  setAccountName(e.target.value);
                  if (e.target.value === "")
                    setFilterTRChecker({
                      accountName: "",
                    });
                }}
              />
            </Box>
          </Flex>
          <Box w="50%" ml={2}>
            <Button
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              className="app_btn"
              color="#ffff"
              width={"30%"}
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              isDisabled={!!accountName ? false : true}
              onClick={() => {
                setFilterTRChecker({
                  accountName: accountName,
                });
              }}>
              <BiSearch size="20px" />
            </Button>
          </Box>

          {/* <Button
            padding={"10px 30px"}
            rightIcon={<AiOutlineEye size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn"
            onClick={() => {
            
            }}>
            View all
          </Button> */}
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px">
          {check && check.success ? (
            <>
              <Badge
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                p={8}
                colorScheme={"whatsapp"}>
                {check?.data[0]?.text}
              </Badge>
            </>
          ) : error && filterTRChecker.accountName !== "" ? (
            <>
              {" "}
              {error && (
                <Badge
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  p={8}
                  colorScheme={"red"}>
                  {error?.response?.data?.message}
                </Badge>
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      {/* <Flex ml="7%" mt={5}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<BiFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn">
            Per Page: {limit}
          </MenuButton>
          <MenuList color={colors.ocean} fontSize="14px">
            <MenuItem onClick={() => setLimit(10)}>10</MenuItem>
            <MenuItem onClick={() => setLimit(15)}>15</MenuItem>
            <MenuItem onClick={() => setLimit(20)}>20</MenuItem>
            <MenuItem onClick={() => setLimit(25)}>25</MenuItem>
            <MenuItem onClick={() => setLimit(30)}>30</MenuItem>
            <MenuItem onClick={() => setLimit(35)}>35</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex align="center" marginTop={-10} justify="center">
        <Pagination1
          pagesQuantity={Math.ceil(email?.total / limit)}
          curPage={page}
          setCurPage={setPage}
        />
      </Flex> */}

      <Modal
        isOpen={isOpen}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: "6xl",
        }}
        onClose={() => {
          onClose();
          setEmail("");
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">Coupon Detail {email}</ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody>{/* <SingleCoupon coupon={coupon} /> */}</ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TRchecker;
