import React, { useEffect, useState } from "react";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
// import { signInAndUp } from "supertokens-web-js/recipe/thirdparty";
import { signInAndUp } from "supertokens-web-js/recipe/thirdparty";
import { useToast, Box, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useAuth } from "../utils/auth";
export default function Home() {
  const toast = useToast();
  const navigate = useNavigate();
  const auth = useAuth();
  async function handleGoogleCallback() {
    try {
      const response = await signInAndUp();
    //  console.log("response = ", response);
      if (response.status === "OK") {
        console.log("user = ", response.user);
        // if (response.createdNewUser) {
        //   // sign up successful
        // } else {
        //   // sign in successful
        // }
        await auth.verify();
        navigate("/apps");
        // window.location.assign("/apps");
      } else {
        // SuperTokens requires that the third party provider
        // gives an email for the user. If that's not the case, sign up / in
        // will fail.

        // As a hack to solve this, you can override the backend functions to create a fake email for the user.

        window.alert(
          "No email provided by social login. Please use another form of login"
        );
        // window.location.assign("/"); // redirect back to login page
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        toast({
          position: "top",
          duration: 5 * 1000,
          status: "error",
          render: () => (
            <Box
              bgColor="red.500"
              borderRadius="5px"
              color="#f5f5f5"
              p="10px"
              px="10px"
              textAlign="center">
              <Heading fontSize="sm">{err?.message}</Heading>
            </Box>
          ),
        });
        navigate("/");
        // this may be a custom error message sent from the API by you.
        // window.location.assign("/");
        // window.alert(err.message);
      } else {
        toast({
          position: "top",
          duration: 5 * 1000,
          status: "error",
          render: () => (
            <Box
              bgColor="red.500"
              borderRadius="5px"
              color="#f5f5f5"
              p="10px"
              px="10px"
              textAlign="center">
              <Heading fontSize="sm">Oops! Something went wrong.</Heading>
            </Box>
          ),
        });
        navigate("/");
        // window.location.assign("/");
        // window.alert("Oops! Something went wrong.");
      }
    }
  }
  const [user, setUser] = useState();
  let sessionContext = Session.useSessionContext();
  // console.log("test ", sessionContext);
  const buttonStyle = {
    backgroundColor: "#ff0000",
    color: "#ffffff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  };
  // const [user, setUser] = useState();
  useEffect(() => {
    const timer = setTimeout(() => {
      handleGoogleCallback();
    }, 500); // adjust the delay as needed

    return () => {
      clearTimeout(timer);
    };
  }, []);
  // useEffect(() => {
  //   // getUser();
  //   handleGoogleCallback();
  // }, []);
  async function onLogout() {
    const a = await signOut();
    // console.log("a", a);
    window.location.href = "/";
  }
  // if (sessionContext.loading === true) return null;

  return (
    <></>
    // <div
    //   style={{
    //     width: "100%",
    //     height: "400px",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "space-evenly",
    //     alignItems: "center",
    //   }}>
    //   {" "}
    //   <div>
    //     {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
    //     <p>fullName : {user?.fullName}</p>
    //     <p>email : {user?.email}</p>
    //     <p>userId : {user?.userId}</p>
    //   </div>
    //   <button style={buttonStyle} onClick={onLogout}>
    //     Logout
    //   </button>
    // </div>
  );
}
