import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Input,
  Button,
  FormLabel,
  useDisclosure,
  VStack,
  Select,
  Box,
  Heading,
  useToast,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import { colors } from "../../utils/colors";
// import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
// import "./datepicker.css";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import useSubscriptions from "../../hooks/useQueries/useSubscriptions";
import { BiEdit } from "react-icons/bi";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import useJournalytixList from "../../hooks/useQueries/useJournalytix";

const schema = yup.object().shape({
  /* fName: yup.string().required("First Name Required"),
    lName: yup.string().required("Last Name Required"), */
  //   email: yup.string().email().optional(),
  endDate: yup.number().optional(),
});

export default function EditJournalitix({ journalitix }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [date, setDate] = useState(new Date());
  const toast = useToast();
  const { refetchJounalytixList } = useJournalytixList();
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      const currentDate = new Date(journalitix?.endDate) < new Date() ? new Date() : new Date(journalitix?.endDate);
      console.log("data = ====", data);
      // Get the input number of d ays (nbDays)
      // const nbDays = 10; // Replace this with your input value

      // Calculate the target date by adding nbDays to the current date
      const targetDate = new Date(currentDate);
      targetDate.setMonth(currentDate.getMonth() + data.endDate);

      // Format the targetDate as "YYYY-MM-DD"
      data.endDate = targetDate.toISOString().split("T")[0];

      const editSub = {
        endDate: data.endDate,
        Email: journalitix.Email,
      };

      await client.put(
        `${process.env.REACT_APP_API_HOST}/journalitix/${journalitix?._id}`,
        editSub,
        {
          method: "PUT",
          // headers: {
          // "auth-token": auth.user.accessToken,
          // },
        }
      );

      await refetchJounalytixList();

      toast({
        position: "bottom-right",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="yellow.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="10px"
            textAlign="center">
            <Heading fontSize="xs" textTransform="uppercase">
              Subscription edited succesfully !
            </Heading>
          </Box>
        ),
      });
      clearErrors();
      onClose();
    } catch (error) {
      console.dir(error);
	  toast({
        position: "top-center",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="red.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="10px"
            textAlign="center">
            <Heading fontSize="xs" textTransform="uppercase">
              {error?.response?.data?.message || "Something went wrong Updaing Journalitix entry!"}
            </Heading>
          </Box>
        ),
      });
    }
  };
  console.log("journa ", journalitix);

  return (
    <>
      <Tooltip label="Edit" placement="auto">
        <Box
          color={colors.ocean}
          align="center"
          _hover={{ cursor: "pointer", color: colors.lightBlue }}>
          <BiEdit size="20px" onClick={onOpen} />
        </Box>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent borderRadius={15} _focus={{}}>
          <ModalHeader>Edit Journalitix</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack align="stretch" spacing={5}>
                {/* <FormControl w="100%">
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    {...register("email", { required: true })}
                    type="text"
                    placeholder="Enter Enail..."
                    defaultValue={journalitix.Email}
                    borderColor={colors.lightBlue}
                    color={colors.ocean}
                    _focus={{ borderColor: colors.lightBlue }}
                  />
                </FormControl> */}

                <FormControl>
                  <FormLabel>Time of Subscription</FormLabel>
                  <Input
                    name="endDate"
                    {...register("endDate", { required: true })}
                    type="number"
                    placeholder="Enter Ticket Number"
                    borderColor={colors.lightBlue}
                    color={colors.lightBlue}
                    _focus={{ borderColor: colors.lightBlue }}
                    _placeholder={{
                      opacity: 0.7,
                      color: "#ffff",
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                  />

                  <Badge
                    fontWeight={600}
                    colorScheme="red"
                    fontSize="12px"
                    align="left"
                    mt="10px">
                    {errors?.endDate?.message}
                  </Badge>
                </FormControl>

                <Button
                  w="100%"
                  borderRadius={15}
                  fontSize="12px"
                  className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  type="submit">
                  <BiEdit size="20px" /> &nbsp; Edit
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
