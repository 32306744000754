import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery, useQueryClient } from "react-query";

const getResetAccounts = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/accountsResetGet?${query}`,
    {
      method: "GET",
    }
  );
  return data;
};

const useGetResetAccounts = (query) => {
   const queryClient = useQueryClient();
  const auth = useAuth();

  return {
    resetAccounts: useQuery(
      ["resetAcounts", query],
      getResetAccounts(query, auth),
      {
        staleTime: ms("1s"),
        refetchInterval: ms("5m"),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchResetAccountslists: queryClient.refetchQueries(["resetAcounts", query]),
  };
};

export default useGetResetAccounts;
