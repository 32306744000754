import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import { useAuth } from "core/Utils";
import SuperTokens from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
export const client = axios.create({
  //   baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
});

client.interceptors.request.use(async (config) => {
  if (config) {
    const cookieString = document.cookie;
    const session = await Session.getAccessToken();
    //console.log("session = ", session);
    // const token = localStorage.getItem("accessToken");
    if (cookieString && config.headers) {
      config.headers["st-auth-mode"] = "header";
      config.headers["Authorization"] = "Bearer " + session;
    }

    return config;
  }
  return config;
});
