import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  breakpoints: {
    sm: "100px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    "2xl": "90em", // Define your custom 2xl breakpoint
    "3xl": "120em", // Define your custom 2xl breakpoint
  },
  fonts: {
    body: "'Montserrat', sans-serif",
    heading: "'Montserrat', sans-serif",
  },
});
