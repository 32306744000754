import {
  Box,
  Image,
  Tooltip,
  Text,
  Heading,
  Flex,
  Button,
} from "@chakra-ui/react";
// import { InfoOutlineIcon } from "@chakra-ui/icons";
import { FcInfo } from "react-icons/fc";

export function CustomBox({ img, appName, description, Link }) {
  const redirection = () => {
    window.open(Link, "_blank");
  };
  return (
    <Box
      _hover={{
        boxShadow: "0px 0px 8px rgba(66, 153, 225, 0.6)",
        padding: "24px",
        borderRadius: "5%", // Same as the card's border-radius
        transform: "translateY(-4px)",
        transition: "all 0.3s ease-in-out",
      }}
      color="gray.50"
      sx={{
        img: { filter: "brightness(90%)" },
        "img:hover": { filter: "brightness(100%)" },
      }}>
      <Flex w={"100"} justifyContent={"center"} alignItems={"center"}>
        <Box w={"95%"}>
          <Image
            src={img}
            rounded={"full"}
            /* opacity="0.8" _hover={{opacity:1}} */ h="200px"
          />
        </Box>
        <Flex w={"2%"} h={"100%"} py={2} alignItems={"center"}>
          <Tooltip label={description} placement="top">
            <Box
              bg="transparent"
              color="blue.500"
              px="2"
              py="1"
              borderRadius="md">
              <FcInfo size={25} />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>

      <Heading w={"90%"} size="md" mt={5} color={"#000"} textAlign={`center`}>
        {appName}
      </Heading>
      <Flex justifyContent="center" mt={2} mb={2}>
        <Button
          style={{
            backgroundImage:
              "radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%)",
          }}
          color="white"
          w="full"
          onClick={redirection}
          variant="solid">
          Read The Doc
        </Button>
      </Flex>
    </Box>
  );
}
