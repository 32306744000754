import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery, useQueryClient } from "react-query";

const getCoupons = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/admin/coupons?${query}`,
    {
      method: "GET",
    }
  );

  return data;
};

const getCoupon = (query) => async () => {
  const { data } = await client.put(
    `${process.env.REACT_APP_API_HOST}/admin/single/coupon?coupon=${query}`,
    {
      method: "PUT",
    }
  );

  return data.data;
};

const getListCoupons = (query) => async () => {
  const { data } = await client.patch(
    `${process.env.REACT_APP_API_HOST}/admin/list/coupon`,
    {
      method: "PATCH",
    }
  );
  return data.data;
};

export const useListCoupons = () => {
  // const queryClient = useQueryClient();
  // const auth = useAuth();

  return {
    listCoupons: useQuery([], getListCoupons(), {
      staleTime: ms("1s"),
      refetchInterval: ms("5m"),
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      retry: false,
    }).data,
    refetchListCoupons: () => getListCoupons(),
  };
};

const useCouponlists = (query) => {
  // const queryClient = useQueryClient();
  const auth = useAuth();

  return {
    coupons: useQuery(["coupons", query], getCoupons(query, auth), {
      staleTime: ms("1s"),
      refetchInterval: ms("5m"),
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      retry: false,
    }).data,
    refetchCouponlists: () => getCoupons(query, auth),
  };
};

export const useCouponUsers = (query) => {
  return {
    couponUsers: useQuery(["coupon", query], getCoupon(query), {
      staleTime: ms("1s"),
      refetchInterval: ms("5m"),
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      retry: false,
    }).data,
    refetchCouponUsers: () => getCoupon(query),
  };
};

export default useCouponlists;
