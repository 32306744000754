import axios from "axios";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Session from "supertokens-auth-react/recipe/session";
import { client } from "./axios";

let AuthContext = React.createContext({
  user: null,
  signin: () => Promise.resolve(false),
  verify: () => Promise.resolve(false),
  signout: () => Promise.resolve(false),
});
//export const Roles = (user)=>{
//	let roles = ["LC", "BM", "SUB", "BL", "MASTER"];
//};
export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  let [user, setUser] = React.useState(null);
  let auth = useAuth();
  let signin = async (newUser) => {
    try {
      let response1 = await axios.post(
        `${process.env.REACT_APP_API_HOST}/admin/login`,
        {
          ...newUser,
        }
      );
      if (response1.status !== 200) {
        setUser(null);
        return false;
      }
      let response = await axios(
        `${process.env.REACT_APP_API_HOST}/admin/whoami`,
        {
          // method: "GET",
          // headers: {
          //   "auth-token": response1.data.accessToken,
          // },
        }
      );
      //  response.data.role = ["LC", "BM", "SUB", "BL", "MASTER"];
      //   response.data.roles = ["BM"];//
      localStorage.setItem("accessToken", response1.data.accessToken);
      setUser({ ...response.data, accessToken: response1.data.accessToken });
      return true;
    } catch (error) {
      console.log(error);
      setUser(null);
      return false;
    }
  };
  let verify = async () => {
    //? check with backend if user is still verified if no deauthenticate and retuen false
    try {
      // const id = await Session.getUserId();
      // let response = await axios.get(
      //   `${process.env.REACT_APP_AUTH_HOST}/user/${id}`
      // );
      let response = await client.get(
        `${process.env.REACT_APP_API_HOST}/whoami`
      );
    //  console.log("response = ", response);
      setUser({
        ...response.data,
      });
      return true;
    } catch (error) {
      setUser(null);
      return false;
    }
  };

  let signout = async () => {
    try {
      await Session.signOut();
      setUser(null);
      window.location.href = "/";
    } catch (error) {
      localStorage.setItem("accessToken", null);
      setUser(null);
    }
  };
  useEffect(() => {
    verify();
  }, []);
  let value = { user, signin, verify, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
/**
 * @param {object} children
 * @breif will render the children if the user is authenticated
 * 			will redirect to the login page if the user is not authenticated
 */

async function doesSessionExist() {
  if (await Session.doesSessionExist()) {
    return true;
  } else {
    return false;
  }
}

// export const RequireAuth = ({ children }) => {
//   let auth = doesSessionExist();
//   const location = useLocation();

//   if (!auth) {
//     return <Navigate to="/" state={{ from: location }} />;
//   }
//   return children;
// };

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  const location = useLocation();

  console.log("auth =", auth);
  if (!auth || !auth.user) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

/**
 * @param {object} children
 * @breif will render the children login page if user is unauthenticated
 * 			else will redirect to previous page
 */
export const RequireNoAuth = ({ children }) => {
  let auth = useAuth();
  const location = useLocation();

  if (auth && auth.user) {
    return <Navigate to={location.state?.from?.pathname || "/apps"} />;
  }
  return children;
};
