import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const postData = async (email) => {
  const response = await client.post(
    // `${process.env.REACT_APP_AMEMBERS2F}/custom-api/tradogetfund`,
    `${process.env.REACT_APP_API_HOST}/funded/getFunded`,
    {
      email: email,
    }
  );
  return response.data;
};

export default function usePostActiveAccounts() {
  return useMutation(postData, {
    onSuccess: (data) => {
      // Handle success (e.g., refetch other queries if needed)
      console.log("Success:", data);
    },
    onError: (error) => {
      // Handle error
      console.error("Error:", error);
    },
  });
}
