import React, { useState } from "react";
import Select from "react-select";

import {
  Flex,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  useDisclosure,
  useToast,
  HStack,
  CheckboxGroup,
  Checkbox,
  VStack,
  Box,
  Text,
  //  Select,
  Badge,
} from "@chakra-ui/react";
import { colors } from "../../../utils/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BiFilter, BiUserPlus } from "react-icons/bi";
import { useAuth } from "../../../utils/auth";
import axios from "axios";
import { client } from "../../../utils/axios";

const schema = yup.object().shape({
  /* fName: yup.string().required("First Name Required"),
    lName: yup.string().required("Last Name Required"), */
  email: yup.string().required("!! Email is a required field."),
  //!  roles: yup.string().required(" !! Roles is a required field."),
  password: yup
    .string()
    .required("!! Password is a required field.")
    .test(
      "len",
      "Password must be more than 6 characters",
      (val) => val.length > 6
    ),
});
export const rolesOptions = [
  { value: "MASTER", label: "MASTER" },
  { value: "BL", label: "TTTBlacklist" },
  { value: "BM", label: "TTTBookmap" },
  { value: "SUB", label: "TTTSubs" },
  {
    label: "JOURNALITIX",
    value: "JOURNALITIX",
  },
  {
    label: "PAYOUT",
    value: "PAYOUT",
  },
  {
    label: "PAYOUTMASTER",
    value: "PAYOUTMASTER",
  },

  { value: "CQG_LEVEL3", label: "CQG_LEVEL3" },
  { value: "CQG_LEVEL1", label: "CQG_LEVEL1" },
  { value: "CQG_LEVEL2", label: "CQG_LEVEL2" },
  { value: "CQG_LEVEL4", label: "CQG_LEVEL4" },

  { value: "AFFILIATE", label: "AFFILIATE" },
  { value: "PROSPECT", label: "PROSPECT" },
  { value: "COUPON", label: "COUPON" },

  { value: "FUNDEDMASTER", label: "FUNDEDMASTER" },
  { value: "FUNDED", label: "FUNDED" },

  { value: "RESET", label: "RESET" },

  /// tradovate roles
  { value: "TRADOVATE_LEVEL1", label: "TRADOVATE_LEVEL1" },
  { value: "TRADOVATE_LEVEL2", label: "TRADOVATE_LEVEL2" },
  { value: "TRADOVATE_LEVEL3", label: "TRADOVATE_LEVEL3" },
  { value: "TRADOVATE_LEVEL4", label: "TRADOVATE_LEVEL4" },
  /* { value: "LC", label: "Licence" }, */
];
export default function NewAdmin() {
  const [roles, setRoles] = useState([]);
  const [master, setMaster] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [selectedRoles, setselectedRoles] = useState([]);
  const [touchedRoles, settouchedRoles] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const auth = useAuth();
  const [erroraddAdmin, seterroraddAdmin] = useState(false);
  const hundleselectchange = (selectedOptions) => {
    setselectedRoles(selectedOptions);
  };

  const onSubmit = async (data) => {
    if (selectedRoles.length === 0) {
      settouchedRoles(true);
      return;
    }
    settouchedRoles(false);
    try {
      let res = await client.post(
        `${process.env.REACT_APP_API_HOST}/admin/signup`,
        {
          ...data,
          username: data.email.split("@")[0],
          roles: selectedRoles.map((v) => v.value),
        },
        {
          // headers: {
          //   "auth-token": auth.user.accessToken,
          // },
        }
      );
      toast({
        position: "top-left",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Text fontSize={13}>New Admin added succefully !</Text>
          </Box>
        ),
      });
      onClose();
      seterroraddAdmin(false);
    } catch (error) {
      seterroraddAdmin(true);
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<BiUserPlus size="20px" />}
        borderRadius={50}
        fontSize="12px"
        bgGradient={`linear(to-b, #46ced8, #008ab4)`}
        boxShadow="lg"
        color="#ffff"
        _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}>
        New Admin
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>Add new Admin</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  {...register("email", { required: true })}
                  type="text"
                  placeholder="Enter Email here..."
                  borderColor={colors.lightBlue}
                  color={colors.lightBlue}
                  _focus={{ borderColor: colors.lightBlue }}
                  _placeholder={{
                    opacity: 0.7,
                    color: colors.darkblue,
                    fontSize: 14,
                    fontStyle: "italic",
                  }}
                />
                <Badge
                  fontWeight={600}
                  colorScheme="red"
                  fontSize="12px"
                  align="left"
                  mt="10px">
                  {errors.email?.message}
                </Badge>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  name="password"
                  {...register("password", { required: true })}
                  type="text"
                  placeholder="Enter password here..."
                  borderColor={colors.lightBlue}
                  color={colors.lightBlue}
                  _focus={{ borderColor: colors.lightBlue }}
                  _placeholder={{
                    opacity: 0.7,
                    color: colors.darkblue,
                    fontSize: 14,
                    fontStyle: "italic",
                  }}
                />
                <Badge
                  fontWeight={600}
                  colorScheme="red"
                  fontSize="12px"
                  align="left"
                  mt="10px">
                  {errors.password?.message}
                </Badge>
              </FormControl>

              {/*<FormControl mt={4}>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  type="text"
                  placeholder="Confrim password..."
                  borderColor={colors.lightBlue}
                  color={colors.lightBlue}
                  _focus={{ borderColor: colors.lightBlue }}
                  _placeholder={{
                    opacity: 0.7,
                    color: colors.darkblue,
                    fontSize: 14,
                    fontStyle: "italic",
                  }}
                />
              </FormControl>*/}

              <FormControl mt={4}>
                <FormLabel>Roles</FormLabel>
                {/*<Select  name="roles"
            {...register("roles", { required: true })} placeholder="Pick role..."
            borderColor={colors.lightBlue}
            color={colors.darkblue}
            _focus={{ borderColor: colors.lightBlue }}
            _placeholder={{
              opacity: 0.7,
              color: colors.darkblue,
              fontSize: 14,
              fontStyle: "italic"
            }}
            >
                    <option value="MASTER">
                            &nbsp; -- MASTER
                    </option>
                    <option value="BL">
                            &nbsp; -- TTTBlacklist
                    </option>
                    <option value="BM">
                            &nbsp; -- TTTBookmap
                    </option>
                    <option value="SUB">
                            &nbsp; -- TTTSubs
                    </option>
                </Select>*/}
                <Badge
                  fontWeight={600}
                  colorScheme="red"
                  fontSize="12px"
                  align="left"
                  mt="10px">
                  {selectedRoles.length === 0 &&
                    touchedRoles &&
                    "!! Roles is a required field."}
                </Badge>
              </FormControl>
              <Select
                isMulti={true}
                value={selectedRoles}
                name="roles"
                onChange={hundleselectchange}
                options={rolesOptions}
              />
              {erroraddAdmin && (
                <Badge
                  fontWeight={600}
                  colorScheme="red"
                  fontSize="12px"
                  align="left"
                  mt="10px">
                  can't add user maybe email already exist
                </Badge>
              )}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Add new admin
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
