import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import Select from "react-select";

import { BiSearch, BiTrash, BiDetail } from "react-icons/bi";
// import {
// } from "react-super-responsive-table";
//   import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../node_modules/react-super-responsive-table/dist/index";
import useBlacklist from "../../hooks/useQueries/useBlacklist";
import useBlacklistMutation from "../../hooks/useMutations/useBlacklistMutation";
import {
  filterBlacklistSelector,
  filterBlacklistState,
  filterCouponlistState,
  filterCouponslistSelector,
  filterPaymentlistSelector,
  filterPaymentlistState,
} from "../../recoil";

import { BiFilter } from "react-icons/bi";
import { AiOutlineEye, AiOutlineFilter } from "react-icons/ai";
import Pagination1 from "../../utils/pagination";
import { useAuth } from "../../utils/auth";
import { colors } from "../../utils/colors";
import { useRecoilState, useRecoilValue } from "recoil";
import getCoupons, { getCoupon } from "../../hooks/useQueries/useCoupons";
import useCouponlists from "../../hooks/useQueries/useCoupons";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import useCouponlistMutation from "../../hooks/useMutations/useCouponlistMutation";
import usePaymentslists from "../../hooks/useQueries/usePayments";
import DetailPayment from "./DetailPayment";
import FilterPayment from "./FilterPayment";
// import "./coupons.css";
// import SingleCoupon from "./SingleCoupon";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

const ListPayments = () => {
  const [search, setSearch] = useState("");
  const [coupon, setCoupon] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [actionType, setActionType] = useState("");
  const [condition, setCondition] = useState("");
  const [order, setOrder] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [ccType, setCCType] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [filterBy, setFilterBy] = useState("WorldPay");

  const [payment, setPayment] = useState(null);

  const options = [
    { value: "sale", label: "sale" },
    { value: "refund", label: "refund" },
    { value: "auth", label: "auth" },
    { value: "capture", label: "capture" },
  ];

  const conditionOptions = [
    { value: "failed", label: "failed" },
    { value: "canceled", label: "canceled" },
    { value: "unknown", label: "unknown" },
    { value: "complete", label: "complete" },
  ];

  const orderOptions = [
    { value: "standard", label: "standard" },
    { value: "reverse", label: "reverse" },
  ];
  const ccTypeOptions = [
    { value: "Visa", label: "Visa" },
    { value: "Mastercard", label: "Mastercard" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const query = useRecoilValue(filterPaymentlistSelector);

  const [filterPaymentlist, setFilterPaymentlist] = useRecoilState(
    filterPaymentlistState
  );

  const { payments, refetchPaymentslists } = usePaymentslists(
    query + `&page_number=${page}&result_limit=${limit}`
  );
  const auth = useAuth()?.user;
  // const roles = auth?.role;
  const roles = useSessionContext().accessTokenPayload["st-role"]?.v;

  const filterPayment = () => {
    return (
      <div>
        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <p>Order:</p>
            <Select
              options={orderOptions}
              defaultValue={order}
              onChange={(e) => {
                const val = e.valueOf();
                setOrder(val["value"]);
              }}
            />
          </div>
          <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
            <p>CC Type:</p>
            <Select
              isMulti
              options={ccTypeOptions}
              defaultValue={ccType}
              onChange={(e) => {
                const val = e.valueOf();
                const arr = val.map((x) => x["value"]);
                setCCType(arr.join(","));
              }}
            />
          </div>
        </div>
        {/* <div
          style={{
            margin: "1.5rem 0",
          }}
        >
          <p>Order:</p>
          <Select
            options={orderOptions}
            defaultValue={order}
            onChange={(e) => {
              const val = e.valueOf();
              setOrder(val["value"]);
            }}
          />
        </div> */}

        {/*  */}
        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <p>Action type:</p>
            <Select
              isMulti
              options={options}
              onChange={(e) => {
                const val = e.valueOf();
                const arr = val.map((x) => x["value"]);
                setActionType(arr.join(","));
              }}
            />
          </div>
          <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
            <p>Condition:</p>
            <Select
              isMulti
              options={conditionOptions}
              defaultValue={condition}
              onChange={(e) => {
                const val = e.valueOf();
                const arr = val.map((x) => x["value"]);
                setCondition(arr.join(","));
              }}
            />
          </div>
        </div>

        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              width: "20%",
            }}
          >
            Start date:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            type={"date"}
            onChange={(e) => {
              setStart(e.target.value.split("-").join(""));
            }}
          />
          <p
            style={{
              width: "20%",
            }}
          >
            End date:
          </p>
          <Input
            type={"date"}
            onChange={(e) => {
              setEnd(e.target.value.split("-").join(""));
            }}
          />
        </div>
        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              width: "20%",
            }}
          >
            First name:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            type={"text"}
            name="first_name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />

          <p
            style={{
              width: "20%",
            }}
          >
            Last name:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            name="last_name"
            type={"text"}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              width: "5%",
            }}
          >
            Email:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            type={"email"}
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      setFilterPaymentlist({
        start_date: "",
        condition: "",
        action_type: "",
        result_order: "",
        end_date: "",
        first_name: "",
        last_name: "",
        email: "",
        cc_type: "",
        security_key: filterBy,
      });
      refetchPaymentslists();
    };
  }, []);
  useEffect(() => {
    setFilterPaymentlist({
      start_date: "",
      condition: "",
      action_type: "",
      result_order: "",
      end_date: "",
      first_name: "",
      last_name: "",
      email: "",
      cc_type: "",
      security_key: filterBy,
    });
  }, [filterBy]);

  let toast = useToast();
  document.title = "TTTSubscriptions | Payment List";

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto"
      >
        <Flex align="end" justify="end" pb="40px">
          <Button
            padding={"10px 30px"}
            mx="20px"
            rightIcon={<AiOutlineFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            className="app_btn"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            onClick={() => {
              setIsFilter(true);
              onOpen();
            }}
          >
            Filter
          </Button>

          {/*  */}
          <Flex>
            <Flex align="center" justify={`start`} w="100%">
              <Box w="20%">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<BiFilter size="20px" />}
                    borderRadius={50}
                    fontSize="12px"
                    // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                    boxShadow="lg"
                    color="#ffff"
                    // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                    className="app_btn"
                  >
                    {filterBy}
                  </MenuButton>
                  <MenuList color={colors.ocean} fontSize="14px">
                    <MenuItem
                      onClick={() => {
                        setFilterBy("WorldPay");
                        setFilterPaymentlist({
                          security_key: filterBy,
                        });
                      }}
                    >
                      WorldPay
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setFilterBy("EmerchantPay");
                        setFilterPaymentlist({
                          security_key: filterBy,
                        });
                      }}
                    >
                      EmerchantPay
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
          </Flex>
          {/*  */}
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          overflow={`scroll`}
          maxH="550px"
        >
          {payments?.transaction?.length > 0 ? (
            // <Table borderRadius={20}>
            <Table borderRadius={20} variant="striped">
              <Thead
                fontSize="15px"
                h="40px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="table__head"
              >
                <Tr
                  textAlign="center"
                  className="table__head"
                  textTransform="capitalize"
                  h="30px"
                  borderBottomWidth={1}
                  borderRadius="20px"
                >
                  <Th color="white" fontSize={11}>
                    Transaction Id
                  </Th>
                  <Th color="white" fontSize={11}>
                    Email
                  </Th>
                  <Th color="white" fontSize={11}>
                    Amount
                  </Th>
                  <Th color="white" fontSize={11}>
                    Acttion Type
                  </Th>
                  <Th color="white" fontSize={11}>
                    response text
                  </Th>
                  <Th color="white" fontSize={11}>
                    Platform Id
                  </Th>
                  <Th color="white" fontSize={11}>
                    Condition
                  </Th>
                  <Th color="white" fontSize={11}>
                    Cardholder auth
                  </Th>
                  <Th color="white" fontSize={11}>
                    CC type
                  </Th>
                  <Th color="white" fontSize={11}>
                    Initiated by
                  </Th>
                  <Th color="white" fontSize={11}>
                    Processor Id
                  </Th>
                  <Th color="white" fontSize={11}>
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody
                // bgGradient={`linear(to-l, gray.100, gray.300)`}
                color="black"
              >
                {payments?.transaction?.map((payment, i) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    <Td>{payment?.transaction_id} </Td>
                    <Td>{payment?.email} </Td>
                    <Td>
                      {payment?.action[0]?.amount || payment?.action?.amount}
                      {" $"}
                    </Td>
                    <Td>
                      {payment?.action[0]?.action_type ||
                        payment?.action?.action_type}{" "}
                    </Td>
                    <Td>
                      {payment?.action[0]?.response_text ||
                        payment?.action?.response_text}{" "}
                    </Td>
                    <Td>{payment?.platform_id} </Td>
                    <Td>{payment?.condition}</Td>
                    <Td>{payment?.cardholder_auth || "-"}</Td>
                    <Td>{payment?.cc_type || "-"}</Td>
                    <Td>{payment?.initiated_by || "-"}</Td>
                    <Td>{payment?.processor_id}</Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setPayment(
                            payments.transaction.filter(
                              (target) =>
                                target?.transaction_id ===
                                payment?.transaction_id
                            )[0]
                          );

                          onOpen();
                        }}
                        disabled={!roles?.includes("MASTER", "COUPON")}
                        bgColor="transparent"
                        color="green.600"
                        align="center"
                        _hover={{ cursor: "pointer", color: "green.500" }}
                      >
                        <BiDetail size="20px" />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}
            >
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>

      <Flex ml="7%" mt={5}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<BiFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            className="app_btn"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
          >
            Per Page: {limit}
          </MenuButton>
          <MenuList color={colors.ocean} fontSize="14px">
            <MenuItem onClick={() => setLimit(10)}>10</MenuItem>
            <MenuItem onClick={() => setLimit(15)}>15</MenuItem>
            <MenuItem onClick={() => setLimit(20)}>20</MenuItem>
            <MenuItem onClick={() => setLimit(25)}>25</MenuItem>
            <MenuItem onClick={() => setLimit(30)}>30</MenuItem>
            <MenuItem onClick={() => setLimit(35)}>35</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Flex align="center" marginTop={-10} justify="center">
        <Box>
          <Button
            onClick={() => {
              let i = page;
              i--;
              if (i > 0) {
                setPage(i);
              }
            }}
            disabled={page === 1}
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            color="#ffff"
            className="app_btn"
            mx="5px"
            // _hover={{
            //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
            // }}
          >
            Previous
          </Button>
        </Box>

        <Box>
          <Button
            onClick={() => {
              if (payments?.length === 0) {
                toast({
                  position: "top",
                  duration: 5 * 1000,
                  status: "error",
                  render: () => (
                    <Box
                      bgColor="red.400"
                      borderRadius="5px"
                      color="#f5f5f5"
                      p="20px"
                      textAlign="center"
                    >
                      <Heading fontSize="sm" textTransform="uppercase">
                        There is no more data
                      </Heading>
                    </Box>
                  ),
                });
                return;
              }
              let i = page;
              i++;
              setPage(i);
            }}
            disabled={payments?.transaction?.length < page}
            mx="5px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            className="app_btn"
            color="#ffff"
            // _hover={{
            //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
            // }}
          >
            Next
          </Button>
        </Box>
      </Flex>

      <Modal
        isOpen={isOpen}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: "6xl",
        }}
        onClose={() => {
          setFilterPaymentlist({
            start_date: "",
            condition: "",
            action_type: "",
            result_order: "",
            end_date: "",
            first_name: "",
            last_name: "",
            email: "",
            cc_type: "",
            security_key: filterBy,
          });

          onClose();
          setIsFilter(false);
          setEmail("");
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">
            {isFilter ? "Filters" : "Details"}
          </ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody h="100vh">
            <div>
              {isFilter ? filterPayment() : <DetailPayment payment={payment} />}
            </div>
          </ModalBody>
          <ModalFooter>
            {isFilter && (
              <Button
                colorScheme="blue"
                className="app_btn"
                mr={3}
                onClick={() => {
                  if (start && end) {
                    if (start > end) {
                      toast({
                        position: "top",
                        duration: 5 * 1000,
                        status: "error",
                        render: () => (
                          <Box
                            bgColor="red.400"
                            borderRadius="5px"
                            color="#f5f5f5"
                            p="20px"
                            textAlign="center"
                          >
                            <Heading fontSize="sm" textTransform="uppercase">
                              Start Date should be less than End Date
                            </Heading>
                          </Box>
                        ),
                      });
                      return;
                    }
                  }

                  setFilterPaymentlist({
                    start_date: start,
                    condition,
                    action_type: actionType,
                    result_order: order,
                    end_date: end,
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    cc_type: ccType,
                    security_key: filterBy,
                  });
                  setIsFilter(false);
                  onClose();
                }}
              >
                Apply
              </Button>
            )}

            {isFilter && (
              <Button
                onClick={() => {
                  setStart("");
                  setEnd("");
                  setActionType("");
                  setCondition("");
                  setOrder("");
                  setEmail("");
                  setFirstName("");
                  setLastName("");
                }}
              >
                Reset
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ListPayments;
