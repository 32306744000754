import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { colors } from "../../utils/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useAuth } from "../../utils/auth";
import "../TTTblacklist/new-blacklisted/datepicker1.css";
import DatePicker from "react-datepicker";
import { client } from "../../utils/axios";

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),
  email: yup.string().email().required("Email is required"),
  accountID: yup.string().required("Account ID is required"),
  ticketNbr: yup.number().required("Ticket Number is required"),
  country: yup.string().required("Country is required"),
  address: yup.string().required("Address is required"),
  paymentMethod: yup.string().required("Payment Method is required"),
  paymentId: yup.string().required("Payment ID is required"),
  swiftCode: yup.string().when("paymentMethod", {
    is: "Bank Transfer", // Check if paymentMethod is 'ttt1'
    then: yup.string().required("Swift Code is required"),
    otherwise: yup.string(), // No validation when paymentMethod is not 'ttt1'
  }),
  bankAddress: yup.string().when("paymentMethod", {
    is: "Bank Transfer", // Check if paymentMethod is 'ttt1'
    then: yup.string().required("Bank Address is required"),
    otherwise: yup.string(), // No validation when paymentMethod is not 'ttt1'
  }),
  network: yup.string().when("paymentMethod", {
    is: "crypto", // Check if paymentMethod is 'ttt1'
    then: yup.string().required("network is required"),
    otherwise: yup.string(), // No validation when paymentMethod is not 'ttt1'
  }),
  cryptoCurrency: yup.string().when("paymentMethod", {
    is: "crypto", // Check if paymentMethod is 'ttt1'
    then: yup.string().required("crypto currency is required"),
    otherwise: yup.string(), // No validation when paymentMethod is not 'ttt1'
  }),
  otherNetowrk: yup.string().when("network", {
    is: "Other", // Check if paymentMethod is 'ttt1'
    then: yup.string().required("network is required"),
    otherwise: yup.string(), // No validation when paymentMethod is not 'ttt1'
  }),
  otherCurrency: yup.string().when("cryptoCurrency", {
    is: "Other", // Check if paymentMethod is 'ttt1'
    then: yup.string().required("Crypto Currency is required"),
    otherwise: yup.string(), // No validation when paymentMethod is not 'ttt1'
  }),
  // amount: yup.number().required("Amount is required"),
  cin_passport: yup.string().required("ID or Passport is required"),
  userID: yup.string().required("userID is required"),
  // accountType: yup.string().required("Account Type is required"),
  requestedAmount: yup
    .number("requested amount must be a positive number")
    .required("Requested Amount is required"),
  withdrawAmount: yup
    .number("withdraw amount must be a positive number")
    .required("Withdraw Amount is required"),
  nbrActiveTradingDays: yup
    .number("Active days must be a positive number")
    .required("Number of Active Trading Days is required"),
  // // AccountCreationDate: yup.date().required("Account Balance is required"),
  // withdrawPercentagePermtted: yup
  //   .string()
  //   .required("Withdraw Percentage is required"),
});

function CreatePayout() {
  const auth = useAuth();
  const toast = useToast();
  const [date, setDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [paymentMethod, setPaymentMethod] = React.useState("deel");
  // const [network, setNetwork] = React.useState("ERC-20");
  // const [cryptoCurrency, setCryptoCurrency] = React.useState("deel");
  const selectedNetowrk = watch("network");
  const selectedCurrency = watch("cryptoCurrency");
  const [accountType, setAccountType] = React.useState("TTTperformence 25k");
  const [withdrawPercentagePermitted, setWithdrawPercentagePermitted] =
    React.useState("20");

  const onSubmit = async (data) => {
    data.AccountCreationDate = date.toISOString().split("T")[0];
    data.withdrawPercentagePermitted = parseInt(withdrawPercentagePermitted);
    if (paymentMethod !== "crypto") {
      data.network = undefined;
      data.otherNetowrk = undefined;
      data.cryptoCurrency = undefined;
      data.otherCurrency = undefined;
    }
    if (paymentMethod !== "Bank Transfer") {
      data.swiftCode = undefined;
      data.bankAddress = undefined;
    }
    if (selectedNetowrk === "Other") {
      data.network = data.otherNetowrk;
      data.otherNetowrk = undefined;
    }
    if (selectedCurrency === "Other") {
      data.cryptoCurrency = data.otherCurrency;
      data.otherCurrency = undefined;
    }
    try {
      await client
        .post(`${process.env.REACT_APP_API_HOST}/payout`, data, {
          // headers: {
          //   "auth-token": auth.user.accessToken,
          // },
        })
        .then(() => {
          toast({
            position: "top",
            duration: 5 * 1000,
            status: "success",
            render: () => (
              <Box
                bgColor="yellow.400"
                borderRadius="5px"
                color="#f5f5f5"
                p="20px"
                textAlign="center">
                <Heading fontSize="sm" textTransform="uppercase">
                  Payout added successfully
                </Heading>
              </Box>
            ),
          });
          reset();
        })
        .catch((error) => {
          toast({
            position: "top",
            duration: 5 * 1000,
            status: "error",
            render: () => (
              <Box
                bgColor="red.400"
                borderRadius="5px"
                color="#f5f5f5"
                p="20px"
                textAlign="center">
                <Heading fontSize="sm" textTransform="uppercase">
                  {error.response.data.message}
                </Heading>
              </Box>
            ),
          });
        });
      clearErrors();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          align="stretch"
          spacing={10}
          px="50px"
          pb="50px"
          mb="50px"
          mt="100px"
          borderRadius={20}
          className="sidebar_nav"
          maxW="900px"
          mx="auto"
          color="gray.50">
          <Heading color="#000" size="lg" py="10px">
            Add a new payout
          </Heading>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl w="40%">
              <FormLabel color="#000">Full name</FormLabel>
              <Input
                name="fullName"
                {...register("fullName", { required: true })}
                type="text"
                placeholder="Enter full name"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />
              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.fullName?.message}
              </Badge>
            </FormControl>

            <FormControl w="40%">
              <FormLabel color="#000">Email</FormLabel>
              <Input
                name="email"
                {...register("email", { required: true })}
                type="email"
                placeholder="Enter email"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.email?.message}
              </Badge>
            </FormControl>

            <FormControl w="30%">
              <FormLabel color="#000">ID or Passport</FormLabel>
              <Input
                name="cin_passport"
                {...register("cin_passport", { required: true })}
                type="text"
                placeholder="Enter ID | Passport"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.cin_passport?.message}
              </Badge>
            </FormControl>
          </Flex>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl w="30%">
              <FormLabel color="#000">Ticket Number</FormLabel>
              <Input
                name="ticketNbr"
                {...register("ticketNbr", { required: true })}
                type="number"
                placeholder="Enter Ticket Number"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.ticketNbr?.message}
              </Badge>
            </FormControl>

            <FormControl w="30%">
              <FormLabel color="#000">Country</FormLabel>
              <Select
                name="country"
                {...register("country", { required: true })}
                placeholder="Select country"
                borderColor={colors.lightBlue}
                color={colors.dark}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}>
                {countriesList.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Select>

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.country?.message}
              </Badge>
            </FormControl>

            <FormControl w="100%">
              <FormLabel color="#000">Address</FormLabel>
              <Input
                name="address"
                {...register("address", { required: true })}
                type="text"
                placeholder="Enter Address"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.address?.message}
              </Badge>
            </FormControl>
          </Flex>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl w="25%">
              <FormLabel color="#000">Account ID</FormLabel>
              <Input
                name="accountID"
                {...register("accountID", { required: true })}
                type="text"
                placeholder="Enter Account ID"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.accountID?.message}
              </Badge>
            </FormControl>
            <FormControl w="25%">
              <FormLabel color="#000">UserID</FormLabel>
              <Input
                name="userID"
                {...register("userID", { required: true })}
                type="text"
                placeholder="Enter userID"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.userID?.message}
              </Badge>
            </FormControl>
            <FormControl w="40%">
              <FormLabel color="#000">Account Type</FormLabel>
              <Select
                name="accountType"
                {...register("accountType", { required: true })}
                placeholder="Select Account type"
                borderColor={colors.lightBlue}
                color={colors.dark}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                defaultValue={"TTTperformence 25k"}
                // 25K Performance Account , 50K Performance Account , 100k Performance Account, 10K Direct Swing Account, 25K Direct Account, 50K Direct Account
                onChange={(e) => setAccountType(e.target.value)}>
                <option value="25K Performance Account">
                  25K Performance Account
                </option>
                <option value="50K Performance Account">
                  50K Performance Account
                </option>
                <option value="100k Performance Account">
                  100k Performance Account
                </option>
                <option value="10K Direct Swing Account">
                  10K Direct Swing Account
                </option>
                <option value="25K Direct Account"> 25K Direct Account</option>
                <option value="50K Direct Account">50K Direct Account</option>
              </Select>

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.accountType?.message}
              </Badge>
            </FormControl>
            <FormControl w="45%" h={"100%"}>
              <FormLabel color="#000" fontSize={14}>
                Date Of Account Creation
              </FormLabel>
              <DatePicker
                className="datepicker1"
                selected={date}
                onChange={(date) => setDate(date)}
              />
            </FormControl>
          </Flex>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl w="100%">
              <FormLabel color="#000">Withdraw Percentage Permited</FormLabel>
              <Select
                name="withdrawPercentagePermitted"
                {...register("withdrawPercentagePermitted", { required: true })}
                placeholder="Select Withdraw percentage Permitted"
                borderColor={colors.lightBlue}
                color={colors.dark}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                defaultValue={"deel"}
                onChange={(e) =>
                  setWithdrawPercentagePermitted(e.target.value)
                }>
                <option value="20">20%</option>
                <option value="50">50%</option>
                <option value="90">90%</option>
                <option value="100">100%</option>
              </Select>

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.withdrawPercentagePermitted?.message}
              </Badge>
            </FormControl>
            <FormControl w="100%">
              <FormLabel color="#000">Account Balance</FormLabel>
              <Input
                name="accountBalance"
                {...register("accountBalance", { required: true })}
                type="number"
                placeholder="Enter accountBalance"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.accountBalance?.message}
              </Badge>
            </FormControl>
            <FormControl w="100%">
              <FormLabel color="#000">Confirmed Amount</FormLabel>
              <Input
                name="requestedAmount"
                {...register("requestedAmount", { required: true })}
                type="number"
                placeholder="Enter Requested Amount"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.requestedAmount?.message}
              </Badge>
            </FormControl>
            <FormControl w="100%">
              <FormLabel color="#000">Withdraw Amount</FormLabel>
              <Input
                name="withdrawAmount"
                {...register("withdrawAmount", { required: true })}
                type="number"
                placeholder="Enter Withdraw Amount"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.withdrawAmount?.message}
              </Badge>
            </FormControl>
          </Flex>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            <FormControl w="100%">
              <FormLabel color="#000">Number of Active Trading Days</FormLabel>
              <Input
                name="nbrActiveTradingDays"
                {...register("nbrActiveTradingDays", { required: true })}
                type="number"
                placeholder="Enter Number of Active Days of Trading"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.nbrActiveTradingDays?.message}
              </Badge>
            </FormControl>

            <FormControl w="100%">
              <FormLabel color="#000">Payment Method</FormLabel>
              <Select
                name="paymentMethod"
                {...register("paymentMethod", { required: true })}
                placeholder="Select payment method"
                borderColor={colors.lightBlue}
                color={colors.dark}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                defaultValue={"deel"}
                onChange={(e) => setPaymentMethod(e.target.value)}>
                <option value="Rise">Rise</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="crypto">Crypto</option>
              </Select>

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.paymentMethod?.message}
              </Badge>
            </FormControl>

            <FormControl w="100%">
              <FormLabel color="#000">
                {paymentMethod === "Rise"
                  ? "Rise account"
                  : paymentMethod === "deel"
                  ? "Deel account"
                  : paymentMethod === "Bank Transfer"
                  ? "IBAN"
                  : "Wallet Address"}
              </FormLabel>
              <Input
                name="paymentId"
                {...register("paymentId", { required: true })}
                type="text"
                placeholder={
                  paymentMethod === "deel"
                    ? "Enter Deel account"
                    : paymentMethod === "Rise"
                    ? "Enter Rise Email"
                    : paymentMethod === "Bank Transfer"
                    ? "Enter IBAN"
                    : "Enter Wallet Address"
                }
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#000",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.paymentId?.message}
              </Badge>
            </FormControl>
            {paymentMethod === "Bank Transfer" && (
              <>
                <FormControl>
                  <FormLabel color="#000">Swift Code</FormLabel>
                  <Input
                    name="swiftCode"
                    {...register("swiftCode", { required: true })}
                    type="text"
                    placeholder="Enter Swift Code"
                    borderColor={colors.lightBlue}
                    color={colors.lightBlue}
                    _focus={{ borderColor: colors.lightBlue }}
                    _placeholder={{
                      opacity: 0.7,
                      color: "#000",
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                  />

                  <Badge
                    fontWeight={600}
                    colorScheme="red"
                    fontSize="12px"
                    align="left"
                    mt="10px">
                    {errors?.swiftCode?.message}
                  </Badge>
                </FormControl>
                <FormControl>
                  <FormLabel color="#000"> Bank Address </FormLabel>
                  <Input
                    name="bankAddress"
                    {...register("bankAddress", { required: true })}
                    type="text"
                    placeholder="Enter Swift Code"
                    borderColor={colors.lightBlue}
                    color={colors.lightBlue}
                    _focus={{ borderColor: colors.lightBlue }}
                    _placeholder={{
                      opacity: 0.7,
                      color: "#000",
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                  />

                  <Badge
                    fontWeight={600}
                    colorScheme="red"
                    fontSize="12px"
                    align="left"
                    mt="10px">
                    {errors?.bankAddress?.message}
                  </Badge>
                </FormControl>
              </>
            )}
            {paymentMethod === "crypto" && (
              <>
                <FormControl>
                  <FormLabel color="#000">Network</FormLabel>
                  <Select
                    name="network"
                    {...register("network", { required: true })}
                    placeholder="Select payment method"
                    borderColor={colors.lightBlue}
                    color={colors.dark}
                    _focus={{ borderColor: colors.lightBlue }}
                    _placeholder={{
                      opacity: 0.7,
                      color: "#000",
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                    defaultValue={"deel"}
                    // onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <option value="ERC-20">ERC-20</option>
                    <option value="BEP-20">BEP-20</option>
                    <option value="TRC-20">TRC-20</option>
                    <option value="Other">Other</option>
                  </Select>

                  {selectedNetowrk === "Other" && (
                    <>
                      <Input
                        mt={2}
                        name="otherNetowrk"
                        {...register("otherNetowrk", { required: true })}
                        type="text"
                        placeholder="Enter your Network"
                        borderColor={colors.lightBlue}
                        color={colors.lightBlue}
                        _focus={{ borderColor: colors.lightBlue }}
                        _placeholder={{
                          opacity: 0.7,
                          color: "#000",
                          fontSize: 14,
                          fontStyle: "italic",
                        }}
                      />
                      <Badge
                        fontWeight={600}
                        colorScheme="red"
                        fontSize="12px"
                        align="left"
                        mt="10px">
                        {errors?.otherNetowrk?.message}
                      </Badge>
                    </>
                  )}

                  <Badge
                    fontWeight={600}
                    colorScheme="red"
                    fontSize="12px"
                    align="left"
                    mt="10px">
                    {errors?.network?.message}
                  </Badge>
                </FormControl>
                <FormControl w="100%">
                  <FormLabel color="#000">Crypto Currency</FormLabel>
                  <Select
                    name="cryptoCurrency"
                    {...register("cryptoCurrency", { required: true })}
                    placeholder="Select Crypto Currency"
                    borderColor={colors.lightBlue}
                    color={colors.dark}
                    _focus={{ borderColor: colors.lightBlue }}
                    _placeholder={{
                      opacity: 0.7,
                      color: "#000",
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                    defaultValue={"deel"}
                    // onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <option value="BNB">BNB</option>
                    <option value="USDT">USDT</option>
                    <option value="BUSD">BUSD</option>
                    <option value="BTC">BTC</option>
                    <option value="ETH">ETH</option>
                    <option value="XVG">XVG</option>
                    <option value="USDC">USDC</option>
                    <option value="TUSD">TUSD</option>
                    <option value="Other">Other</option>
                  </Select>
                  {selectedCurrency === "Other" && (
                    <>
                      <Input
                        mt={2}
                        name="otherCurrency"
                        {...register("otherCurrency", { required: true })}
                        type="text"
                        placeholder="Enter your ohter currency"
                        borderColor={colors.lightBlue}
                        color={colors.lightBlue}
                        _focus={{ borderColor: colors.lightBlue }}
                        _placeholder={{
                          opacity: 0.7,
                          color: "#000",
                          fontSize: 14,
                          fontStyle: "italic",
                        }}
                      />
                      <Badge
                        fontWeight={600}
                        colorScheme="red"
                        fontSize="12px"
                        align="left"
                        mt="10px">
                        {errors?.otherCurrency?.message}
                      </Badge>
                    </>
                  )}
                  <Badge
                    fontWeight={600}
                    colorScheme="red"
                    fontSize="12px"
                    align="left"
                    mt="10px">
                    {errors?.cryptoCurrency?.message}
                  </Badge>
                </FormControl>
              </>
            )}
          </Flex>
          <Flex flexDir={"row"} justifyContent={"space-evenly"} gap={5}>
            {/* <FormControl w="100%">
              <FormLabel>Amount</FormLabel>
              <Input
                name="amount"
                {...register("amount", { required: true })}
                type="number"
                placeholder="Enter Amount"
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                _placeholder={{
                  opacity: 0.7,
                  color: "#ffff",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              />

              <Badge
                fontWeight={600}
                colorScheme="red"
                fontSize="12px"
                align="left"
                mt="10px">
                {errors?.amount?.message}
              </Badge>
            </FormControl> */}
          </Flex>
          <Flex align="center" justify={`center`} pt="20px">
            <Button
              w="100%"
              borderRadius={15}
              fontSize="12px"
              // bgGradient={`linear(to-b, #01298B, #01298C)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"
              // _hover={{ bgGradient: `linear(to-r, #01298B, #01298B)` }}
              type="submit">
              + Add Payout
            </Button>
          </Flex>
        </VStack>
      </form>
    </div>
  );
}

export default CreatePayout;

export const countriesList = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
