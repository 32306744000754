import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  VStack,
  Input,
  Flex,
  Button,
  Heading,
  useToast,
  Box,
  Text,
  Badge,
  chakra,
  Switch,
  SimpleGrid,
} from "@chakra-ui/react";
import chroma from "chroma-js";
import { colors } from "../../utils/colors";
import Papa from "papaparse";
import DatePicker from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./datepicker1.css";
import useSubscriptionMutation from "../../hooks/useMutations/useSubscriptionMutation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useSubscriptions from "../../hooks/useQueries/useSubscriptions";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import useCouponlistMutation from "../../hooks/useMutations/useCouponlistMutation";
import { useListCoupons } from "../../hooks/useQueries/useCoupons";
import { useEffect } from "react";
import Select from "react-select";
import { convertedArrayToOptions } from "../../utils/commen";

const schema = yup.object().shape({
  // coupon: yup.string().required("!! coupon is a required field."),
  email: yup.string().email().required(" !! email is a required field."),
});
const schemaCSV = yup.object().shape({
  // coupon: yup.string().required("!! coupon is a required field."),
  email: yup.mixed().required("File is required"),
});

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

export default function AddCoupons() {
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isEmailsValid, setIsEmailsValid] = useState(true);
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);

  const [accountName, setAccountName] = useState("");
  const auth = useAuth();
  const { AddCoupons } = useCouponlistMutation();
  const { refetchSubscriptions } = useSubscriptions();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(isChecked ? schemaCSV : schema),
  });

  // const handleCSVRead = (data, fileInfo) => {
  //   console.log(data[0]);
  const { listCoupons, refetchListCoupons } = useListCoupons("");

  useEffect(() => {
    refetchListCoupons();
  }, []);
  //   setCsvData(data[0]);
  //   console.log(data);
  // };

  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "black",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "green" : isSelected ? "green" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const onSubmitCheck = async (data) => {
    try {
      await AddCoupons({
        coupon: value,
        email: [data.email],
      });

      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="yellow.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center"
          >
            <Heading fontSize="sm" textTransform="uppercase">
              Coupon added succesfully !
            </Heading>
          </Box>
        ),
      });
      reset();
      clearErrors();
    } catch (error) {
      console.error(error);
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center"
          >
            <Heading fontSize="sm" textTransform="uppercase">
              {error?.response?.data?.message}
            </Heading>
          </Box>
        ),
      });
    }
  };

  const changeHandler = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          valuesArray.push(d["Email"]);
        });

        // Parsed Data Response in array format
        setParsedData(results.data);
        // console.log(results.data);
        // Filtered Column Names
        setTableRows(rowsArray[0]);
        // console.log(rowsArray[0]);
        // Filtered Values
        setValues(valuesArray);
        console.log(valuesArray);
      },
    });
  };

  const addCouponsSubmit = async (data) => {
    try {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!data.email) {
        setError("File csv is required");
        return;
      }
      if (values.length === 0) {
        setError("File csv is empty!!");
        return;
      }
      if (isChecked && values.length > 0) {
        for (const email of values) {
          if (!email.match(validRegex)) {
            setIsEmailsValid(false);
            toast({
              position: "top",
              duration: 5 * 1000,
              status: "error",
              render: () => (
                <Box
                  bgColor="red.400"
                  borderRadius="5px"
                  color="#f5f5f5"
                  p="20px"
                  textAlign="center"
                >
                  <Heading fontSize="sm">
                    The email {email} is not valid
                  </Heading>
                </Box>
              ),
            });
            break;
          }
        }
        if (isEmailsValid) {
          await AddCoupons({
            coupon: value,
            email: values,
          });
        }
        toast({
          position: "top",
          duration: 5 * 1000,
          status: "success",
          render: () => (
            <Box
              bgColor="yellow.400"
              borderRadius="5px"
              color="#f5f5f5"
              p="20px"
              textAlign="center"
            >
              <Heading fontSize="sm" textTransform="uppercase">
                Coupon added succesfully !
              </Heading>
            </Box>
          ),
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center"
          >
            <Heading fontSize="sm" textTransform="uppercase">
              {error?.response?.data?.message}
            </Heading>
          </Box>
        ),
      });
    }
  };

  document.title = "TTTSubscriptions | Add coupon";

  return (
    <form
      onSubmit={
        isChecked ? handleSubmit(addCouponsSubmit) : handleSubmit(onSubmitCheck)
      }
    >
      <VStack
        align="stretch"
        spacing={10}
        px="50px"
        pb="50px"
        mb="50px"
        mt="100px"
        borderRadius={20}
        className="sidebar_nav"
        maxW="900px"
        mx="auto"
        color="gray.50"
      >
        <Heading color="#000" size="lg" py="20px">
          Add coupon
        </Heading>
        {JSON.stringify(listCoupons)}

        {/*  */}
        <FormControl as={SimpleGrid} columns={{ base: 4, lg: 4 }}>
          <FormLabel color="#000" htmlFor="csv">
            With csv file:
          </FormLabel>
          <Switch
            id="csv"
            isChecked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <FormLabel color="#000" htmlFor="inp">
            With email input:
          </FormLabel>
          <Switch
            id="inp"
            isChecked={!isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        </FormControl>
        {/*  */}

        {isChecked && (
          <FormControl w="100%">
            <FormLabel color="#000">CSV file</FormLabel>

            <Input
              name="email"
              {...register("email", { required: true })}
              onChange={(e) => {
                changeHandler(e);
              }}
              type="file"
              borderColor={colors.lightBlue}
              accept="text/csv"
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              _placeholder={{
                opacity: 0.7,
                color: "#ffff",
                fontSize: 14,
                fontStyle: "italic",
              }}
            />
            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px"
            >
              {error}
              {/* {JSON.stringify(errors.email)} */}
            </Badge>
          </FormControl>
        )}

        <FormControl w="100%">
          <FormLabel color="#000">Coupon</FormLabel>
          {/* <Input
            name="coupon"
            {...register("coupon", { required: true })}
            type="text"
            placeholder="Enter coupon here..."
            borderColor={colors.lightBlue}
            color={colors.lightBlue}
            _focus={{ borderColor: colors.lightBlue }}
            _placeholder={{
              opacity: 0.7,
              color: "#ffff",
              fontSize: 14,
              fontStyle: "italic",
            }}
          /> */}

          {listCoupons?.length > 0 && (
            <Select
              name="coupon"
              placeholder="Select coupon"
              onChange={(e) => {
                setValue(e.valueOf());
              }}
              value={value}
              // className="basic-single"
              options={convertedArrayToOptions(listCoupons)}
              styles={colourStyles}
            />
          )}
          {/* <Select
            placeholder="Select coupon"
            name="coupon"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            // {...register("coupon", { required: true })}
          >
            {
              listCoupons.map((coupon, i) => (
                <option key={i} value={coupon.code}>
                  {coupon.code}
                </option>
              ))}
          </Select> */}

          <Badge
            fontWeight={600}
            colorScheme="red"
            fontSize="12px"
            align="left"
            mt="10px"
          >
            {errors.coupon?.message}
          </Badge>
        </FormControl>

        {!isChecked && (
          <FormControl>
            <FormLabel color="#000">Email</FormLabel>
            <Input
              name="email"
              {...register("email", { required: true })}
              type="text"
              placeholder="Enter your email here..."
              borderColor={colors.lightBlue}
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              _placeholder={{
                opacity: 0.7,
                color: "#000",
                fontSize: 14,
                fontStyle: "italic",
              }}
            />

            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px"
            >
              {errors.email?.message}
            </Badge>
          </FormControl>
        )}

        <Flex align="center" justify={`center`} pt="50px">
          <Button
            w="100%"
            borderRadius={15}
            fontSize="12px"
            // bgGradient={`linear(to-b, #01298B, #01298C)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #01298B, #01298B)` }}
            className="app_btn"
            type="submit"
          >
            + Add coupon
          </Button>
        </Flex>
      </VStack>
    </form>
  );
}
