import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { Routes, Route, Outlet } from "react-router";
import NavbarSubs from "./components/NavbarSubs";
import Sidebar from "./components/Sidebar";
import AddSubscription from "./pages/add-subscription";
import TTTSubscriptionsHomepage from "./pages/tttsubhomepage";
import ListSubscriptions from "./pages/list-subscriptions";
import { colors } from "./utils/colors";
import Apps from "./pages/Admin/apps";
import NewAdmin from "./pages/Admin/add-admin";
import ListAdmins from "./pages/Admin/list-admin";
import Profile from "./pages/Admin/profile";
import NavbarBookmap from "./components/NavbarBookmap";
import TTTbookmapAddLicense from "./pages/bookmap/add-license";
import BookmapLicensesList from "./pages/bookmap/licenses";
import BookmapAvailableLicensesList from "./pages/bookmap/available-licenses";
import NavbarBlacklist from "./components/NavbarBlacklist";
import NewBlacklisted from "./pages/TTTblacklist/new-blacklisted";
import Blacklisted from "./pages/TTTblacklist/blacklist";
import AdminHomepage from "./pages/Admin/homepage";
import LoginPage from "./pages/homepage";
import { RequireAuth, RequireNoAuth } from "./utils/auth";
import CreatePayout from "./pages/ttt-payout/CreatePayout";
import NavbarPayout from "./components/NavbarPayout";
import ListPayout from "./pages/ttt-payout/ListPayout";
import NavbarJournalytix from "./components/NavbarJournalytix";
import NavbarTradovate from "./components/tradovateActiveAccounts";
import ListJournalytix from "./pages/ttt-journalytix/ListJournalytix";
import CreateJournalytix from "./pages/ttt-journalytix/CreateJournalytix";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Home from "./pages";
import ThirdPartyEmailPassword, {
  Github,
  Google,
  Facebook,
  Apple,
} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { SuperTokensConfig } from "./configSuperTokens.ts";
import * as reactRouterDom from "react-router-dom";
import Session from "supertokens-auth-react/recipe/session";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import ThirdParty from "supertokens-web-js/recipe/thirdparty";
import { MdAllInbox } from "react-icons/md";
import ListCoupons from "./pages/list-coupons";
import NavbarCoupons from "./components/NavbarCoupons";
import AddCoupons from "./pages/add-coupons";
import HistoryPayout from "./pages/ttt-payout/HistoryPayout";
import ListPayments from "./pages/list-payments";
import NavbarPayment from "./components/NavBarPayment";
import NavbarResetAccount from "./components/NavbarResetAccount";
import FundedList from "./pages/funded-list/index";
import NavbarFunded from "./components/NavbarFunded";
import Funded from "./pages/funded-list/Funded";
import MultipleAccounts from "./pages/funded-list/MultipleAccounts";
import UpdateMultipleAccounts from "./pages/funded-list/UpdateMultipleAccount";
import ConsitencyList from "./pages/funded-list/ConsitencyList";
import ListResetAccounts from "./pages/list-resetAccounts";
import ActiveAccount from "./pages/tvActiveAccounts";
import UserActiveAccount from "./pages/UserActiveAccounts";
import NavbarUserAccounts from "./components/UserActiveAccounts";
import NavbarTargetReachedChecker from "./components/TargetReachedChecker";
import AccountCertificate from "./pages/accountCertificate";
import TRchecker from "./pages/TR-Chekcer";
import NavbarAccountCertificate from "./components/NavbarAcoountCertificate";

// SuperTokens.init({
//   appInfo: {
//     apiDomain: "http://localhost:4000",
//     apiBasePath: "/auth",
//     websiteDomain: "http://localhost:3008",
//     appName: "...",
//     websiteBasePath: "/auth",
//   },
//   recipeList: [Session.init(), ThirdPartyEmailPassword.init()],
// });
// SuperTokens.init({
//   appInfo: {
//     // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
//     appName: "tickticksubAPI",
//     apiDomain: "http://localhost:4000",
//     websiteDomain: "http://localhost:3008",
//     apiBasePath: "/auth",
//     websiteBasePath: "/",
//   },
//   recipeList: [
// ThirdPartyEmailPassword.init({
//   signInAndUpFeature: {
//     providers: [
//       // Github.init(),
//       Google.init(),
//       // Facebook.init(),
//       // Apple.init(),
//     ],
//   },
//   getRedirectionURL: async (context) => {
//     if (context.action === "SUCCESS") {
//       if (context.redirectToPath !== undefined) {
//         // we are navigating back to where the user was before they authenticated
//         return context.redirectToPath;
//       }
//       return "/test";
//       // window.location.href = "http://159.69.142.232:3008/";
//     }
//     return undefined;
//   },
// }),
//     ThirdParty.init({
//       signInAndUpFeature: {
//         providers: [
//           // Github.init(),
//           Google.init(),
//           // Facebook.init(),
//           // Apple.init(),
//         ],
//       },
//     }),
//     // ThirdParty.init({
//     //   useShadowDom: true,
//     //   signInAndUpFeature: {
//     //     providers: [
//     //       // Github.init(),
//     //       // Google.init(),
//     //       // Facebook.init(),
//     //       // Apple.init(),
//     //     ],
//     //   },
//     //   emailVerificationFeature: {
//     //     disableDefaultImplementation: false,
//     //     // mode: "REQUIRED" | "OFF",
//     //     // sendVerifyEmailScreen: {...},
//     //     // verifyEmailLinkClickedScreen: {...}
//     //   },
//     //   // preAPIHooks(context) {},
//     //   // getRedirectionURL(context) {}
//     //   // onHandleEvent(context) {}
//     // }),
//     Session.init({
//       // sessionTokenBackendDomain: "http://localhost:4000",
//       // override: {
//       //   functions: (originalImplementation) => {
//       //     return {
//       //       ...originalImplementation,
//       //       createNewSession: async function (input) {
//       //         let userId = input.userId;
//       //         // This goes in the access token, and is availble to read on the frontend.
//       //         input.accessTokenPayload = {
//       //           ...input.accessTokenPayload,
//       //           someKey: "someValue",
//       //         };
//       //         return originalImplementation.createNewSession(input);
//       //       },
//       //     };
//       //   },
//       // },
//     }),
//   ],
// });

SuperTokens.init(SuperTokensConfig);
const AdminTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(15, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={1} colSpan={2}>
        <Sidebar />
      </GridItem>
      <GridItem colSpan={13} overflow="auto" borderRightRadius="20px" p="20px">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const CouponsTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarCoupons />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const PaymentsTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarPayment />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const ResetTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarResetAccount />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};
const FundedTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarFunded />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const TTTSubTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarSubs />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const TTTBookmapTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarBookmap />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const TTTBlacklistTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarBlacklist />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const TTTLoginPage = (props) => {
  <Box
    h="100vh"
    bgGradient={`linear(to-t, ${colors.ocean}, ${colors.darkblue})`}>
    <Outlet />
  </Box>;
};
// const Test = (props) => {
//   <Box
//     h="100vh"
//     bgGradient={`linear(to-t, ${colors.ocean}, ${colors.darkblue})`}></Box>;
// };

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <RequireNoAuth>
              <LoginPage />
            </RequireNoAuth>
          }
        />
        <Route
          path={process.env.REACT_APP_REDIRECT_PATH}
          element={
            <RequireNoAuth>
              <Home />
            </RequireNoAuth>
          }
        />

        <Route
          element={
            <RequireAuth>
              <AdminTemplate />
            </RequireAuth>
          }>
          <Route path="/apps" element={<Apps />} />
          <Route path="/new-admin" element={<NewAdmin />} />
          <Route path="/admins" element={<ListAdmins />} />
          <Route path="/profile" element={<Profile />} />
        </Route>

        <Route
          element={
            <RequireAuth>
              <TTTSubTemplate />
            </RequireAuth>
          }>
          <Route
            path="/apps/tttsubscriptions"
            element={<TTTSubscriptionsHomepage />}
          />
          <Route
            path="/apps/tttsubscriptions/add-subscription"
            element={<AddSubscription />}
          />
          <Route
            path="/apps/tttsubscriptions/list-subscription"
            element={<ListSubscriptions />}
          />
        </Route>
        {/*  */}
        <Route
          element={
            <RequireAuth>
              <CouponsTemplate />
            </RequireAuth>
          }>
          <Route path="/apps/coupons/list" element={<ListCoupons />} />
          <Route path="/apps/coupons/add-coupon" element={<AddCoupons />} />
          {/* <Route
            path="/apps/tttsubscriptions/list-subscription"
            element={<ListSubscriptions />}
          /> */}
        </Route>
        {/*  */}
        {/*  */}
        <Route
          element={
            <RequireAuth>
              <PaymentsTemplate />
            </RequireAuth>
          }>
          <Route path="/apps/payments/list" element={<ListPayments />} />
        </Route>
        <Route
          element={
            <RequireAuth>
              <ResetTemplate />
            </RequireAuth>
          }>
          <Route
            path="/apps/resetAccount/list"
            element={<ListResetAccounts />}
          />
        </Route>

        <Route
          element={
            <RequireAuth>
              <FundedTemplate />
            </RequireAuth>
          }>
          <Route path="/apps/funded" element={<Funded />} />
          <Route path="/apps/funded/list" element={<FundedList />} />
          <Route
            path="/apps/funded/Consitency/list"
            element={<ConsitencyList />}
          />
          <Route
            path="/apps/funded/multiple-accounts/list"
            element={<MultipleAccounts />}
          />
          <Route
            path="/apps/funded/update/multiple-account/:id"
            element={<UpdateMultipleAccounts />}
          />
        </Route>
        {/*  */}
        <Route
          element={
            <RequireAuth>
              <TTTBookmapTemplate />
            </RequireAuth>
          }>
          <Route
            path="/apps/tttbookmap/add-bookmap-license"
            element={<TTTbookmapAddLicense />}
          />
          <Route
            path="/apps/tttbookmap/list-licenses"
            element={<BookmapLicensesList />}
          />
          <Route
            path="/apps/tttbookmap/available-licenses"
            element={<BookmapAvailableLicensesList />}
          />
        </Route>

        <Route element={<TTTBlacklistTemplate />}>
          <Route
            path="/apps/tttblacklist/new-blacklisted"
            element={<NewBlacklisted />}
          />
          <Route
            path="/apps/tttblacklist/blacklist"
            element={<Blacklisted />}
          />
        </Route>

        <Route element={<TTTPayoutTemplate />}>
          <Route path="/apps/tttpayout/create" element={<CreatePayout />} />
          <Route path="/apps/tttpayout/list" element={<ListPayout />} />
          <Route
            path="/apps/tttpayout/payoutActivities"
            element={<HistoryPayout />}
          />
        </Route>
        <Route element={<TTTJournalitixTemplate />}>
          <Route
            path="/apps/tttjournalytix/create"
            element={<CreateJournalytix />}
          />
          <Route
            path="/apps/tttjournalytix/list"
            element={<ListJournalytix />}
          />
        </Route>
        <Route element={<TTTActiveAccounts />}>
          <Route path="/apps/activeAccounts/" element={<ActiveAccount />} />
        </Route>
        <Route element={<TTTUserActiveAccounts />}>
          <Route
            path="/apps/allActiveAccounts/"
            element={<UserActiveAccount />}
          />
        </Route>
        <Route element={<TTTTR />}>
          <Route path="/apps/TRAccountChecker/" element={<TRchecker />} />
        </Route>
        <Route element={<AccountCertificateTemp />}>
          <Route path="/apps/accountCertificate/" element={<AccountCertificate />} />
        </Route>
      </Routes>
    </>
  );
}

const TTTPayoutTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarPayout />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};
const TTTJournalitixTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarJournalytix />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const TTTActiveAccounts = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarTradovate />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};
const TTTUserActiveAccounts = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarUserAccounts />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};
const TTTTR = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarTargetReachedChecker />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};
const AccountCertificateTemp = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      // bgGradient={`linear(to-b, ${colors.ocean}, ${colors.darkblue})`}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarAccountCertificate />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

export default App;
