import {
  Flex,
  Heading,
  Input,
  Menu,
  MenuItem,
  Box,
  Button,
  MenuButton,
  MenuList,
  chakra,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Toast,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
} from "@chakra-ui/react";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { colors } from "../../../utils/colors";
import NewAdmin, { rolesOptions } from "../add-admin";
import { BiEdit, BiFilter, BiSearch, BiTrash } from "react-icons/bi";
// import {  } from "react-super-responsive-table";
import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useAuth } from "../../../utils/auth";
import Session from "supertokens-auth-react/recipe/session";
import SuperTokens from "supertokens-auth-react";
import axios from "axios";
import { client } from "../../../utils/axios";
export function AttemptRefresh(props) {
  React.useEffect(() => {
    let cancel = false;
    Session.attemptRefreshingSession().then((success) => {
      if (cancel) {
        // component has unmounted somehow..
        return;
      }
      if (success) {
        // we have new session tokens, so we redirect the user back
        // to where they were.
        const urlParams = new URLSearchParams(window.location.search);
        window.location.href = urlParams.get("redirectBack");
      } else {
        // we redirect to the login page since the user
        // is now logged out
        SuperTokens.redirectToAuth();
      }
    });
    return () => {
      cancel = true;
    };
  }, []);
  return null;
}

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

export default function ListAdmins() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isTrashOpen,
    onOpen: onTrashOpen,
    onClose: onTrashClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(true);

  let sessionContext = Session.useSessionContext();
  const [currentAdmin, setCurrentAdmin] = useState({});
  const toast = useToast();

  document.title = "Tick Tick Trader | Admins List";
  const auth = useAuth();

  const [admins, setadmins] = useState([]);
  useEffect(() => {
    client
      .get(`${process.env.REACT_APP_API_HOST}/admins`, {
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      })
      .then((res) => {
        //  console.log(res.data);
        setLoading(false);
        setadmins(res.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const hundleselectchange = (selectedOptions) => {
    setCurrentAdmin({
      ...currentAdmin,
      roles: [...selectedOptions.map((option) => option.value)],
    });
  };

  const refetchAdmins = async () => {
    setLoading(true);
    client
      .get(`${process.env.REACT_APP_API_HOST}/admins`, {
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      })
      .then((res) => {
        //  console.log(res.data);
        setadmins(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <VStack h="calc(100vh - 40px)" spacing={5} align="stretch">
      <Flex align="center" justify="space-between">
        <Text
          bgGradient="linear(to-l, gray.100, #ffff)"
          bgClip="text"
          fontSize="4xl"
          fontWeight="extrabold"
          color="black">
          Admins
        </Text>
        {/* <NewAdmin /> */}
      </Flex>

      {/*  <Flex justify="space-between">
        <Flex w="60%" justify="space-evenly">
          <Box w="70%">
            <Input
              type="text"
              placeholder="Search by email..."
              borderColor={colors.lightBlue}
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              bgColor="gray.100"
              borderRadius={20}
              boxShadow="lg"
              _placeholder={{
                opacity: 0.7,
                color: colors.ocean,
                fontSize: 14,
                fontStyle: "italic",
              }}

            />
          </Box>
          <Box w="10%">
            <Button
              borderRadius={50}
              fontSize="12px"
              bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              
            >
              <BiSearch size="15px" />
            </Button>
          </Box>
          <Box w="10%">
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<BiFilter size="20px" />}
                borderRadius={50}
                fontSize="12px"
                bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                boxShadow="lg"
                color="#ffff"
                _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              >
                Filter by
              </MenuButton>
              <MenuList color={colors.ocean} fontSize="14px">
                <MenuItem onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware:``,
                      });
                    }}
                >
                  Master Admin
                </MenuItem>
                <MenuItem onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware:`Bookmap`,
                      });
                    }}
                >
                  TTTBookmap Admin
                </MenuItem>
                <MenuItem onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware:`Jigsaw`,
                      });
                    }}
                >
                  TTTBlacklist Admin
                </MenuItem>
                <MenuItem onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware:`Ninja Trader`,
                      });
                    }}
                >
                  TTTSubs Admin
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Flex> */}

      <Box
        borderRadius={15}
        bgColor="gray.50"
        color="#000"
        h="calc(100vh - 100px)"
        // maxH={"1000px"}
        // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        overflow={`scroll`}>
        {loading ? (
          <Flex
            w={"full"}
            h={"full"}
            justifyContent={"center"}
            alignItems={"center"}>
            <Spinner
              thickness="80px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="2xl"
            />
          </Flex>
        ) : (
          <Table borderRadius={20} variant="striped">
            <Thead
              fontSize="15px"
              h="40px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              className="table__head">
              <Tr
                textAlign="center"
                className="table__head"
                textTransform="capitalize"
                h="30px"
                borderBottomWidth={1}
                borderRadius="20px">
                <Th color="#fff" textAlign={"center"}>
                  Username
                </Th>
                <Th color="#fff" textAlign={"center"}>
                  Email
                </Th>
                <Th color="#fff" textAlign={"center"}>
                  Roles
                </Th>
                <Th color="#fff" textAlign={"center"}>
                  Actions
                </Th>
                {/*<Th color='#fff' textAlign={'center'} >Action</Th>*/}
              </Tr>
            </Thead>

            <Tbody
              // bgGradient={`linear(to-l, gray.100, gray.300)`}
              color="black">
              {admins.map((admin, i) => (
                <Tr
                  textAlign="center"
                  fontSize={{
                    "2xl": "13px",
                    xl: "13px",
                    md: "12px",
                    lg: "12px",
                    sm: "10px",
                  }}
                  key={i}>
                  <Td textAlign={"center"} py="7px">
                    {admin.fullName}
                  </Td>
                  <Td textAlign={"center"}>{admin.email} </Td>
                  <Td textAlign={"center"}>
                    {admin.roles.join(", ") !== ""
                      ? admin.roles.join(", ")
                      : "None"}
                  </Td>

                  <Td textAlign={"center"}>
                    {sessionContext ||
                    sessionContext?.accessTokenPayload ||
                    sessionContext.accessTokenPayload["st-role"].v.includes(
                      "MASTER"
                    ) ? (
                      <Flex justify="space-evenly" align="center">
                        <Popover>
                          <PopoverTrigger>
                            <Button
                              bgColor="transparent"
                              color="green.600"
                              align="center"
                              _hover={{ cursor: "pointer", color: "green.500" }}
                              onClick={() => {
                                onOpen();
                                setCurrentAdmin(admin);
                                console.log(admin);
                              }}>
                              <BiEdit size="20px" />
                            </Button>
                          </PopoverTrigger>
                        </Popover>
                        <Popover>
                          <PopoverTrigger>
                            <Button
                              bgColor="transparent"
                              color="red.600"
                              align="center"
                              _hover={{ cursor: "pointer", color: "red.500" }}
                              onClick={() => {
                                onTrashOpen();
                                setCurrentAdmin(admin);
                                console.log(admin);
                              }}>
                              <BiTrash size="20px" />
                            </Button>
                          </PopoverTrigger>
                        </Popover>
                      </Flex>
                    ) : (
                      <Flex justify="space-evenly" align="center">
                        <Text>-</Text>
                      </Flex>
                    )}
                  </Td>
                  {/*<Td textAlign={"center"}>
                  <Flex justify="space-evenly" align="center">
                    <Popover>
                      <PopoverTrigger>
                        <Button
                          bgColor="transparent"
                          color="red.600"
                          align="center"
                          _hover={{ cursor: "pointer", color: "red.500" }}
                        >
                          <BiTrash size="20px" />
                        </Button>
                      </PopoverTrigger>

                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader
                          fontSize="16px"
                          color="red.500"
                          fontWeight={`bold`}
                        >
                          Confirmation!
                        </PopoverHeader>
                        <PopoverBody>
                          <Text size="12px" pb="10px">
                            Are you sure you want to delete this subscription?
                          </Text>
                          <Button
                            size="sm"
                            bgGradient={`linear(to-b, red.300, red.500)`}
                            color="gray.50"
                            _hover={{
                              bgGradient: `linear(to-t, red.300, red.500)`,
                            }}
                            boxShadow="lg"
                             onClick={async () => {
                  try {
                    await deleteSubscription(subscription?._id);
                    await refetchSubscriptions();
                    toast({
                      position: "bottom",
                      duration: 5 * 1000,
                      status: "success",
                      render: () => (
                        <Box
                          bgColor="red.500"
                          borderRadius="5px"
                          color="#f5f5f5"
                          p="10px"
                          px="10px"
                          textAlign="center"
                        >
                          <Heading fontSize="sm">Subscription deleted !!</Heading>
                        </Box>
                      ),
                    });
                  } catch (error) {
                    console.dir("error", error);
                  }
                }} 
                          >
                            Yes, I'm sure
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                </Td>*/}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>assign Role</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to update this admin?
            <br />
            <br />
            {/* <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                value={currentAdmin?.username}
                onChange={(e) =>
                  setCurrentAdmin({
                    ...currentAdmin,
                    username: e.target.value,
                  })
                }
              />
            </FormControl> */}
            <br />
            {/* <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={currentAdmin?.email}
                onChange={(e) =>
                  setCurrentAdmin({ ...currentAdmin, email: e.target.value })
                }
              />
            </FormControl> 
            <br />
              <br /> */}
            {/* <FormControl> */}
            {/* <FormLabel>Password</FormLabel>
              <Input
                type="email"
                value={currentAdmin?.password}
                onChange={(e) =>
                  setCurrentAdmin({ ...currentAdmin, password: e.target.value })
                }
              />
            </FormControl> */}
            <br />
            <FormControl>
              <FormLabel>Roles</FormLabel>
              <Select
                isMulti={true}
                value={
                  currentAdmin?.roles?.map((role) => ({
                    value: role,
                    label: role,
                  })) || []
                }
                name="roles"
                onChange={hundleselectchange}
                options={rolesOptions}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                try {
                  await client
                    .put(
                      `${process.env.REACT_APP_API_HOST}/admin/assignRoles/${currentAdmin.userId}`,
                      {
                        // id: currentAdmin._id,
                        // username: currentAdmin.username,
                        // email: currentAdmin.email,
                        // // password: currentAdmin.password,
                        roles: currentAdmin.roles,
                      }
                    )
                    .then((res) => {
                      console.log(res);
                      refetchAdmins();
                      onClose();

                      toast({
                        position: "bottom",
                        duration: 5 * 1000,
                        status: "success",
                        render: () => (
                          <Box
                            bgColor="green.500"
                            borderRadius="5px"
                            color="#f5f5f5"
                            p="10px"
                            px="10px"
                            textAlign="center">
                            <Heading fontSize="sm">Admin updated !!</Heading>
                          </Box>
                        ),
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                      toast({
                        position: "bottom",
                        duration: 5 * 1000,
                        status: "error",
                        render: () => (
                          <Box
                            bgColor="red.500"
                            borderRadius="5px"
                            color="#f5f5f5"
                            p="10px"
                            px="10px"
                            textAlign="center">
                            <Heading fontSize="sm">
                              {err?.response?.data?.details
                                ?.map((e) => e?.message)
                                .join(", ")}
                            </Heading>
                          </Box>
                        ),
                      });
                    });
                } catch (error) {
                  console.dir("error", error);
                }
              }}>
              Update
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onClose();
              }}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isTrashOpen} onClose={onTrashClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Admin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to Delete this admin?
            <br />
            <br />
            {/* <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                value={currentAdmin?.username}
                onChange={(e) =>
                  setCurrentAdmin({
                    ...currentAdmin,
                    username: e.target.value,
                  })
                }
              />
            </FormControl> */}
            <br />
            {/* <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={currentAdmin?.email}
                onChange={(e) =>
                  setCurrentAdmin({ ...currentAdmin, email: e.target.value })
                }
              />
            </FormControl> 
            <br />
              <br /> */}
            {/* <FormControl> */}
            {/* <FormLabel>Password</FormLabel>
              <Input
                type="email"
                value={currentAdmin?.password}
                onChange={(e) =>
                  setCurrentAdmin({ ...currentAdmin, password: e.target.value })
                }
              />
            </FormControl> */}
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                try {
                  await client
                    .delete(
                      `${process.env.REACT_APP_API_HOST}/admin/deleteAdmin/${currentAdmin.userId}`,
                      {
                        // id: currentAdmin._id,
                        // username: currentAdmin.username,
                        // email: currentAdmin.email,
                        // // password: currentAdmin.password,
                        roles: currentAdmin.roles,
                      }
                    )
                    .then((res) => {
                      console.log(res);
                      refetchAdmins();
                      onTrashClose();

                      toast({
                        position: "bottom",
                        duration: 5 * 1000,
                        status: "success",
                        render: () => (
                          <Box
                            bgColor="green.500"
                            borderRadius="5px"
                            color="#f5f5f5"
                            p="10px"
                            px="10px"
                            textAlign="center">
                            <Heading fontSize="sm">Admin Deleted !!</Heading>
                          </Box>
                        ),
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                      toast({
                        position: "bottom",
                        duration: 5 * 1000,
                        status: "error",
                        render: () => (
                          <Box
                            bgColor="red.500"
                            borderRadius="5px"
                            color="#f5f5f5"
                            p="10px"
                            px="10px"
                            textAlign="center">
                            <Heading fontSize="sm">
                              {err?.response?.data?.details
                                ?.map((e) => e?.message)
                                .join(", ")}
                            </Heading>
                          </Box>
                        ),
                      });
                    });
                } catch (error) {
                  console.dir("error", error);
                }
              }}>
              Delete
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onClose();
              }}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
