import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery } from "react-query";

const getConsistencyList = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/funded/consistency?${query}`,
    {
      method: "GET",
    }
  );
  return data;
};

const useConsistencyList = (query) => {
  const auth = useAuth();
  return {
    constistencyList: useQuery(
      ["constistencyList", query],
      getConsistencyList(query, auth),
      {
        staleTime: ms("1s"),
        refetchInterval: ms("5m"),
        refetchOnMount: "always",
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchConsistenctList: () => getConsistencyList(query, auth),
  };
};

export default useConsistencyList;
