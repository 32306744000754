import { useState, useRef, useEffect } from "react";
import { Button, useToast, Heading, Text, Spinner } from "@chakra-ui/react";
import { client } from "../../utils/axios";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import {
  Box,
  Flex,
  Input,
  chakra,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
} from "@chakra-ui/react";
import React from "react";

import { BiSearch } from "react-icons/bi";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
//   import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../node_modules/react-super-responsive-table/dist/index";
import usePostActiveAccounts from "../../hooks/useMutations/usePostActiveAccounts";

import { colors } from "../../utils/colors";

import "./style.css";
// import SingleCoupon from "./SingleCoupon";

const headers = [
  { label: "Account name", key: "name" },
  { label: "first Name", key: "user.name_f" },
  { label: "Last Name", key: "user.name_l" },
  { label: "Email", key: "user.email" },
  { label: "Phone", key: "user.phone" },
  { label: "UserId", key: "user.user_id" },
  { label: "Tradovate ID", key: "user.tradovate_id" },
];

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

const UserActiveAccount = () => {
  const [csvReport, setSCVReport] = useState({ data: [] });
  // let csvReport = {};
  const [exportCheck, setExportCheck] = useState(false);
  const [importDone, setImportDone] = useState(false);
  //   const toast = useToast();
  //   const [exportCheck, setExportCheck] = useState(false);
  const clickRef = useRef();
  useEffect(() => {
    if (exportCheck)
      if (csvReport?.data.length > 0) {
        clickRef.current.link.click();
        setExportCheck(false);
      }
  }, [csvReport]);

  const exportReport = async () => {
    setExportCheck(true);
    await client
      .get(`${process.env.REACT_APP_TRADOVATE_URL}/getActiveAccounts/`, {
        headers: {
          "x-api-key": process.env.REACT_APP_TV_API_KEY,
        },
      })
      .then((res) => {
        setSCVReport({
          data: res?.data?.map((e) => e),
          headers: headers,
          filename: `Bookmap Licenses - ${new Date()}.csv`,
        });
        // csvReport = {
        //   data: res?.data?.map((e) => e),
        //   headers: headers,
        //   filename: `Bookmap Licenses - ${new Date()}.csv`,
        // if (res.data.length > 0)
        // if (csvReport?.data.length > 0) clickRef.current.link.click();
        // };
      });
    // <CSVLink {...csvReport}>
    // clickRef.current = null;
    // setExportCheck(false);
    //   <button>Export CSV</button>
    // </CSVLink>;

    // const csvExporter = new CSVExporter();
    // csvExporter.export(csvReport.data); // Pass your data to the exporter
    // csvExporter.download("data.csv"); //
  };

  const exportData = (data, filename, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const importData = async () => {
    setImportDone(true);
    toast({
      position: "top",
      duration: 5 * 1000,
      status: "info",
      render: () => (
        <Box
          bgColor="blue.400"
          borderRadius="5px"
          color="#f5f5f5"
          p="20px"
          textAlign="center">
          <Heading fontSize="sm" textTransform="uppercase">
            Active Account Data is being imported, Please Wait it will take some
            minutes
          </Heading>
        </Box>
      ),
    });

    client
      .get(`${process.env.REACT_APP_PORTAL_URL}/getActiveAccounts/`, {
        headers: {
          "x-api-key": process.env.REACT_APP_TV_API_KEY,
        },
      })
      .then((res) => {
        setImportDone(false);
        // const csvData = Papa.unparse(res.data.data);

        let csvData1 = res.data.data.map((data) => {
          return {
            "Account name": data.name,
            "first Name": data.user.name_f,
            "Last Name": data.user.name_l,
            Email: data.user.email,
            Phone: data.user.phone,
            UserId: data.user.user_id,
            "Tradovate ID": data.user.tradovate_id,
          };
        });
        let csvData2 = Papa.unparse(csvData1);
        exportData(csvData2, "ActiveAccount.csv", "text/csv;charset=utf-8;");
        toast({
          position: "top",
          duration: 5 * 1000,
          status: "success",
          render: () => (
            <Box
              bgColor="green.400"
              borderRadius="5px"
              color="#f5f5f5"
              p="20px"
              textAlign="center">
              <Heading fontSize="sm" textTransform="uppercase">
                Active Accounts Data is imported
              </Heading>
            </Box>
          ),
        });
      });

    // console.log("res import = ", res);
    // // refetchJounalytixList();
    // setSCVReport({
    //   data: res?.data?.data?.map((e) => e),
    //   headers: headers,
    //   filename: `Active Accounts - ${new Date()}.csv`,
    // });
    // setImportDone(false);
    // if (res.status === 200)
    //   toast({
    //     position: "top",
    //     duration: 5 * 1000,
    //     status: "success",
    //     render: () => (
    //       <Box
    //         bgColor="green.400"
    //         borderRadius="5px"
    //         color="#f5f5f5"
    //         p="20px"
    //         textAlign="center">
    //         <Heading fontSize="sm" textTransform="uppercase">
    //           Active Accounts Data is imported
    //         </Heading>
    //       </Box>
    //     ),
    //   });
  };
  //   const [searchBy, setSearchBy] = useState("");
  //   const [search, setSearch] = useState("");
  //   const [coupon, setCoupon] = useState("");
  const [email, setEmail] = useState("");
  //   const [page, setPage] = useState(1);
  //   const [limit, setLimit] = useState(10);

  const { isOpen, onOpen, onClose } = useDisclosure();
  //   const query = useRecoilValue(filterCouponslistSelector);

  const { mutate, isLoading, data, error } = usePostActiveAccounts();

  //   const [filterCouponlist, setFilterCouponlist] = useRecoilState(
  //     filterCouponlistState
  //   );

  //   const { coupons, refetchCouponlists } = useCouponlists(
  //     query + `&page=${page}&limit=${limit}`
  //   );
  //   const auth = useAuth()?.user;
  // const roles = auth?.role;
  //   const roles = useSessionContext().accessTokenPayload["st-role"]?.v;

  //   useEffect(() => {
  //     return () => {
  //       setSearch("");
  //       setFilterCouponlist({
  //         ...filterCouponlist,
  //         coupon: "",
  //         email: "",
  //       });
  //       refetchCouponlists();
  //     };
  //   }, []);
  //   const { deleteCouponlist } = useCouponlistMutation();

  let toast = useToast();
  document.title = "TTTSubscriptions | Coupons List";
  console.log("data = ", data);

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Flex align="center" justify="space-evenly" pb="40px">
          <Flex flexDir={"column"}>
            {/* <Box>
              <Heading size="lg">Email</Heading>
            </Box>
            <Box>
              <Heading size="lg">Coupons</Heading>
            </Box> */}
          </Flex>
          <Flex align="center" className="coupon" justify={`center`} w="100%">
            {/* <Box w="90%">
              <Input
                type="text"
                placeholder={`Search by email...`}
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  setSearchBy("email");
                  setSearch(e.target.value);
                }}
              />
            </Box> */}
            <Box w="90%">
              <Input
                type="text"
                placeholder={`Search by Email...`}
                borderColor={colors.blue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => {
                  //   setSearchBy("coupon");
                  setEmail(e.target.value);
                }}
              />
            </Box>
          </Flex>
          <Box w="50%" ml={2}>
            <Button
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              className="app_btn"
              color="#ffff"
              width={"30%"}
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              isDisabled={!!email ? false : true}
              onClick={() => {
                mutate(email);
              }}>
              <BiSearch size="20px" />
            </Button>
          </Box>

          {/* <Button
            padding={"10px 30px"}
            rightIcon={<AiOutlineEye size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn"
            onClick={() => {
            
            }}>
            View all
          </Button> */}
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px">
          {data?.rithmic_id.length > 0 ||
          data?.cqg_id.length > 0 ||
          data?.tradovate_id.length > 0 ? (
            <CustomTable borderRadius={20}>
              <CustomThead
                fontSize="15px"
                h="40px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              >
                <CustomTr
                  textAlign="center"
                  textTransform="capitalize"
                  h="30px"
                  className="table__head"
                  borderBottomWidth={1}
                  borderRadius="20px">
                  <CustomTh>Account id</CustomTh>
                  <CustomTh>Provider</CustomTh>
                  {/* <CustomTh>Action</CustomTh> */}
                </CustomTr>
              </CustomThead>

              <CustomTbody
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color={colors.ocean}>
                {data?.rithmic_id.map((id, i) => (
                  <CustomTr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    {/* <CustomTd>{coupon.email} </CustomTd> */}
                    <CustomTd>{id} </CustomTd>
                    <CustomTd>{"RITHMIC"}</CustomTd>
                  </CustomTr>
                ))}
                {data?.cqg_id?.map((id, i) => (
                  <CustomTr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    {/* <CustomTd>{coupon.email} </CustomTd> */}
                    <CustomTd>{id} </CustomTd>
                    <CustomTd>{"CQG"}</CustomTd>
                  </CustomTr>
                ))}
                {data?.tradovate_id?.map((id, i) => (
                  <CustomTr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    {/* <CustomTd>{coupon.email} </CustomTd> */}
                    <CustomTd>{id} </CustomTd>
                    <CustomTd>{"TRADOVATE"}</CustomTd>
                  </CustomTr>
                ))}
              </CustomTbody>
            </CustomTable>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}>
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>

      {/* <Flex ml="7%" mt={5}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<BiFilter size="20px" />}
            borderRadius={50}
            fontSize="12px"
            // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            className="app_btn">
            Per Page: {limit}
          </MenuButton>
          <MenuList color={colors.ocean} fontSize="14px">
            <MenuItem onClick={() => setLimit(10)}>10</MenuItem>
            <MenuItem onClick={() => setLimit(15)}>15</MenuItem>
            <MenuItem onClick={() => setLimit(20)}>20</MenuItem>
            <MenuItem onClick={() => setLimit(25)}>25</MenuItem>
            <MenuItem onClick={() => setLimit(30)}>30</MenuItem>
            <MenuItem onClick={() => setLimit(35)}>35</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex align="center" marginTop={-10} justify="center">
        <Pagination1
          pagesQuantity={Math.ceil(email?.total / limit)}
          curPage={page}
          setCurPage={setPage}
        />
      </Flex> */}

      <Modal
        isOpen={isOpen}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: "6xl",
        }}
        onClose={() => {
          onClose();
          setEmail("");
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">Coupon Detail {email}</ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody>{/* <SingleCoupon coupon={coupon} /> */}</ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default UserActiveAccount;
