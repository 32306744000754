import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery } from "react-query";

const getPayments = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/admin/payments?${query}`,
    {
      method: "GET",
    }
  );
  return data;
};

const usePaymentslists = (query) => {
  // const queryClient = useQueryClient();
  const auth = useAuth();

  return {
    payments: useQuery(["payments", query], getPayments(query, auth), {
      staleTime: ms("1s"),
      refetchInterval: ms("5m"),
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      retry: false,
    }).data,
    refetchPaymentslists: () => getPayments(query, auth),
  };
};

export default usePaymentslists;
