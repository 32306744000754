import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  Badge,
  Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { colors } from "../../utils/colors";
import { BiSearch, BiTrash } from "react-icons/bi";
// import {  } from "react-super-responsive-table";
// import "../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useBlacklist from "../../hooks/useQueries/useBlacklist";
import { usePayoutHistory } from "../../hooks/useQueries/usePayoutlist";
import useBlacklistMutation from "../../hooks/useMutations/useBlacklistMutation";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  filterPayoutHistoryState,
  filterPayoutHistorySelector,
} from "../../recoil";
// import BlacklistedReason from "./reason";
import { BiFilter } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import Pagination1 from "../../utils/pagination";
import { useAuth } from "../../utils/auth";
import moment from "moment";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

export default function HistoryPayout() {
  const [searchBy, setSearchBy] = useState("email");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const query = useRecoilValue(filterPayoutHistorySelector);
  //   const query = useRecoilValue(filterPayoutHistorySelector);
  const [filterPayoutHistory, setFilterPayoutHistory] = useRecoilState(
    filterPayoutHistoryState
  );
  const [fullName, setFullName] = useState("");

  const { payoutHistory, refetchPayoutHisotry } = usePayoutHistory(
    query + `&page=${page}&limit=${limit}`
  );
  const auth = useAuth()?.user;
  const roles = auth?.roles;
  useEffect(() => {
    return () => {
      setSearch("");
      setFilterPayoutHistory({
        ...filterPayoutHistory,
        fName: ``,
        email: ``,
        search: ``,
        action: ``,
      });
      refetchPayoutHisotry();
    };
  }, []);
  // const { deleteBlacklist } = useBlacklistMutation();

  let toast = useToast();
  document.title = "TTTBlacklist | Blacklist";

  console.log("test query", query);
  // console.log(search);

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Flex align="center" justify="space-between" pb="40px">
          <Box w="20%">
            <Heading size="lg">History of Activities</Heading>
          </Box>
          <Flex align="center" justify={`center`} w="100%">
            <Box w="20%">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiFilter size="20px" />}
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  boxShadow="lg"
                  color="#ffff"
                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  className="app_btn"
                  >
                  Search by: {searchBy}
                </MenuButton>
                <MenuList color={colors.ocean} fontSize="14px">
                  <MenuItem onClick={() => setSearchBy("email")}>
                    Admin Email
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      // console.log("search", searchBy);
                      setSearchBy("ID/PASSPORT");
                      console.log("search", searchBy);
                    }}>
                    ID or Passport
                  </MenuItem> */}
                  {/* <MenuItem
                    onClick={() => {
                      // console.log("search", searchBy);
                      setSearchBy("phone");
                      console.log("search", searchBy);
                    }}>
                    Phone
                  </MenuItem> */}
                </MenuList>
              </Menu>
            </Box>

            <Box w="70%">
              <Input
                type="text"
                placeholder={`Search by ${searchBy}...`}
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.blue,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>

            <Box w="10%" ml="5px">
              {searchBy === "fName" ? (
                <Button
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  isDisabled={!!search ? false : true}
                  onClick={() => {
                    setFilterPayoutHistory({
                      ...filterPayoutHistory,
                      fName: `type=fName&search=${search}`,
                      email: ``,
                    });
                  }}>
                  <BiSearch size="15px" />
                </Button>
              ) : searchBy === "email" ? (
                <Button
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  isDisabled={!!search ? false : true}
                  onClick={() => {
                    setFilterPayoutHistory({
                      ...filterPayoutHistory,
                      email: `${search}`,
                      fName: ``,
                    });
                  }}>
                  <BiSearch size="15px" />
                </Button>
              ) : searchBy === "ID/PASSPORT" ? (
                <Button
                borderRadius={50}
                fontSize="12px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="app_btn"
                boxShadow="lg"
                color="#ffff"
                // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                isDisabled={!!search ? false : true}
                onClick={() => {
                  setFilterPayoutHistory({
                    ...filterPayoutHistory,
                    email: `type=ID/PASSPORT&search=${search}`,
                    fName: ``,
                  });
                }}>
                  <BiSearch size="15px" />
                </Button>
              ) : (
                <Button
                borderRadius={50}
                fontSize="12px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  isDisabled={!!search ? false : true}
                  onClick={() => {
                    setFilterPayoutHistory({
                      ...filterPayoutHistory,
                      email: `type=phone&search=${search}`,
                      fName: ``,
                    });
                  }}>
                  <BiSearch size="15px" />
                </Button>
              )}
            </Box>
            <Box w="20%" mr="15px">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiFilter size="20px" />}
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  boxShadow="lg"
                  color="#ffff"
                  className="app_btn"
                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  >
                  Filter by
                </MenuButton>
                <MenuList color={colors.ocean} fontSize="14px">
                  <MenuItem
                    onClick={() => {
                      setFilterPayoutHistory({
                        ...filterPayoutHistory,
                        fname: ``,
                        email: ``,
                        search: ``,
                        action: ``,
                      });
                    }}>
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterPayoutHistory({
                        ...filterPayoutHistory,
                        action: "create",
                      });
                    }}>
                    Create
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterPayoutHistory({
                        ...filterPayoutHistory,
                        action: "update",
                      });
                    }}>
                    Update
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterPayoutHistory({
                        ...filterPayoutHistory,
                        action: "delete",
                      });
                      // refetchPayoutHisotry();
                    }}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Button
              rightIcon={<AiOutlineEye size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"
              px={6}
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setFilterPayoutHistory({
                  ...filterPayoutHistory,
                  fName: ``,
                  email: ``,
                  search: ``,
                  action: ``,
                });
              }}>
              View all
            </Button>
          </Flex>
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px">
          {payoutHistory?.docs?.length > 0 ? (
            <Table borderRadius={20} variant='striped'>
              <Thead
                fontSize="15px"
                h="40px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="table__head"

                >
                <Tr
                  textAlign="center"
                  textTransform="capitalize"
                  className="table__head"
                  h="30px"
                  borderBottomWidth={1}
                  borderRadius="20px">
                  <Th color='#fff'>Full Name</Th>
                  <Th color='#fff'>Email</Th>
                  <Th color='#fff'>Action</Th>
                  <Th color='#fff'>Content</Th>
                  <Th color='#fff'>Payout ID</Th>
                  <Th color='#fff'>Date</Th>
                  {/* <Th>More</Th>
                  <Th>Action</Th> * */}
                </Tr>
              </Thead>

              <Tbody
                // bgGradient={`linear(to-l, gray.100, gray.300)`}
                color='balck'
                >
                {payoutHistory?.docs?.map((hisotry, i) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                    >
                    <Td py="7px">{hisotry?.fullName}</Td>
                    <Td>{hisotry?.email} </Td>
                    <Td>
                      {" "}
                      <Badge
                        variant={"solid"}
                        colorScheme={
                          hisotry?.action === "CREATE"
                            ? "green"
                            : hisotry?.action === "UPDATE"
                            ? "orange"
                            : "red"
                        }>
                        {hisotry?.action}
                      </Badge>
                    </Td>
                    <Td>{hisotry?.content}</Td>
                    <Td>{hisotry?.payoutId}</Td>
                    <Td>
                      {moment(hisotry?.createdAt).fromNow(true)} ago
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}>
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>
      <Pagination1
        pagesQuantity={payoutHistory?.totalPages}
        curPage={page}
        setCurPage={setPage}
      />
    </Flex>
  );
}
