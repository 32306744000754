import { Flex, Box, Image, Heading } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
// import logo from "../../assets/logo.png";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";
import TTTSubs from "../../assets/images/subscriptions.svg";
import { BiMessageSquareAdd, BiTable, BiHome, BiStats } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router";
import subscriptions from "../../assets/appLogos/Subscriptions.webp";
import { AiOutlineDashboard } from "react-icons/ai";

export default function NavbarSubs() {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  return (
    <Flex
      justify={`space-between`}
      align="center"
      h="70px"
      w="100%"
      boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
      color={"#000"}
      bg="#fff"
      px="20px">
      <Flex w="20%">
        <Image src={subscriptions} rounded={"xl"} h="60px" w="auto" />
        <Image src={logo} h="60px" w="auto" onClick={() => navigate("/apps")} />
        <Image
          src={logo1}
          h="60px"
          w="auto"
          onClick={() => navigate("/apps")}
        />
      </Flex>
      <Flex justify={`space-around`} align="center" color="gray.50" w="60%">
        <Flex
          className="nav__bar__link"
          onClick={() => navigate("/apps")}
          bgColor={location === "/apps" && "gray.50"}
          color={location === "/apps" && colors.ocean}
          cursor="pointer"
          boxShadow={location === "/apps" && "lg"}>
          <Box>
            <BiHome
              color={location === "/apps" ? "white" : "black"}
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading color={location === "/apps" ? "white" : "black"} size="xs">
              &nbsp; Apps
            </Heading>
          </Box>
        </Flex>

        <Flex
          cursor="pointer"
          onClick={() => navigate("/apps/tttsubscriptions")}
          className={`${
            location === "/apps/tttsubscriptions"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          boxShadow={location === "/apps/tttsubscriptions" && "lg"}>
          <Box>
            <BiStats
              color={location === "/apps/tttsubscriptions" ? "white" : "black"}
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              color={location === "/apps/tttsubscriptions" ? "white" : "black"}
              size="xs">
              &nbsp; Stats
            </Heading>
          </Box>
        </Flex>

        <Flex
          onClick={() => navigate("/apps/tttsubscriptions/add-subscription")}
          cursor="pointer"
          className={`${
            location === "/apps/tttsubscriptions/add-subscription"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}>
          <Box>
            <BiMessageSquareAdd
              color={
                location === "/apps/tttsubscriptions/add-subscription"
                  ? "white"
                  : "black"
              }
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              size="xs"
              color={
                location === "/apps/tttsubscriptions/add-subscription"
                  ? "white"
                  : "black"
              }>
              &nbsp; Add Subscription
            </Heading>
          </Box>
        </Flex>
        <Flex
          className={`${
            location === "/apps/tttsubscriptions/list-subscription"
              ? "sidebar_link_active"
              : "nav__bar__link"
          }`}
          onClick={() => navigate("/apps/tttsubscriptions/list-subscription")}
          cursor="pointer">
          <Box>
            <BiTable
              color={
                location === "/apps/tttsubscriptions/list-subscription"
                  ? "white"
                  : "black"
              }
              size="20px"
            />
          </Box>
          <Box mt="2px">
            <Heading
              size="xs"
              color={
                location === "/apps/tttsubscriptions/list-subscription"
                  ? "white"
                  : "black"
              }>
              &nbsp; Subscriptions
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
