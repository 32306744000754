import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const useBlacklistMutation = () => {
  const auth = useAuth();
  const { mutateAsync: addBlacklist } = useMutation((data) =>
    client.post(
      `${process.env.REACT_APP_API_HOST}/blacklists`,
      { ...data },
      {
        method: "GET",
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      }
    )
  );

  const { mutateAsync: deleteBlacklist } = useMutation((id) =>
    client.delete(`${process.env.REACT_APP_API_HOST}/blacklists/${id}`, {
      method: "GET",
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    })
  );

  return { addBlacklist, deleteBlacklist };
};

export default useBlacklistMutation;
