import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Tooltip,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import { AiOutlineEye } from "react-icons/ai";
import { colors } from "../../../../utils/colors";
import moment from "moment";
function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

export default function BlacklistedReason({ blacklisted }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log("black date")
  return (
    <>
      <Tooltip label="Read reason" placement="auto">
        <Box
          color={colors.ocean}
          align="center"
          _hover={{ cursor: "pointer", color: colors.lightBlue }}
        >
          <AiOutlineEye size="20px" onClick={onOpen} />
        </Box>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={18}>Reason of blacklisting</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack
              color="gray.50"
              spacing={1}
              align="stretch"
              borderRadius={15}
              p="10px"
              boxShadow={"lg"}
              className="sidebar_nav"
            >
              <HStack spacing={3} align="stretch" fontSize={13}>
                <Text fontWeight={`bold`} color="black">
                  Full name:
                </Text>
                <Text color="black">{blacklisted.fName}</Text>
              </HStack>
              <HStack color="black" spacing={3} align="stretch" fontSize={13}>
                <Text fontWeight={`bold`}>Email:</Text>
                <Text>{blacklisted.email}</Text>
              </HStack>
              <HStack color="black" spacing={3} align="stretch" fontSize={13}>
                <Text fontWeight={`bold`}>Phone:</Text>
                <Text>{blacklisted.phone}</Text>
              </HStack>
              <HStack spacing={3} align="stretch" fontSize={13}>
                <Text color="black" fontWeight={`bold`}>
                  ID or Passport:
                </Text>
                <Text color="red.600">
                  {blacklisted.cin_passport || "Not Set"}
                </Text>
              </HStack>
              <HStack spacing={3} align="stretch" fontSize={13}>
                <Text color="black" fontWeight={`bold`}>
                  Type:
                </Text>
                <Text color="red.500">
                  {blacklisted.isBlacklisted === false
                    ? "Cooldown"
                    : "Blacklisted"}
                </Text>
              </HStack>
              {blacklisted.isBlacklisted === false && (
                <HStack spacing={3} align="stretch" fontSize={13}>
                  <Text color="black" fontWeight={`bold`}>
                    Cooldown End Date :
                  </Text>
                  <Text color="red.500">
                    {blacklisted.endDate !== null
                      ? moment(blacklisted.endDate).format("YYYY-MM-DD")
                      : "No Date"}
                  </Text>
                </HStack>
              )}
              <HStack spacing={3} align="stretch" fontSize={13}>
                <Text color="black" fontWeight={`bold`}>
                  Reason:
                </Text>
                <Text color="red.500">{blacklisted.motif}</Text>
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button className="app_btn" onClick={onClose} size="sm">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
