import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  VStack,
  Input,
  Flex,
  Select,
  Button,
  Heading,
  useToast,
  Box,
  Text,
  Badge,
  chakra,
} from "@chakra-ui/react";
import { colors } from "../../utils/colors";
import DatePicker from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./datepicker1.css";
import useSubscriptionMutation from "../../hooks/useMutations/useSubscriptionMutation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useSubscriptions from "../../hooks/useQueries/useSubscriptions";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const schema = yup.object().shape({
  /* fName: yup.string().required("First Name Required"),
  lName: yup.string().required("Last Name Required"), */
  accountName: yup.string().required("!! Account Name is a required field."),
  tradingSoftware: yup
    .string()
    .required(" !! Trading Software is a required field."),
});

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

export default function AddSubscription() {
  const [date, setDate] = useState(new Date());
  const [accountName, setAccountName] = useState("");
  const auth = useAuth();
  const { addSubscription } = useSubscriptionMutation();
  const { refetchSubscriptions } = useSubscriptions();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await addSubscription({
        date: date.toISOString().split("T")[0],
        systemDate: new Date().toISOString().split("T")[0],
        /* fName: data.fName,
        lName: data.lName, */
        accountName: data.accountName,
        tradingSoftware: data.tradingSoftware,
      });
      await refetchSubscriptions();
      reset();

      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="yellow.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center"
          >
            <Heading fontSize="sm" textTransform="uppercase">
              Subscription added succesfully !
            </Heading>
          </Box>
        ),
      });
      clearErrors();
    } catch (error) {
      /* if (error.response.data.error.message.includes("Duplicate")) {
        toast({
          position: "bottom-right",
          duration: 5 * 1000,
          status: "success",
          render: () =>
            <Box
              bgColor="red.500"
              borderRadius="5px"
              color="#f5f5f5"
              p="10px"
              textAlign="center"
            >
              <Heading fontSize="xs" textTransform="uppercase">
                Une facture avec ce Numéro existe déjà !!
              </Heading>
            </Box>
        });
      } */
      console.dir(error);
    }
  };

  const onSubmitCheck = async (data) => {
    const result = await client.get(
      `${process.env.REACT_APP_API_HOST}/subscriptions?type=accounts&search=${data.accountName}`,
      {
        // method: "GET",
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      }
    );
    if (result.data.docs.length === 0) {
      try {
        await addSubscription({
          date: date.toISOString().split("T")[0],
          systemDate: new Date().toISOString().split("T")[0],
          /* fName: data.fName,
          lName: data.lName, */
          accountName: data.accountName,
          tradingSoftware: data.tradingSoftware,
        });
        await refetchSubscriptions();
        reset();

        toast({
          position: "top",
          duration: 5 * 1000,
          status: "success",
          render: () => (
            <Box
              bgColor="yellow.400"
              borderRadius="5px"
              color="#f5f5f5"
              p="20px"
              textAlign="center"
            >
              <Heading fontSize="sm" textTransform="uppercase">
                Subscription added succesfully !
              </Heading>
            </Box>
          ),
        });
        clearErrors();
      } catch (error) {
        /* if (error.response.data.error.message.includes("Duplicate")) {
          toast({
            position: "bottom-right",
            duration: 5 * 1000,
            status: "success",
            render: () =>
              <Box
                bgColor="red.500"
                borderRadius="5px"
                color="#f5f5f5"
                p="10px"
                textAlign="center"
              >
                <Heading fontSize="xs" textTransform="uppercase">
                  Une facture avec ce Numéro existe déjà !!
                </Heading>
              </Box>
          });
        } */
        console.dir(error);
      }
    } else {
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgGradient={`linear(to-l, gray.100, gray.300)`}
            color={colors.ocean}
            borderRadius="5px"
            p="10px"
            textAlign="center"
          >
            <Box pb="10px">
              <Heading fontSize="md" textTransform="uppercase" py="10px">
                Confirmation
              </Heading>

              <Text fontSize={12} pb="10px">
                This account had already one/many subscriptions! <br />
                Are you sure you want to create a new one ?
              </Text>

              {result?.data.length > 0 && (
                <CustomTable borderRadius={20}>
                  <CustomThead
                    fontSize="12px"
                    h="20px"
                    bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  >
                    <CustomTr
                      textAlign="center"
                      textTransform="capitalize"
                      h="20px"
                      borderBottomWidth={1}
                      borderRadius="20px"
                      color="gray.900"
                    >
                      <CustomTh>Trading software</CustomTh>
                      <CustomTh>Delivery Date</CustomTh>
                    </CustomTr>
                  </CustomThead>

                  <CustomTbody
                    bgGradient={`linear(to-l, gray.100, gray.300)`}
                    color={colors.ocean}
                  >
                    {result?.data.map((res, i) => (
                      <CustomTr
                        textAlign="center"
                        fontSize={{
                          "2xl": "13px",
                          xl: "13px",
                          md: "12px",
                          lg: "12px",
                          sm: "10px",
                        }}
                        key={i}
                        borderBottom="1px"
                        borderColor={colors.lightBlue}
                      >
                        <CustomTd>{res.tradingSoftware} </CustomTd>
                        <CustomTd>{res.date.slice(0, 10)}</CustomTd>
                      </CustomTr>
                    ))}
                  </CustomTbody>
                </CustomTable>
              )}
            </Box>

            <Button
              size="sm"
              borderRadius={15}
              fontSize="12px"
              bgGradient={`linear(to-b, yellow.400, yellow.500)`}
              boxShadow="lg"
              color="#ffff"
              _hover={{ bgGradient: `linear(to-r, yellow.400, yellow.500)` }}
              onClick={async () => {
                try {
                  await addSubscription({
                    date: date.toISOString().split("T")[0],
                    systemDate: new Date().toISOString().split("T")[0],
                    /* fName: data.fName,
        lName: data.lName, */
                    accountName: data.accountName,
                    tradingSoftware: data.tradingSoftware,
                  });
                  await refetchSubscriptions();
                  reset();

                  toast({
                    position: "top",
                    duration: 5 * 1000,
                    status: "success",
                    render: () => (
                      <Box
                        bgColor="yellow.400"
                        borderRadius="5px"
                        color="#f5f5f5"
                        p="20px"
                        textAlign="center"
                      >
                        <Heading fontSize="sm" textTransform="uppercase">
                          Subscription added succesfully !
                        </Heading>
                      </Box>
                    ),
                  });
                  clearErrors();
                } catch (error) {
                  console.dir(error);
                }
              }}
            >
              Yes, I'm sure
            </Button>
          </Box>
        ),
      });
    }
  };

  document.title = "TTTSubscriptions | Add Subscription";

  return (
    <form onSubmit={handleSubmit(onSubmitCheck)}>
      <VStack
        align="stretch"
        spacing={10}
        px="50px"
        pb="50px"
        mb="50px"
        mt="100px"
        borderRadius={20}
        className="sidebar_nav"
        maxW="900px"
        mx="auto"
        color="gray.50"
      >
        <Heading color="#000" size="lg" py="40px">
          Add Subscription
        </Heading>

        <FormControl w="100%">
          <FormLabel color="#000">Account Name</FormLabel>
          <Input
            name="accountName"
            {...register("accountName", { required: true })}
            type="text"
            placeholder="Enter account name here..."
            borderColor={colors.blue}
            color={colors.lightBlue}
            _focus={{ borderColor: colors.lightBlue }}
            _placeholder={{
              opacity: 0.7,
              color: "#000",
              fontSize: 14,
              fontStyle: "italic",
            }}
          />

          <Badge
            fontWeight={600}
            colorScheme="red"
            fontSize="12px"
            align="left"
            mt="10px"
          >
            {errors.accountName?.message}
          </Badge>
        </FormControl>

        <FormControl>
          <FormLabel color="#000">Trading Software</FormLabel>
          <Select
            name="tradingSoftware"
            {...register("tradingSoftware", { required: true })}
            placeholder="Make your choice.."
            borderColor={colors.lightBlue}
            _focus={{ borderColor: colors.lightBlue }}
            _placeholder={{
              color: "#000",
              bg: "#000",
            }}
            className="select_sub"
          >
            <option value="Bookmap">Bookmap</option>
            <option value="Jigsaw">Jigsaw</option>
            <option value="Ninja Trader">Ninja Trader</option>
            <option value="Trading view">Trading view</option>
          </Select>
          <Badge
            fontWeight={600}
            colorScheme="red"
            fontSize="12px"
            align="left"
            mt="10px"
          >
            {errors.tradingSoftware?.message}
          </Badge>
        </FormControl>

        <FormControl w="100%">
          <FormLabel color="#000">Delivery Date</FormLabel>
          <DatePicker
            className="datepicker1"
            selected={date}
            color="#000"
            onChange={(date) => setDate(date)}
          />
        </FormControl>

        <Flex align="center" justify={`center`} pt="50px">
          <Button
            w="100%"
            borderRadius={15}
            fontSize="15px"
            className="app_btn"
            boxShadow="lg"
            type="submit"
            py={25}
          >
            + Add subscription
          </Button>
        </Flex>
      </VStack>
    </form>
  );
}
