import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../utils/auth";
import Session from "supertokens-auth-react/recipe/session";
import axios from "axios";
import { client } from "../../../utils/axios";
export default function Profile() {
  document.title = "Tick Tick Trader | My profile";
  const auth = useAuth();
  let sessionContext = Session.useSessionContext();
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);
  async function getUser() {
    try {
      // console.log("session", sessionContext);
      const user = await client.get(
        `${process.env.REACT_APP_API_HOST}/whoami/`
      );
      setUser(user.data);
      // console.log("test", user.data);
    } catch (e) {
      console.log("e", e);
    }
    // return user;
    // console.log("user", user);
  }
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      h="calc(100vh - 40px)"
    >
      {/*<Heading fontSize={70} color={`#ffff`}>
        Coming soon
      </Heading>*/}
      <Box
        //width="100"
        border="1px solid"
        py={10}
        px={20}
        className="sidebar_nav"
        borderRadius={15}
      >
        <Heading color={"#000"} fontSize={40} p={4}>
          Profile
        </Heading>
        <Flex>
          <Box w="200px" p={4} color="black">
            username:
          </Box>
          <Box p={4} color="black">
            {user?.fullName}
          </Box>
        </Flex>
        <Flex>
          <Box w="200px" p={4} color="black">
            email:
          </Box>
          <Box p={4} color="black">
            {user?.email}
          </Box>
        </Flex>
        <Flex>
          <Box w="200px" p={4} color="black">
            Roles:
          </Box>
          <Box p={4} color="black">
            {sessionContext?.accessTokenPayload["st-role"].v.join(", ")}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
