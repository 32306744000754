import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { colors } from "../../../utils/colors";
import { BiSearch, BiTrash } from "react-icons/bi";
// import {
//  } from "react-super-responsive-table";
import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useBlacklist from "../../../hooks/useQueries/useBlacklist";
import useBlacklistMutation from "../../../hooks/useMutations/useBlacklistMutation";
import { useRecoilValue, useRecoilState } from "recoil";
import { filterBlacklistSelector, filterBlacklistState } from "../../../recoil";
import BlacklistedReason from "./reason";
import { BiFilter } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import Pagination1 from "../../../utils/pagination";
import { useAuth } from "../../../utils/auth";
import Session from "supertokens-auth-react/recipe/session";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

export default function Blacklisted() {
  const [searchBy, setSearchBy] = useState("fName");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const query = useRecoilValue(filterBlacklistSelector);
  const [filterBlacklist, setFilterBlacklist] =
    useRecoilState(filterBlacklistState);
  const [fullName, setFullName] = useState("");

  const { blacklists, refetchBlacklists } = useBlacklist(
    query + `&page=${page}&limit=${limit}`
  );
  const sessionContext = Session.useSessionContext();
  useEffect(() => {
    return () => {
      setSearch("");
      setFilterBlacklist({
        ...filterBlacklist,
        fName: ``,
        email: ``,
        search: ``,
        cd: ``,
      });
      refetchBlacklists();
    };
  }, []);
  const { deleteBlacklist } = useBlacklistMutation();

  let toast = useToast();
  document.title = "TTTBlacklist | Blacklist";

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto"
      >
        <Flex align="center" justify="space-between" pb="40px">
          <Box w="20%">
            <Heading size="lg" color="black">
              Blacklist
            </Heading>
          </Box>
          <Flex align="center" justify={`center`} w="100%">
            <Box w="20%">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiFilter size="20px" />}
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                >
                  Search by: {searchBy}
                </MenuButton>
                <MenuList color={colors.ocean} fontSize="14px">
                  <MenuItem onClick={() => setSearchBy("fName")}>
                    Full name
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("email")}>
                    Email
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      // console.log("search", searchBy);
                      setSearchBy("ID/PASSPORT");
                      console.log("search", searchBy);
                    }}
                  >
                    ID or Passport
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      // console.log("search", searchBy);
                      setSearchBy("phone");
                      console.log("search", searchBy);
                    }}
                  >
                    Phone
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>

            <Box w="70%">
              <Input
                type="text"
                placeholder={`Search by ${searchBy}...`}
                // borderColor={colors.lightBlue}
                borderColor="#0177D9"
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>

            <Box w="10%" ml="5px">
              {searchBy === "fName" ? (
                <Button
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  isDisabled={!!search ? false : true}
                  onClick={() => {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      fName: `type=fName&search=${search}`,
                      email: ``,
                    });
                  }}
                >
                  <BiSearch size="15px" />
                </Button>
              ) : searchBy === "email" ? (
                <Button
                  borderRadius={50}
                  fontSize="12px"
                  bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  boxShadow="lg"
                  color="#ffff"
                  _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  isDisabled={!!search ? false : true}
                  onClick={() => {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      email: `type=email&search=${search}`,
                      fName: ``,
                    });
                  }}
                >
                  <BiSearch size="15px" />
                </Button>
              ) : searchBy === "ID/PASSPORT" ? (
                <Button
                  borderRadius={50}
                  fontSize="12px"
                  bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  boxShadow="lg"
                  color="#ffff"
                  _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  isDisabled={!!search ? false : true}
                  onClick={() => {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      email: `type=ID/PASSPORT&search=${search}`,
                      fName: ``,
                    });
                  }}
                >
                  <BiSearch size="15px" />
                </Button>
              ) : (
                <Button
                  borderRadius={50}
                  fontSize="12px"
                  bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  boxShadow="lg"
                  color="#ffff"
                  _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                  isDisabled={!!search ? false : true}
                  onClick={() => {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      email: `type=phone&search=${search}`,
                      fName: ``,
                    });
                  }}
                >
                  <BiSearch size="15px" />
                </Button>
              )}
            </Box>
            <Box w="20%" mr="15px">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiFilter size="20px" />}
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                >
                  Filter by
                </MenuButton>
                <MenuList color={colors.ocean} fontSize="14px">
                  <MenuItem
                    onClick={() => {
                      setFilterBlacklist({
                        ...filterBlacklist,
                        fname: ``,
                        email: ``,
                        search: ``,
                        cd: ``,
                      });
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterBlacklist({
                        ...filterBlacklist,
                        // fname: ``,
                        // email: ``,
                        // search: "&cd=true",
                        cd: "false",
                      });
                    }}
                  >
                    On cooldown
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterBlacklist({
                        ...filterBlacklist,
                        // fname: ``,
                        // email: ``,
                        // search: "&cd=false",
                        cd: "true",
                      });
                    }}
                  >
                    Blacklisted
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Button
              rightIcon={<AiOutlineEye size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              className="app_btn"
              boxShadow="lg"
              px={6}
              color="#ffff"
              _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setFilterBlacklist({
                  ...filterBlacklist,
                  fName: ``,
                  email: ``,
                  search: ``,
                  cd: ``,
                });
              }}
            >
              View all
            </Button>
          </Flex>
        </Flex>

        <Box
          borderRadius={15}
          // bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px"
        >
          {blacklists?.docs?.length > 0 ? (
            <Table borderRadius={20} variant="striped">
              <Thead
                fontSize="15px"
                h="40px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              >
                <Tr
                  textAlign="center"
                  textTransform="capitalize"
                  className="table__head"
                  h="30px"
                  borderBottomWidth={1}
                  borderRadius="20px"
                >
                  <Th textAlign="center" color="#fff">
                    Full Name
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Email
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Phone
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Type
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Date
                  </Th>
                  <Th textAlign="center" color="#fff">
                    More
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody
              // bgGradient={`linear(to-l, gray.100, gray.300)`}
              // color={colors.ocean}
              >
                {blacklists?.docs?.map((blacklisted, i) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // borderColor={colors.lightBlue}
                  >
                    <Td textAlign="center" py="7px">
                      {blacklisted.fName}
                    </Td>
                    <Td textAlign="center">{blacklisted.email} </Td>
                    <Td textAlign="center">{blacklisted.phone}</Td>
                    <Td textAlign="center">
                      {blacklisted.isBlacklisted === false
                        ? "Cooldown"
                        : "Black list"}
                    </Td>
                    <Td textAlign="center">
                      {addHours(new Date(blacklisted.date), 1)
                        .toISOString()
                        .slice(0, 10)}
                    </Td>
                    <Td textAlign="center">
                      <BlacklistedReason blacklisted={blacklisted} />
                    </Td>
                    <Td textAlign="center">
                      <Flex justify="space-evenly" align="center">
                        {/* <EditSubscription blacklisted={blacklisted}/>  */}

                        <Popover>
                          <PopoverTrigger>
                            <Button
                              disabled={
								!sessionContext?.accessTokenPayload["st-role"].v?.includes("BL") &&
								!sessionContext?.accessTokenPayload["st-role"].v?.includes("MASTER")
}
                              bgColor="transparent"
                              color="red.600"
                              align="center"
                              _hover={{ cursor: "pointer", color: "red.500" }}
                            >
                              <BiTrash size="20px" />
                            </Button>
                          </PopoverTrigger>

                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader
                              fontSize="16px"
                              color="red.500"
                              fontWeight={`bold`}
                            >
                              Confirmation!
                            </PopoverHeader>
                            <PopoverBody>
                              <Text size="12px" pb="10px">
                                Are you sure you want to whitelist this user ?
                              </Text>
                              <Button
                                disabled={
									!sessionContext?.accessTokenPayload["st-role"].v?.includes("BL") &&
									!sessionContext?.accessTokenPayload["st-role"].v?.includes("MASTER")
}
                                size="sm"
                                bgGradient={`linear(to-b, red.300, red.500)`}
                                color="gray.50"
                                _hover={{
                                  bgGradient: `linear(to-t, red.300, red.500)`,
                                }}
                                boxShadow="lg"
                                onClick={async () => {
                                  try {
                                    await deleteBlacklist(blacklisted?._id);
                                    await refetchBlacklists();
                                    toast({
                                      position: "bottom",
                                      duration: 5 * 1000,
                                      status: "success",
                                      render: () => (
                                        <Box
                                          bgColor="red.500"
                                          borderRadius="5px"
                                          color="#f5f5f5"
                                          p="10px"
                                          px="10px"
                                          textAlign="center"
                                        >
                                          <Heading fontSize="sm">
                                            User whitelisted !!
                                          </Heading>
                                        </Box>
                                      ),
                                    });
                                  } catch (error) {
                                    console.dir("error", error);
                                  }
                                }}
                              >
                                Yes, I'm sure
                              </Button>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}
            >
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>
      <Pagination1
        pagesQuantity={blacklists?.totalPages}
        curPage={page}
        setCurPage={setPage}
      />
    </Flex>
  );
}
