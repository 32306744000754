import { Button, Flex, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router";
import useMultipleAccountMutation from "../../hooks/useMutations/useMultipleAccountMutation";

const AddMultipleAccount = ({ id, close }) => {
  const [accounts, setAccounts] = useState("");
  const [rithmicId, setRithmicId] = useState("");
  const [publicId, setPublicId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [targetAccount, setTargetAccount] = useState("");
  const [provider, setProvider] = useState("");
  const [cqg_Id, setCqgId] = useState("");
  const [amemberUserId, setAmemberUserId] = useState("");

  const { Add } = useMultipleAccountMutation();

  return (
    <div>
      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%", margin: "0 1rem 0 0" }}>
          <p>Accounts:</p>
          <Input
            type={"text"}
            name="accounts"
            value={accounts}
            onChange={(e) => {
              setAccounts([...accounts, e.target.value]);
            }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <p>Public Id:</p>
          <Input
            type={"text"}
            name="publicId"
            value={publicId}
            onChange={(e) => {
              setPublicId(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p> Invoice Id:</p>
          <Input
            type={"text"}
            name="invoiceId"
            value={invoiceId}
            onChange={(e) => {
              setInvoiceId(e.target.value);
            }}
          />
        </div>
      </div>

      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%" }}>
          <p>Amember User Id:</p>

          <Input
            type={"text"}
            name="amemberUserId"
            value={amemberUserId}
            onChange={(e) => {
              setAmemberUserId(e.target.value);
            }}
          />
        </div>

        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>Target Account:</p>
          <Input
            type={"text"}
            name="targetAccount"
            value={targetAccount}
            onChange={(e) => {
              setTargetAccount(e.target.value);
            }}
          />
        </div>
      </div>

      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%" }}>
          <p>Provider:</p>

          <Input
            type={"text"}
            name="provider"
            value={provider}
            onChange={(e) => {
              setProvider(e.target.value);
            }}
          />
        </div>

        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>Rithmic Id:</p>
          <Input
            type={"text"}
            name="rithmicId"
            value={rithmicId}
            onChange={(e) => {
              setRithmicId(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>CQG Id:</p>
          <Input
            type={"text"}
            name="cqg_Id"
            value={cqg_Id}
            onChange={(e) => {
              setCqgId(e.target.value);
            }}
          />
        </div>
      </div>
      <Flex justifyContent="end">
        <Button
          title="Add Multiple Account"
          onClick={async () => {
            const data = {
              id: id,
              accounts: accounts,
              public_id: publicId,
              invoice_id: invoiceId,
              amember_user_id: amemberUserId,
              target_account: targetAccount,
              provider: provider,
              rithmic_Id: rithmicId,
              cqg_Id: cqg_Id,
            };
            await Add(data);
            close();
          }}
          bgColor="green"
          color="white"
          px={5}
          _hover={{ cursor: "pointer" }}
        >
          Create
        </Button>
      </Flex>
    </div>
  );
};

export default AddMultipleAccount;
