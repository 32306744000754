import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const useCouponlistMutation = () => {
  const auth = useAuth();
  const { mutateAsync: AddCoupons } = useMutation((data) => {
    const newData = {
      coupon: data.coupon.value,
      email: data.email,
    };
    console.log(newData);
    client.post(
      `${process.env.REACT_APP_API_HOST}/admin/coupons`,
      { ...newData },
      {
        method: "POST",
      }
    );
  });

  const { mutateAsync: deleteCouponlist } = useMutation((data) => {
    client.delete(
      `${process.env.REACT_APP_API_HOST}/admin/coupon?custom_coupon_id=${data}`,
      {
        method: "DELETE",
      }
    );
  });

  return { AddCoupons, deleteCouponlist };
};

export default useCouponlistMutation;
