import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery, useQueryClient } from "react-query";
import { getstrquery } from "../../utils/query.js";

const getAllFunded = (query) => async () => {
	// query filter this query object to remove undefined values and empty strings and arrays
	const postData = getstrquery(query);

	const { data } = await client.get(
		`${process.env.REACT_APP_API_HOST}/funded?${postData}`,
	);

	return data;
};
export const getTriggerCreation = async (query) => {
	try {
		const { data } = await client.post(
			`${process.env.REACT_APP_API_HOST}/funded/trigger-creation`, {
			accountId: query.accountId,
			reason: query.reason
		}
		);

		return {
			success: "PA Created Sucessfully",
			data: data
		};
	} catch (error) {
		console.error(error.response.data);
		return { error: error.response.data.error };
	}
};
export const setKycReason = async (query) => {
	try {
		const { data } = await client.post(
			`${process.env.REACT_APP_API_HOST}/funded/kyc-reason`, {
			accountId: query.accountId,
			reason: query.reason
		}
		);

		return {
			success: "Reason Added Sucessfully",
			data: data
		};
	} catch (error) {
		console.error(error.response.data);
		return { error: error.response.data.error };
	}
};

export const setExportOptions = async (query) => {
	try {
		const { data } = await client.post(
			`${process.env.REACT_APP_API_HOST}/funded/export-options`, {
			is_kyc_approved: query.is_kyc_approved,
			is_invoice_allowd: query.is_invoice_allowd,
			is_signin: query.is_signin,
		}
		);

		return {
			success: "Exported Sucessfully",
			data: data.data
		};
	} catch (error) {
		console.error(error.response.data);
		return { error: error.response.data.error };
	}
};
export const setMarkSigned = async (query) => {
	try {
		const { data } = await client.post(
			`${process.env.REACT_APP_API_HOST}/funded/mark-signed`, {
				user_id: query.user_id,
		}
		);

		return {
			success: "User Marked as Signed",
			data: data
		};
	} catch (error) {
		console.error(error.response.data);
		return { error: error.response.data.error };
	}
};
export const setMarkCreation = async (query) => {
	try {
		const { data } = await client.post(
			`${process.env.REACT_APP_API_HOST}/funded/mark-creation`, {
			accountId: query.accountId,
			paccountId: query.paccountId
		}
		);

		return {
			success: "Marked as Created",
			data: data
		};
	} catch (error) {
		console.error(error.response.data);
		return { error: error.response.data.error };
	}
};
export const getUpdateInvoice = async (query) => {
	try {
		const { data } = await client.get(
			`${process.env.REACT_APP_API_HOST}/funded/invoice-status?invoiceId=${query}`,
			{
				method: "GET",
			}
		);

		return data;
	} catch (error) {
		console.error(error.response.data);
		return { error: error.response.data.error };
	}
};

const useAllFundedlists = (query) => {
	const queryClient = useQueryClient();
	//const auth = useAuth();

	return {
		funded: useQuery(["funded-list",getstrquery(query)], getAllFunded(query), {
			staleTime: ms("1s"),
			refetchInterval: ms("5m"),
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			refetchOnReconnect: true,
			keepPreviousData: false,
			retry: false,
		}).data,
		refetchFundedlists: ()=>queryClient.refetchQueries(["funded-list",getstrquery(query)]),
	};
};

export default useAllFundedlists;
