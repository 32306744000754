import { useQuery, useQueryClient } from "react-query";
import { GET } from "../../utils/request";
import ms from "ms";
//import axios from "axios";
import { useAuth } from "../../utils/auth";
import { client as axios } from "../../utils/axios";
const bookmapLicensesFetcher = (query, auth) => async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_HOST}/bookmapLicenses?${query??``}`,
    {
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    }
  );
  return data;
};

const useBookmapLicenses = (query) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  return {
    bookmapLicenses: useQuery(
      ["bookmapLicenses", query],
      bookmapLicensesFetcher(query, auth),
      {
        staleTime: ms("1s"),
        refetchInterval: ms("5m"),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchBookmapLicenses: () =>
      queryClient.refetchQueries(["bookmapLicenses"]),
  };
};

export default useBookmapLicenses;
