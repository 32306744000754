import { Flex, Box, Image, Heading } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
// import logo from "../../assets/logo.png";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";
import { BiMessageSquareAdd, BiTable, BiHome, BiStats } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router";
import certif from "../../assets/appLogos/certif.png";

export default function NavbarAccountCertificate() {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  return (
    <Flex
      justify={`space-between`}
      align="center"
      h="70px"
      w="100%"
      boxShadow={`0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgb(0 0 0 / .25)`}
      color={"#000"}
      bg="#fff"
      px="20px">
      <Flex w="20%">
        <Image src={certif} h="60px" />
        <Image src={logo} h="60px" w="auto" onClick={() => navigate("/apps")} />
        <Image
          src={logo1}
          h="60px"
          w="auto"
          onClick={() => navigate("/apps")}
        />
      </Flex>
      <Flex justify={`space-around`} align="center" color="gray.50" w="60%">
        <Flex
          // _hover={{
          //   bgColor: "gray.50",
          //   color: colors.ocean,
          //   FlexShadow: "lg",
          // }}
          // p="10px"
          // borderRadius={20}
          className="nav__bar__link"
          cursor="pointer"
          onClick={() => navigate("/apps")}
          bgColor={location === "/apps" && "gray.50"}
          color={location === "/apps" && colors.ocean}
          boxShadow={location === "/apps" && "lg"}>
          <Box>
            <BiHome color="#000" size="20px" />
          </Box>
          <Box mt="2px">
            <Heading color="#000" size="xs">
              &nbsp; Apps
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
