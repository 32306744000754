import {
  Box,
  VStack,
  Heading,
  Button,
  Input,
  chakra,
  Flex,
  Image,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  HStack,
  Badge,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Papa from "papaparse";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { colors } from "../../../utils/colors";
import csvlogo from "../../../assets/images/csv.png";
import useLicensesMutation from "../../../hooks/useMutations/useLicensesMutation";
import useLicenseMutation from "../../../hooks/useMutations/useLicensesMutation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import axios from "axios";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./datepicker2.css";
import useLicenses from "../../../hooks/useQueries/useLicenses";
import useBookmapLicenses from "../../../hooks/useQueries/useBookmapLicenses";

import useBookmapLicenseMutation from "../../../hooks/useMutations/useBookmapLicensesMutation";
import { useAuth } from "../../../utils/auth";
import Session from "supertokens-auth-react/recipe/session";
import { client } from "../../../utils/axios";

const CustomTable = chakra(Table);
const CustomThead = chakra(Thead);
const CustomTbody = chakra(Tbody);
const CustomTr = chakra(Tr);
const CustomTh = chakra(Th);
const CustomTd = chakra(Td);

const schema = yup.object().shape({
  accountName: yup.string().required("!! Account Name is a required field."),
  email: yup.string().required(" !! Email is a required field."),
});

export default function TTTbookmapHomepage() {
  const toast = useToast();
  let auth = useAuth();
  let sessionContext = Session.useSessionContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const {
    handleSubmit: handleSubmitCsv,
    formState: { isSubmitting },
  } = useForm({});

  const [nbr, setNbr] = useState(0);
  const [date, setDate] = useState(new Date());
  const [generatedKey, setGeneratedKey] = useState();

  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);
        console.log(results.data);
        // Filtered Column Names
        setTableRows(rowsArray[0]);
        console.log(rowsArray[0]);
        // Filtered Values
        setValues(valuesArray);
        console.log(valuesArray);
      },
    });
  };

  const { addLicense, deleteLicense } = useLicenseMutation();
  const { licenses, refetchLicenses } = useLicenses();
  const { addBookmapLicense } = useBookmapLicenseMutation();
  const { refetchBookmapLicenses } = useBookmapLicenses();
  const pushCsv = async () => {
    try {
      for (let i = 0; i < values.length; i++) {
        console.log("to be   ", values[i]);
        await addLicense({
          password: values[i][3],
          licenseKey: values[i][2],
          username: values[i][0],
          email: values[i][1],
        });
        setNbr(i + 1);
      }
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="yellow.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center"
          >
            <Heading fontSize="sm" textTransform="uppercase">
              CSV file Pushed succesfully !
            </Heading>
          </Box>
        ),
      });
    } catch (e) {
      //  if (e.code === "ERR_NETWORK" || true) {
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="red.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center"
          >
            <Heading fontSize="md" textTransform="uppercase">
              Error due to duplication
            </Heading>
            <Text fontSize={13}>
              This CSV file contains existing licence keys !
            </Text>
          </Box>
        ),
      });
      //  }
    }
  };

  //  const onSubmit = async data => {

  //    try {
  //      await addBookmapLicense({
  //        date: date.toISOString().split("T")[0],
  //        accountName: data.accountName,
  //        email: data.email,
  //        password: generatedKey.password,
  //        licenseKey: generatedKey.licenseKey,
  //		// TODO  username: generatedKey.password,
  //        // TODO  licenseemail: generatedKey.licenseKey,

  //        isActif: 1

  //      });
  //      await deleteLicense(generatedKey._id)
  //      await refetchLicenses();
  //      reset()
  //      setGeneratedKey()
  //      toast({
  //        position: "top",
  //        duration: 5 * 1000,
  //        status: "success",
  //        render: () =>
  //          <Box
  //            bgColor="yellow.400"
  //            borderRadius="5px"
  //            color="#f5f5f5"
  //            p="20px"
  //            textAlign="center"
  //          >
  //            <Heading fontSize="sm" textTransform="uppercase">
  //              License Affected succefully !
  //            </Heading>
  //          </Box>
  //      });
  //      clearErrors();
  //    } catch (error) {
  //      /* if (error.response.data.error.message.includes("Duplicate")) {
  //        toast({
  //          position: "bottom-right",
  //          duration: 5 * 1000,
  //          status: "success",
  //          render: () =>
  //            <Box
  //              bgColor="red.500"
  //              borderRadius="5px"
  //              color="#f5f5f5"
  //              p="10px"
  //              textAlign="center"
  //            >
  //              <Heading fontSize="xs" textTransform="uppercase">
  //                Une facture avec ce Numéro existe déjà !!
  //              </Heading>
  //            </Box>
  //        });
  //      } */
  //      console.dir(error);
  //    }

  //  };

  const onSubmitCheck = async (data) => {
    const result = await client.get(
      `${process.env.REACT_APP_API_HOST}/bookmapLicenses?type=accounts&search=${data.accountName}`,
      {
        // method: "GET",
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      }
    );
    if (result.data.docs.length === 0) {
      try {
        await addBookmapLicense({
          date: date.toISOString().split("T")[0],
          accountName: data.accountName,
          email: data.email,
          password: generatedKey.password,
          licenseKey: generatedKey.licenseKey,
          isActif: true,
          username: generatedKey.username,
          email2: generatedKey.email,
        });
        await deleteLicense(generatedKey._id);
        await refetchLicenses();
        await refetchBookmapLicenses();
        reset();
        setGeneratedKey();

        toast({
          position: "top",
          duration: 5 * 1000,
          status: "success",
          render: () => (
            <Box
              bgColor="yellow.400"
              borderRadius="5px"
              color="#f5f5f5"
              p="20px"
              textAlign="center"
            >
              <Heading fontSize="sm" textTransform="uppercase">
                Subscription added succesfully !
              </Heading>
            </Box>
          ),
        });
        clearErrors();
      } catch (error) {
        /* if (error.response.data.error.message.includes("Duplicate")) {
          toast({
            position: "bottom-right",
            duration: 5 * 1000,
            status: "success",
            render: () =>
              <Box
                bgColor="red.500"
                borderRadius="5px"
                color="#f5f5f5"
                p="10px"
                textAlign="center"
              >
                <Heading fontSize="xs" textTransform="uppercase">
                  Une facture avec ce Numéro existe déjà !!
                </Heading>
              </Box>
          });
        } */
        console.dir(error);
      }
    } else {
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgGradient={`linear(to-l, gray.100, gray.300)`}
            color={colors.ocean}
            borderRadius="5px"
            p="10px"
            textAlign="center"
            w="600px"
          >
            <Box pb="10px">
              <Heading fontSize="md" textTransform="uppercase" py="10px">
                Confirmation
              </Heading>

              <Text fontSize={13} pb="10px">
                This account had already one/many bookmap licenses! <br />
                Are you sure you want to assign him a new one ?
              </Text>

              {result?.data.length > 0 && (
                <CustomTable borderRadius={20}>
                  <CustomThead
                    fontSize="12px"
                    h="20px"
                    bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  >
                    <CustomTr
                      textAlign="center"
                      textTransform="capitalize"
                      h="20px"
                      borderBottomWidth={1}
                      borderRadius="20px"
                      color="gray.900"
                    >
                      <CustomTh>License key</CustomTh>
                      <CustomTh>Delivery </CustomTh>
                    </CustomTr>
                  </CustomThead>

                  <CustomTbody
                    bgGradient={`linear(to-l, gray.100, gray.300)`}
                    color={colors.ocean}
                  >
                    {result?.data.map((res, i) => (
                      <CustomTr
                        textAlign="center"
                        fontSize={12}
                        key={i}
                        borderBottom="1px"
                        borderColor={colors.lightBlue}
                      >
                        <CustomTd>{res.licenseKey} </CustomTd>
                        <CustomTd>{res.date.slice(0, 10)}</CustomTd>
                      </CustomTr>
                    ))}
                  </CustomTbody>
                </CustomTable>
              )}
            </Box>

            <Button
              size="sm"
              borderRadius={15}
              fontSize="12px"
              bgGradient={`linear(to-b, yellow.400, yellow.500)`}
              boxShadow="lg"
              color="#ffff"
              _hover={{ bgGradient: `linear(to-r, yellow.400, yellow.500)` }}
              onClick={async () => {
                try {
                  await addBookmapLicense({
                    date: date.toISOString().split("T")[0],
                    accountName: data.accountName,
                    email: data.email,
                    password: generatedKey.password,
                    licenseKey: generatedKey.licenseKey,
                    // TODO  username: generatedKey.password,
                    // TODO  licenseemail: generatedKey.licenseKey,
                    isActif: true,
                  });
                  await deleteLicense(generatedKey._id);
                  await refetchLicenses();
                  await refetchBookmapLicenses();

                  reset();
                  setGeneratedKey();

                  toast({
                    position: "top",
                    duration: 5 * 1000,
                    status: "success",
                    render: () => (
                      <Box
                        bgColor="yellow.400"
                        borderRadius="5px"
                        color="#f5f5f5"
                        p="20px"
                        textAlign="center"
                      >
                        <Heading fontSize="sm" textTransform="uppercase">
                          Bookmap License assigned successfully !
                        </Heading>
                      </Box>
                    ),
                  });
                  clearErrors();
                } catch (error) {
                  console.dir(error);
                }
              }}
            >
              Yes, I'm sure
            </Button>
          </Box>
        ),
      });
    }
  };
  document.title = "Bookmap Licenses | Add License";

  return (
    <VStack spacing={5} align="stretch" px="50px" py="20px">
      <Box py="20px">
        <Heading size="md" color="black">
          {" "}
          {/* Import CSV File */} Bookmap Licenses Assignement
        </Heading>
      </Box>

      {/* ---------- MASTER ADMIN ONLY ----------- */}

      {sessionContext?.accessTokenPayload["st-role"].v.includes("MASTER") && (
        <Flex justify={"space-between"} align="center" w="100%">
          <Box w="50%">
            <Input
              type="file"
              name="file"
              accept=".csv"
              style={{ display: "block", margin: "10px auto" }}
              onChange={changeHandler}
              borderColor={colors.lightBlue}
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              bgColor="gray.100"
              borderRadius={20}
              boxShadow="lg"
            />
          </Box>
          {tableRows?.length > 0 && values?.length > 0 && (
            <Box w="20%">
              <form onSubmit={handleSubmitCsv(pushCsv)}>
                {!isSubmitting ? (
                  <Button
                    type="submit"
                    borderRadius={50}
                    fontSize="12px"
                    bgGradient={`linear(to-b, green.400, green.500)`}
                    boxShadow="lg"
                    color="#ffff"
                    _hover={{
                      bgGradient: `linear(to-r, green.400, green.500)`,
                    }}
                  >
                    Push CSV to Database
                  </Button>
                ) : (
                  <Button
                    borderRadius={50}
                    fontSize="12px"
                    bgGradient={`linear(to-b, green.400, green.500)`}
                    boxShadow="lg"
                    color="#ffff"
                    _hover={{
                      bgGradient: `linear(to-r, green.400, green.500)`,
                    }}
                  >
                    <Flex align="center" justify="center">
                      <Spinner color="gray.50" size="sm" />
                      <Heading size="sm">
                        &nbsp;{" "}
                        {`processing... ${parseInt(
                          (nbr / values.length) * 100
                        )}%`}
                      </Heading>
                    </Flex>
                  </Button>
                )}
              </form>
            </Box>
          )}
        </Flex>
      )}

      <Flex align="center" justify="center" w="100%" h="calc(100vh - 280px)">
        {/*   ------------ ASSIGN LICENSE ------------ */}

        <Box w="100%">
          <form onSubmit={handleSubmit(onSubmitCheck)}>
            <VStack
              bgColor="gray.50"
              align="stretch"
              spacing={4}
              p="25px"
              borderRadius={15}
              boxShadow={`0px 2px 10px 5px rgba(0,183,196,0.35)`}
              w="100%"
            >
              <Flex justify={`space-between`} align="center">
                <Heading size="md" py="5px">
                  {" "}
                  Assign license{" "}
                </Heading>
                <Badge
                  colorScheme="yellow"
                  fontWeight={600}
                  fontSize="12px"
                  borderRadius={15}
                  boxShadow={"lg"}
                >
                  {licenses?.length > 0
                    ? `${licenses?.length} available licenses`
                    : "No available license"}
                </Badge>
              </Flex>

              <FormControl>
                <FormLabel fontSize={14}>User ID</FormLabel>
                <Input
                  name="accountName"
                  {...register("accountName", { required: true })}
                  type="text"
                  placeholder="Enter User ID here..."
                  borderColor={colors.lightBlue}
                  color={colors.lightBlue}
                  _focus={{ borderColor: colors.lightBlue }}
                  _placeholder={{
                    opacity: 0.7,
                    color: colors.ocean,
                    fontSize: 12,
                    fontStyle: "italic",
                  }}
                />
                <Badge
                  fontWeight={600}
                  colorScheme="red"
                  fontSize="12px"
                  align="left"
                  mt="10px"
                >
                  {errors.accountName?.message}
                </Badge>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={14}>Email</FormLabel>
                <Input
                  name="email"
                  {...register("email", { required: true })}
                  type="email"
                  placeholder="Enter email here..."
                  borderColor={colors.lightBlue}
                  color={colors.lightBlue}
                  _focus={{ borderColor: colors.lightBlue }}
                  _placeholder={{
                    opacity: 0.7,
                    color: colors.ocean,
                    fontSize: 12,
                    fontStyle: "italic",
                  }}
                />
                <Badge
                  fontWeight={600}
                  colorScheme="red"
                  fontSize="12px"
                  align="left"
                  mt="10px"
                >
                  {errors.email?.message}
                </Badge>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={14}>Date</FormLabel>
                <DatePicker
                  className="datepicker2"
                  selected={date}
                  onChange={(date) => setDate(date)}
                />
              </FormControl>
              <Button
                w="100%"
                borderRadius={10}
                fontSize="15px"
                className="app_btn"
                boxShadow="lg"
                py={19.75}
                onClick={async () => {
                  try {
                    if (licenses?.length > 0) {
                      setGeneratedKey(licenses[0]);
                    } else {
                      toast({
                        position: "top",
                        duration: 5 * 1000,
                        status: "success",
                        render: () => (
                          <Box
                            bgColor="red.500"
                            borderRadius="5px"
                            color="#f5f5f5"
                            p="20px"
                            textAlign="center"
                          >
                            <Heading fontSize="sm" textTransform="uppercase">
                              No License key found!
                            </Heading>
                          </Box>
                        ),
                      });
                    }
                  } catch {}
                }}
                size="sm"
              >
                Generate License key
              </Button>
              {!!generatedKey && (
                <VStack
                  borderRadius={15}
                  p="10px"
                  align="stretch"
                  spacing={2}
                  bgGradient={`linear(to-l, gray.100, gray.300)`}
                  color="#000"
                  boxShadow={`lg`}
                >
                  <HStack align={`stretch`} spacing={3}>
                    <Text fontSize={12} fontWeight="bold">
                      {" "}
                      Generated key:{" "}
                    </Text>
                    <Text fontSize={12}> {generatedKey.licenseKey} </Text>
                  </HStack>

                  {/* <HStack align={`stretch`} spacing={3}>
                  <Text fontSize={12} fontWeight="bold"> Password: </Text>
                  <Text fontSize={12}> {generatedKey.password} </Text>
              </HStack> */}

                  <Button
                    w="100%"
                    borderRadius={15}
                    fontSize="12px"
                    bgGradient={`linear(to-b, yellow.500, yellow.300)`}
                    boxShadow="lg"
                    _hover={{
                      bgGradient: `linear(to-r, yellow.300, yellow.500)`,
                    }}
                    type="submit"
                    size="sm"
                    color="gray.700"
                  >
                    Assign License
                  </Button>
                </VStack>
              )}
            </VStack>
          </form>
        </Box>

        {sessionContext?.accessTokenPayload["st-role"]?.v?.includes(
          "MASTER"
        ) && (
          <Box w="60%" align="left">
            {tableRows?.length > 0 && values?.length > 0 ? (
              <Box
                borderRadius={15}
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color="#000"
                boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
                overflow={`scroll`}
                maxH="550px"
                h="550px"
              >
                <CustomTable borderRadius={20}>
                  <CustomThead
                    fontSize="15px"
                    h="40px"
                    bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  >
                    <CustomTr
                      textAlign="center"
                      textTransform="capitalize"
                      h="30px"
                      borderBottomWidth={1}
                      borderRadius="20px"
                    >
                      {tableRows.map((rows, index) => (
                        <CustomTh key={index}>{rows}</CustomTh>
                      ))}
                    </CustomTr>
                  </CustomThead>
                  <CustomTbody
                    bgGradient={`linear(to-l, gray.100, gray.300)`}
                    color={colors.ocean}
                  >
                    {values.map((value, index) => {
                      return (
                        <CustomTr
                          textAlign="center"
                          fontSize={{
                            "2xl": "13px",
                            xl: "13px",
                            md: "12px",
                            lg: "12px",
                            sm: "10px",
                          }}
                          key={index}
                          borderBottom="1px"
                          borderColor={colors.lightBlue}
                        >
                          {value.map((val, i) => {
                            return <CustomTd key={i}>{val}</CustomTd>;
                          })}
                        </CustomTr>
                      );
                    })}
                  </CustomTbody>
                </CustomTable>
              </Box>
            ) : (
              <Flex
                borderRadius={15}
                color="#000"
                overflow={`scroll`}
                h="550px"
                justify={`center`}
                align="center"
              >
                <Box align="center">
                  <Image src={csvlogo} h="250px" w="auto" opacity="0.6" />
                  <Heading size="lg" color="gray.300">
                    No CSV file choosen !!
                  </Heading>
                </Box>
              </Flex>
            )}
          </Box>
        )}
      </Flex>
    </VStack>
  );
}
