import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Button,
  FormLabel,
  useDisclosure,
  VStack,
  Select,
  Box,
  Heading,
  useToast,
  Tooltip,
  HStack,
  Text,
  Badge,
} from "@chakra-ui/react";
import { colors } from "../../../utils/colors";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import useBookmapLicenses from "../../../hooks/useQueries/useBookmapLicenses";
import { BiEdit } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../../utils/auth";

const schema = yup.object().shape({
  /* fName: yup.string().required("First Name Required"),
    lName: yup.string().required("Last Name Required"), */
  isActif: yup.string().required("Status Required"),
});

export default function EditBookmapLicense({ bl }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { refetchBookmapLicenses } = useBookmapLicenses();
  const [date, setDate] = useState(new Date());
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const editBookmapLicense = {
        isActif: data.isActif,
        toInactif: date,
      };

      await axios.put(
        `${process.env.REACT_APP_API_HOST}/bookmapLicenses/${bl?._id}`,
        editBookmapLicense,
        {
          // method: "GET",
          // headers: {
          // "auth-token": auth.user.accessToken,
          // },
        }
      );

      await refetchBookmapLicenses();

      toast({
        position: "bottom-right",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="yellow.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="10px"
            textAlign="center"
          >
            <Heading fontSize="xs" textTransform="uppercase">
              Status updated successfully !
            </Heading>
          </Box>
        ),
      });
      clearErrors();
      onClose();
    } catch (error) {
      console.dir(error);
    }
  };
  return (
    <>
      <Tooltip label="Edit" placement="auto">
        <Box
          color={colors.ocean}
          align="center"
          _hover={{ cursor: "pointer", color: colors.lightBlue }}
        >
          <BiEdit size="20px" onClick={onOpen} />
        </Box>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent borderRadius={15} _focus={{}}>
          <ModalHeader>Update Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack align="stretch" spacing={5}>
                <VStack
                  color="gray.50"
                  spacing={1}
                  align="stretch"
                  borderRadius={15}
                  p="10px"
                  boxShadow={"lg"}
                  className="sidebar_nav"

                  // bgGradient={`linear(to-l, ${colors.blue}, ${colors.lightBlue})`}
                >
                  <HStack
                    color="black"
                    spacing={3}
                    align="stretch"
                    fontSize={12}
                  >
                    <Text fontWeight={`bold`}>Account Name:</Text>
                    <Text>{bl.accountName}</Text>
                  </HStack>
                  <HStack
                    color="black"
                    spacing={3}
                    align="stretch"
                    fontSize={12}
                  >
                    <Text fontWeight={`bold`}>Licence Key:</Text>
                    <Text>{bl.licenseKey}</Text>
                  </HStack>
                  <HStack
                    color="black"
                    spacing={3}
                    align="stretch"
                    fontSize={12}
                  >
                    <Text fontWeight={`bold`}>Password:</Text>
                    <Text>{bl.password}</Text>
                  </HStack>
                  <HStack spacing={3} align="stretch" fontSize={12}>
                    <Text color="black" fontWeight={`bold`}>
                      Status:
                    </Text>
                    <Badge
                      colorScheme={!!bl.isActif ? "green" : "red"}
                      fontSize={12}
                    >
                      {!!bl.isActif ? "Actif" : "Inactif"}
                    </Badge>
                  </HStack>
                </VStack>

                <FormControl>
                  <FormLabel fontSize="14px">License</FormLabel>
                  <Select
                    name="isActif"
                    {...register("isActif", { required: true })}
                    placeholder="Update status.."
                    borderColor={colors.blue}
                    _focus={{ borderColor: colors.lightBlue }}
                    _placeholder={{
                      opacity: 0.7,
                      color: "#000",
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                    defaultValue={bl.status}
                    size="sm"
                  >
                    <option value={1}>Actif</option>
                    <option value={0}>Inactif</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={14}>Date</FormLabel>
                  <DatePicker
                    className="datepicker2"
                    selected={date}
                    onChange={(date) => setDate(date)}
                  />
                </FormControl>

                <Button
                  w="100%"
                  borderRadius={15}
                  fontSize="15px"
                  boxShadow="lg"
                  className="app_btn"
                  color="#ffff"
                  type="submit"
                  size="sm"
                >
                  <BiEdit size="20px" /> &nbsp; Edit
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
