import { useQuery, useQueryClient } from "react-query";
import { GET } from "../../utils/request";
import ms from "ms";
import axios from "axios";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const payoutlistFetcher = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/payout?${query}`,
    {
      method: "GET",
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    }
  );
  return data;
};

const usePayoutList = (query) => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const { data: payoutlist, isLoading } = useQuery(
    ["payouts", query],
    payoutlistFetcher(query, auth),
    {
      staleTime: ms("1s"),
      refetchInterval: ms("5m"),
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      retry: false,
    }
  );

  return {
    payoutlist,
    isLoading,
    refetchPayoutList: () => queryClient.refetchQueries(["payouts"]),
  };
};

const payoutHistoryFetcher = (query, auth) => async () => {
  const { data } = await client.get(
    `${process.env.REACT_APP_API_HOST}/archive?${query}`,
    {
      method: "GET",
      // headers: {
      //   "auth-token": auth.user.accessToken,
      // },
    }
  );
  return data;
};

export const usePayoutHistory = (query) => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  return {
    payoutHistory: useQuery(
      ["payoutHistory", query],
      payoutHistoryFetcher(query, auth),
      {
        staleTime: ms("1s"),
        refetchInterval: ms("5m"),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        keepPreviousData: true,
        retry: false,
      }
    ).data,
    refetchPayoutHisotry: () => queryClient.refetchQueries(["payoutHistory"]),
  };
};

export default usePayoutList;
