import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const useSubscriptionMutation = () => {
  const auth = useAuth();
  const { mutateAsync: addSubscription } = useMutation((data) =>
    client.post(
      `${process.env.REACT_APP_API_HOST}/subscriptions`,
      { ...data },
      {
        //   method: "GET",
        //   headers: {
        // 	"auth-token": auth.user.accessToken,
        //   },
      }
    )
  );

  const { mutateAsync: deleteSubscription } = useMutation((id) =>
    client.delete(`${process.env.REACT_APP_API_HOST}/subscriptions/${id}`, {
      //   method: "GET",
      //   headers: {
      // 	"auth-token": auth.user.accessToken,
      //   },
    })
  );

  return { addSubscription, deleteSubscription };
};

export default useSubscriptionMutation;
