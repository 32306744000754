import {
  Badge,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { colors } from "../../utils/colors";
import {
  getInvoiceStatus,
  getPayedStatus,
  getSignInStatus,
} from "../../utils/commen";
import Session from "supertokens-auth-react/recipe/session";

const DetailFunded = ({ funded }) => {
  let sessionContext = Session.useSessionContext();

  const renderCreationStatus = (fn) => {
    if (fn?.creation_status === "success") {
      return (
        <Badge variant="outline" colorScheme="green">
          {fn?.creation_status}
        </Badge>
      );
    } else if (fn?.creation_status === "working") {
      return (
        <Badge variant="outline" colorScheme="orange">
          {fn?.creation_status}
        </Badge>
      );
    } else if (!fn?.creation_status) {
      return <p>-</p>;
    } else {
      return (
        <Badge variant="outline" colorScheme="red">
          {fn?.creation_status}
        </Badge>
      );
    }
  };
  return (
    <>
      <GridItem className="sidebar_nav" border="1px solid lightgray">
        <Table variant="simple">
          <Tbody>
            <Tr>
              <Td fontWeight="bold">Title:</Td>
              <Td>{funded?.item_title || "-"}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Account Id:</Td>
              <Td>{funded?.accountId || "-"}</Td>
            </Tr>
            {funded?.active_accounts.length > 0 && (
              <Tr>
                <Td>Accounts:</Td>
                <Td>
                  {funded?.active_accounts?.map((account, i) => (
                    <Badge key={i} mx={1} variant="solid" colorScheme="green">
                      {account}
                    </Badge>
                  ))}
                </Td>
              </Tr>
            )}
            <Tr>
              <Td fontWeight="bold">User Id:</Td>
              <Td>{funded?.userId || "-"}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Payed:</Td>
              <Td>
                {/* <Badge variant="outline" colorScheme="green">
                  {getPayedStatus(funded?.payed)}
                </Badge> */}
                {funded?.payed === "1" ? (
                  <Badge variant="outline" colorScheme="green">
                    {getPayedStatus(funded?.payed)}
                  </Badge>
                ) : (
                  <Badge variant="outline" colorScheme="red">
                    {getPayedStatus(funded?.payed)}
                  </Badge>
                )}
              </Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Perfarmance Account:</Td>
              <Td>{funded?.accountCreated || "-"}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Status:</Td>
              <Td>
                {/* {funded?.creation_status === "success" ? (
                  <Badge variant="outline" colorScheme="green">
                    {funded?.creation_status}
                  </Badge>
                ) : (
                  <Badge variant="outline" colorScheme="red">
                    {funded?.creation_status}
                  </Badge>
                )} */}
                {renderCreationStatus(funded)}
              </Td>
            </Tr>
            {/* <Tr>
              <Td fontWeight='bold' >user_id:</Td>
              <Td>{funded?.user_id || "-"}</Td>
            </Tr> */}
            <Tr>
              <Td fontWeight="bold">Login:</Td>
              <Td>{funded?.login || "-"}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Email:</Td>
              <Td>{funded?.email || "-"}</Td>
            </Tr>

            {/* <Tr>
              <Td fontWeight='bold' >KYC Key:</Td>
              <Td>
                {(funded?.kyc_key && (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_TTT_INQUIREIES_URL}/${funded?.kyc_key}`}
                  >
                    {funded?.kyc_key}
                  </a>
                )) ||
                  "-"}
              </Td>
            </Tr> */}
            <Tr>
              <Td fontWeight="bold">KYC</Td>
              <Td>
                {(funded?.kyc_status && (
                  <Badge
                    variant="outline"
                    colorScheme={(() => {
                      switch (funded?.kyc_status) {
                        case "approved":
                          return "green";
                        case "completed":
                          return "gray";
                        case "created":
                          return "gray";
                        case "declined":
                          return "red";
                        case "failed":
                          return "red";
                        case "needs_review":
                          return "orange";
                        default:
                          return "gray";
                      }
                    })()}
                  >
                    {!!funded?.kyc_key && (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={`${process.env.REACT_APP_TTT_INQUIREIES_URL}/${funded?.kyc_key}`}
                      >
                        {funded?.kyc_status}
                      </a>
                    )}
                    {!funded?.kyc_key && <span>{funded?.kyc_status}</span>}
                  </Badge>
                )) ||
                  "-"}
              </Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Rithmic Id:</Td>
              <Td>{funded?.rithmic_id || "-"}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">CQG Id:</Td>
              <Td>{funded?.cqg_id || "-"}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Signed:</Td>
              <Td>
                {/* <Badge variant="outline" colorScheme="green">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_TTT_SIGNNOW_URL}/${funded?.document_id}`}
                  >
                    {getSignInStatus(funded?.is_signin)}
                  </a>
                </Badge> */}
                {funded?.is_signin === "1" ? (
                  <Badge variant="outline" colorScheme="green">
                    {sessionContext &&
                    sessionContext.accessTokenPayload["st-role"].v.includes(
                      "MASTER"
                    ) ? (
                      !funded?.document_id ? (
                        <span>{getSignInStatus(funded?.is_signin)}</span>
                      ) : (
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${process.env.REACT_APP_TTT_SIGNNOW_URL}/${funded?.document_id}`}
                        >
                          {getSignInStatus(funded?.is_signin)}
                        </a>
                      )
                    ) : (
                      <>{getSignInStatus(funded?.is_signin)}</>
                    )}
                  </Badge>
                ) : (
                  <Badge variant="outline" colorScheme="red">
                    {getSignInStatus(funded?.is_signin)}
                  </Badge>
                )}
              </Td>
            </Tr>
            {/* <Tr>
              <Td fontWeight='bold' >Document Id:</Td>
              <Td>
                {(funded?.document_id && (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_TTT_SIGNNOW_URL}/${funded?.document_id}`}
                  >
                    {funded?.document_id}
                  </a>
                )) ||
                  "-"}
              </Td>
            </Tr> */}
            {/* <Tr>
              <Td fontWeight='bold' >Public Id:</Td>
              <Td>{funded?.public_id || "-"}</Td>
            </Tr> */}
            <Tr>
              <Td fontWeight="bold">Invoice:</Td>
              <Td>
                <Badge variant="outline" colorScheme="green">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_TTT_AMEMBER_URL}${funded?.user_id}#invoice-${funded?.invoice_id}`}
                  >
                    {`#${funded?.invoice_id}/${funded?.public_id}`}
                  </a>
                </Badge>
              </Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Invoice Status:</Td>
              <Td>
                <Badge
                  variant="outline"
                  colorScheme={`${
                    funded?.invoice_status === "3" ||
                    funded?.invoice_status === "4"
                      ? "red"
                      : "green"
                  }`}
                >
                  {getInvoiceStatus(funded?.invoice_status) || "-"}
                </Badge>
              </Td>
            </Tr>
            {!!funded?.infos?.creationReason && (
              <Tr>
                <Td>Manual Creation Reason:</Td>
                <Td>{funded?.infos?.creationReason}</Td>
              </Tr>
            )}

            {typeof funded?.infos?.createdManually == "boolean" && (
              <Tr>
                <Td>Created Manualy:</Td>
                <Td>{funded?.infos?.createdManually ? "Yes" : "No"}</Td>
              </Tr>
            )}

            {!!funded?.infos?.kycReason && (
              <Tr>
                <Td>KYC Decline Reason:</Td>
                <Td>{funded?.infos?.kycReason}</Td>
              </Tr>
            )}

            <Tr>
              <Td fontWeight="bold">Invoice Item Id:</Td>
              <Td>{funded?.invoice_item_id || "-"}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Item Id:</Td>
              <Td>{funded?.item_id || "-"}</Td>
            </Tr>
          </Tbody>
        </Table>
      </GridItem>
    </>
  );
};

export default DetailFunded;
