import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";

const useMultipleAccountMutation = () => {
  const auth = useAuth();

  const { mutateAsync: Add } = useMutation((data) => {
    const newData = {
      accounts: data.accounts,
      public_id: data.public_id,
      invoice_id: +data.invoice_id,
      amember_user_id: +data.amember_user_id,
      target_account: data.target_account,
      provider: data.provider,
      rithmic_Id: data.rithmic_Id,
      cqg_Id: data.cqg_Id,
    };

    client.post(
      `${process.env.REACT_APP_API_HOST}/funded/new`,
      { ...newData },
      {
        method: "POST",
      }
    );
  });

  //
  const { mutateAsync: UpdateMultipleAccounts } = useMutation((data) => {
    const newData = Object.fromEntries(
      Object.entries(data)
        .filter(([key, value]) => key !== "id" && value !== "")
        .map(([key, value]) => {
          if (/^-?\d+$/.test(value)) {
            return [key, parseInt(value, 10)];
          }
          return [key, value];
        })
    );
    console.log(newData);

    client.put(
      `${process.env.REACT_APP_API_HOST}/funded/multiple-account/${data.id}`,
      { ...newData },
      {
        method: "PUT",
      }
    );
  });

  return { Add, UpdateMultipleAccounts };
};

export default useMultipleAccountMutation;
