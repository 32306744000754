import {
  Box,
  Flex,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Badge,
  useToast,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineFilter } from "react-icons/ai";
import { BiEdit, BiFilter, BiCheck, BiPlus } from "react-icons/bi";
// import {
//  } from "react-super-responsive-table";
import { colors } from "../../utils/colors";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  filterMultipleAccountSelector,
  filterMultipleAccountState,
} from "../../recoil";
import "../../../node_modules/react-super-responsive-table/dist/index";
import Select from "react-select";
import useAllMultipleAccountlists from "../../hooks/useQueries/useMultipleAccount";
import Pagination1 from "../../utils/pagination";
import useMultipleAccountMutation from "../../hooks/useMutations/useMultipleAccountMutation";
import UpdateMultipleAccount from "./UpdateMultipleAccount";
import AddMultipleAccount from "./AddMultipleAccount";
import { getResolvedStatus } from "../../utils/commen";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

const MultipleAccounts = () => {
  let toast = useToast();

  const [isFilter, setIsFilter] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { UpdateMultipleAccounts } = useMultipleAccountMutation();

  const [rithmicId, setRithmicId] = useState("");
  const [publicId, setPublicId] = useState("");
  const [cqg_Id, setCqgId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [targetAccount, setTargetAccount] = useState("");
  const [provider, setProvider] = useState("");
  const [multipleAccountId, setMultipleAccountId] = useState("");
  const [amemberUserId, setAmemberUserId] = useState("");
  const [resolved, setResolved] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const resolvedOptions = [
    { value: true, label: "Resolved" },
    { value: false, label: "UnResolved" },
  ];

  const query = useRecoilValue(filterMultipleAccountSelector);

  document.title = "TTTSubscriptions | Multiple Accounts List";

  const [filterMultipleAccountlist, setFilterMultipleAccountlist] =
    useRecoilState(filterMultipleAccountState);

  const { multipleAccounts, refetchMultipleAccountlists } =
    useAllMultipleAccountlists(query);

  useEffect(() => {
    return () => {
      setFilterMultipleAccountlist({
        public_id: "",
        invoice_id: "",
        amember_user_id: "",
        target_account: "",
        provider: "",
        rithmic_Id: "",
        resolved: undefined,
        cqg_Id: "",
        page: filterMultipleAccountlist.page,
        limit: filterMultipleAccountlist.limit,
      });
      refetchMultipleAccountlists();
    };
  }, []);

  const filterPayment = () => {
    return (
      <div>
        <div style={{ width: "100%", margin: "1rem 0" }}>
          <p>Resolved:</p>
          <Select
            options={resolvedOptions}
            defaultValue={resolved}
            onChange={(e) => {
              const val = e.valueOf();
              const arr = val["value"];
              console.log({ arr });
              setResolved(arr);
            }}
          />
        </div>
        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              width: "20%",
            }}
          >
            Public Id:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            type={"text"}
            name="publicId"
            value={publicId}
            onChange={(e) => {
              setPublicId(e.target.value);
            }}
          />

          <p
            style={{
              width: "20%",
            }}
          >
            Invoice Id:
          </p>
          <Input
            style={{
              margin: "0 1.5rem",
            }}
            name="userId"
            type={"text"}
            value={invoiceId}
            onChange={(e) => {
              setInvoiceId(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <p>Amember User Id:</p>

            <Input
              type={"text"}
              name="amemberUserId"
              value={amemberUserId}
              onChange={(e) => {
                setAmemberUserId(e.target.value);
              }}
            />
          </div>

          <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
            <p>Target Account:</p>
            <Input
              type={"text"}
              name="targetAccount"
              value={targetAccount}
              onChange={(e) => {
                setTargetAccount(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            margin: "1.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <p>Provider:</p>

            <Input
              type={"text"}
              name="provider"
              value={provider}
              onChange={(e) => {
                setProvider(e.target.value);
              }}
            />
          </div>

          <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
            <p>Rithmic Id:</p>
            <Input
              type={"text"}
              name="rithmicId"
              value={rithmicId}
              onChange={(e) => {
                setRithmicId(e.target.value);
              }}
            />
          </div>
          <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
            <p>CQG Id:</p>
            <Input
              type={"text"}
              name="cqg_Id"
              value={cqg_Id}
              onChange={(e) => {
                setCqgId(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Flex flexDir={"column"} justify={"space-evenly"}>
        <Box
          px="100px"
          py="40px"
          h="calc(100vh - 200px)"
          color="gray.50"
          overflow="auto"
        >
          <Flex align="end" justify="end" pb="40px">
            {/* <Button
              padding={"10px 30px"}
              mx="20px"
              rightIcon={<BiPlus size="20px" />}
              borderRadius={50}
              fontSize="12px"
              bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setIsNew(true);
                setIsFilter(false);
                setIsUpdate(false);
                onOpen();
              }}
            >
              Add
            </Button> */}
            <Button
              padding={"10px 30px"}
              mx="20px"
              rightIcon={<AiOutlineFilter size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              className="app_btn"
              boxShadow="lg"
              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setIsNew(false);
                setIsFilter(true);
                setIsUpdate(false);
                onOpen();
              }}
            >
              Filter
            </Button>
            <Button
              rightIcon={<AiOutlineEye size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              className="app_btn"
              boxShadow="lg"
              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setFilterMultipleAccountlist({
                  public_id: "",
                  invoice_id: "",
                  amember_user_id: "",
                  target_account: "",
                  provider: "",
                  rithmic_Id: "",
                  resolved: undefined,
                  cqg_Id: "",
                  page: filterMultipleAccountlist.page,
                  limit: filterMultipleAccountlist.limit,
                });
              }}
            >
              View all
            </Button>
          </Flex>

          <Box
            borderRadius={15}
            bgColor="gray.50"
            color="#000"
            overflow={`scroll`}
            maxH="550px"
          >
            {multipleAccounts?.docs?.length > 0 ? (
              <Table variant="striped">
                <Thead
                  fontSize="15px"
                  h="40px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="table__head"
                >
                  <Tr
                    textAlign="center"
                    className="table__head"
                    textTransform="capitalize"
                    h="30px"
                    borderBottomWidth={1}
                    borderRadius="20px"
                  >
                    <Th color="#fff" fontSize={12}>
                      Accounts
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Invoice
                    </Th>
                    {/* <Th color='#fff' fontSize=215}>Public Id</Th> */}
                    <Th color="#fff" fontSize={12}>
                      Amember User Id
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Target Account
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Provider
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Rithmic Id
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      CQG Id
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Resolved
                    </Th>

                    <Th color="#fff" fontSize={12}>
                      Action
                    </Th>
                  </Tr>
                </Thead>

                <Tbody
                  // bgGradient={`linear(to-l, gray.100, gray.300)`}
                  color="black"
                >
                  {multipleAccounts?.docs?.map((multipleAccount, i) => (
                    <Tr
                      textAlign="center"
                      fontSize={{
                        "2xl": "13px",
                        xl: "13px",
                        md: "12px",
                        lg: "12px",
                        sm: "10px",
                      }}
                      key={i}
                      // borderBottom="1px"
                      // borderColor={colors.lightBlue}
                      minH={"50px"}
                    >
                      <Td
                        style={{
                          //  display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {multipleAccount?.accounts?.map((account, i) => (
                          <Badge
                            key={i}
                            mx={1}
                            variant="solid"
                            colorScheme="green"
                          >
                            {account}
                          </Badge>
                        ))}
                      </Td>

                      <Td>
                        <Badge variant="outline" colorScheme="green">
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`${process.env.REACT_APP_TTT_AMEMBER_URL}${multipleAccount?.amember_user_id}#invoice-${multipleAccount?.invoice_id}`}
                          >
                            {`#${multipleAccount?.invoice_id}/${multipleAccount?.public_id}`}
                          </a>
                        </Badge>
                      </Td>
                      {/* <Td>{multipleAccount?.public_id} </Td> */}
                      <Td>{multipleAccount?.amember_user_id} </Td>
                      <Td>{multipleAccount?.target_account}</Td>
                      <Td>{multipleAccount?.provider} </Td>
                      <Td>{multipleAccount?.rithmic_Id}</Td>
                      <Td>{multipleAccount?.cqg_Id || "-"}</Td>
                      <Td>
                        <Badge
                          variant="outline"
                          colorScheme={`${
                            multipleAccount?.resolved ? "green" : "red"
                          }`}
                        >
                          {getResolvedStatus(multipleAccount?.resolved)}
                        </Badge>
                      </Td>

                      <Td>
                        {/* <Button
                          title="Edit Multiple Account"
                          onClick={() => {
                            setIsNew(false);
                            setIsFilter(false);
                            setIsUpdate(true);
                            setMultipleAccountId(multipleAccount?._id);
                            onOpen();
                          }}
                          bgColor="transparent"
                          color="green.600"
                          align="center"
                          px={0}
                          _hover={{ cursor: "pointer", color: "green.500" }}
                        >
                          <BiEdit size="20px" />
                        </Button> */}
                        {
                          <Button
                            disabled={multipleAccount?.resolved}
                            onClick={async () => {
                              if (multipleAccount?.resolved) return;
                              toast({
                                position: "top",
                                duration: 2.5 * 1000,
                                status: "loading",
                                render: () => (
                                  <Box
                                    bgColor="blue.400"
                                    borderRadius="5px"
                                    color="#f5f5f5"
                                    p="15px"
                                    textAlign="center"
                                  >
                                    <Spinner />
                                  </Box>
                                ),
                              });

                              const data = {
                                id: multipleAccount?._id,
                                resolved: true,
                              };
                              await UpdateMultipleAccounts(data);

                              await refetchMultipleAccountlists();

                              toast({
                                position: "top",
                                duration: 3 * 1000,
                                status: "loading",
                                render: () => (
                                  <Box
                                    bgColor="green.400"
                                    borderRadius="5px"
                                    color="#f5f5f5"
                                    p="15px"
                                    textAlign="center"
                                  >
                                    Done
                                  </Box>
                                ),
                              });
                            }}
                            title="Edit Multiple Account"
                            bgColor="transparent"
                            color="green.600"
                            align="center"
                            px={0}
                            _hover={{ cursor: "pointer", color: "green.500" }}
                          >
                            {!multipleAccount?.resolved ? (
                              <BiCheck size="20px" />
                            ) : (
                              "-"
                            )}
                          </Button>
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Flex
                borderRadius={15}
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color={colors.ocean}
                boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
                p="20px"
                h="500px"
                justify={`center`}
                align="center"
                fontSize={30}
              >
                No data to display !!
              </Flex>
            )}
          </Box>
        </Box>

        <Flex ml="7%">
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<BiFilter size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              className="app_btn"
              boxShadow="lg"
              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            >
              Per Page: {filterMultipleAccountlist.limit}
            </MenuButton>
            <MenuList color={colors.ocean} fontSize="14px">
              <MenuItem
                onClick={() =>
                  setFilterMultipleAccountlist({
                    ...filterMultipleAccountlist,
                    limit: 10,
                  })
                }
              >
                10
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setFilterMultipleAccountlist({
                    ...filterMultipleAccountlist,
                    limit: 15,
                  })
                }
              >
                15
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setFilterMultipleAccountlist({
                    ...filterMultipleAccountlist,
                    limit: 20,
                  })
                }
              >
                20
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setFilterMultipleAccountlist({
                    ...filterMultipleAccountlist,
                    limit: 25,
                  })
                }
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setFilterMultipleAccountlist({
                    ...filterMultipleAccountlist,
                    limit: 30,
                  })
                }
              >
                30
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setFilterMultipleAccountlist({
                    ...filterMultipleAccountlist,
                    limit: 35,
                  })
                }
              >
                35
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        {multipleAccounts?.totalPages && (
          <Pagination1
            pagesQuantity={multipleAccounts?.totalPages}
            curPage={filterMultipleAccountlist.page}
            setCurPage={(page) => {
              setFilterMultipleAccountlist({
                ...filterMultipleAccountlist,
                page,
              });
            }}
          />
        )}
        <Modal
          isOpen={isOpen}
          scrollBehavior={"inside"}
          size={{
            sm: "md",
            lg: "6xl",
          }}
          onClose={() => {
            onClose();
            setIsFilter(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color="gray.500">
              {isFilter && "Filters"}
              {isUpdate && "Update"}
              {isNew && "Add new"}
            </ModalHeader>
            <Divider style={{ borderBottom: "1.5px solid black" }} />
            <ModalCloseButton />
            <ModalBody h="100vh">
              <div>
                {isFilter && filterPayment()}
                {isNew && (
                  <AddMultipleAccount
                    close={() => {
                      onClose();
                    }}
                  />
                )}
                {isUpdate && (
                  <UpdateMultipleAccount
                    id={multipleAccountId}
                    close={async () => {
                      await refetchMultipleAccountlists();
                      onClose();
                    }}
                  />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              {isFilter && (
                <Button
                  colorScheme="blue"
                  className="app_btn"
                  mr={3}
                  onClick={() => {
                    console.log({
                      ...filterMultipleAccountlist,
                      public_id: publicId,
                      invoice_id: invoiceId,
                      amember_user_id: amemberUserId,
                      target_account: targetAccount,
                      provider: provider,
                      rithmic_Id: rithmicId,
                      resolved: resolved,
                      cqg_Id: cqg_Id,
                    });
                    setFilterMultipleAccountlist({
                      ...filterMultipleAccountlist,
                      public_id: publicId,
                      invoice_id: +invoiceId,
                      amember_user_id: +amemberUserId,
                      target_account: targetAccount,
                      provider: provider,
                      rithmic_Id: rithmicId,
                      resolved: resolved,
                      cqg_Id: cqg_Id,
                    });

                    setIsFilter(false);
                    onClose();
                  }}
                >
                  Apply
                </Button>
              )}

              {isFilter && (
                <Button
                  onClick={() => {
                    setRithmicId("");
                    setPublicId("");
                    setInvoiceId("");
                    setTargetAccount("");
                    setProvider("");
                    setAmemberUserId("");
                    setResolved(undefined);
                    setCqgId("");
                  }}
                >
                  Reset
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

export default MultipleAccounts;
