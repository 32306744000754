import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Input,
  Button,
  FormLabel,
  useDisclosure,
  VStack,
  Select,
  Box,
  Heading,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { colors } from "../../../utils/colors";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import useSubscriptions from "../../../hooks/useQueries/useSubscriptions";
import { BiEdit } from "react-icons/bi";
import { useAuth } from "../../../utils/auth";
import { client } from "../../../utils/axios";

const schema = yup.object().shape({
  /* fName: yup.string().required("First Name Required"),
    lName: yup.string().required("Last Name Required"), */
  accountName: yup.string().required("Account Name Required"),
  tradingSoftware: yup.string().required("Trading Software Required"),
});

export default function EditSubscription({ subscription }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [date, setDate] = useState(new Date());
  const toast = useToast();
  const { refetchSubscriptions } = useSubscriptions();
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      const editSub = {
        date: date.toISOString().split("T")[0],
        accountName: data.accountName,
        tradingSoftware: data.tradingSoftware,
      };

      await client.put(
        `${process.env.REACT_APP_API_HOST}/subscriptions/${subscription?._id}`,
        editSub,
        {
          method: "GET",
          // headers: {
          // "auth-token": auth.user.accessToken,
          // },
        }
      );

      await refetchSubscriptions();

      toast({
        position: "bottom-right",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="yellow.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="10px"
            textAlign="center"
          >
            <Heading fontSize="xs" textTransform="uppercase">
              Subscription edited succesfully !
            </Heading>
          </Box>
        ),
      });
      clearErrors();
      onClose();
    } catch (error) {
      console.dir(error);
    }
  };

  return (
    <>
      <Tooltip label="Edit" placement="auto">
        <Box
          color={colors.ocean}
          align="center"
          _hover={{ cursor: "pointer", color: colors.lightBlue }}
        >
          <BiEdit size="20px" onClick={onOpen} />
        </Box>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent borderRadius={15} _focus={{}}>
          <ModalHeader>Edit Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack align="stretch" spacing={5}>
                <FormControl w="100%">
                  <FormLabel>Account Name</FormLabel>
                  <Input
                    name="accountName"
                    {...register("accountName", { required: true })}
                    type="text"
                    placeholder="Enter account name here..."
                    defaultValue={subscription.accountName}
                    borderColor={colors.blue}
                    color={colors.ocean}
                    _focus={{ borderColor: colors.lightBlue }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Trading Software</FormLabel>
                  <Select
                    name="tradingSoftware"
                    {...register("tradingSoftware", { required: true })}
                    placeholder="Make your choice.."
                    borderColor={colors.blue}
                    _focus={{ borderColor: colors.lightBlue }}
                    _placeholder={{
                      opacity: 0.7,
                      color: "#ffff",
                      fontSize: 14,
                      fontStyle: "italic",
                    }}
                    defaultValue={subscription.tradingSoftware}
                  >
                    <option value="Bookmap">Bookmap</option>
                    <option value="Jigsaw">Jigsaw</option>
                    <option value="Ninja Trader">Ninja Trader</option>
                    <option value="Trading view">Trading view</option>
                  </Select>
                </FormControl>

                <FormControl borderColor={colors.blue}>
                  <FormLabel>Delivery Date</FormLabel>
                  <DatePicker
                    className="datepicker"
                    selected={date}
                    onChange={(date) => setDate(date)}
                    defaultValue={subscription.date}
                  />
                </FormControl>

                <Button
                  w="100%"
                  borderRadius={15}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, yellow.400, yellow.500)`}
                  boxShadow="lg"
                  className="app_btn"
                  color="#ffff"
                  // _hover={{
                  //   bgGradient: `linear(to-r, yellow.400, yellow.500)`,
                  // }}
                  type="submit"
                >
                  <BiEdit size="20px" /> &nbsp; Edit
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
