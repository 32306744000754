import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { colors } from "../../utils/colors";
import { BiFilter, BiSearch, BiTrash } from "react-icons/bi";
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useSubscriptions from "../../hooks/useQueries/useSubscriptions";
import EditSubscription from "./edit-subscription";
import { CSVLink } from "react-csv";
import useSubscriptionMutation from "../../hooks/useMutations/useSubscriptionMutation";
import { useRecoilValue, useRecoilState } from "recoil";
import Session from "supertokens-auth-react/recipe/session";
import {
  filterSubscriptionSelector,
  filterSubscriptionState,
} from "../../recoil";
import { useAuth } from "../../utils/auth";
import Pagination1 from "../../utils/pagination";
import axios from "axios";
import { client } from "../../utils/axios";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

const headers = [
  { label: "Account Name", key: "accountName" },
  { label: "Trading Software", key: "tradingSoftware" },
  { label: "Start Date", key: "systemDate" },
  { label: "Date", key: "date" },
];

export default function ListSubscriptions() {
  let auth = useAuth();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const query = useRecoilValue(filterSubscriptionSelector);
  const [filterSubscription, setFilterSubscription] = useRecoilState(
    filterSubscriptionState
  );
  const [accountName, setAccountName] = useState("");
  // let sessionContext = Session.useSessionContext();

  const { subscriptions } = useSubscriptions(
    query + `&page=${page}&limit=${limit}`
  );
  // const csvReport = {
  //   data: subscriptions?.docs?.map((e) => e),
  //   headers: headers,
  //   filename: `licences - ${new Date()}.csv`,
  // };

  const [csvReport, setSCVReport] = useState({ data: [] });
  // let csvReport = {};
  const [exportCheck, setExportCheck] = useState(false);
  const { deleteSubscription } = useSubscriptionMutation();
  const { refetchSubscriptions } = useSubscriptions();

  const clickRef = useRef();
  useEffect(() => {
    if (exportCheck)
      if (csvReport?.data.length > 0) {
        clickRef.current.link.click();
        setExportCheck(false);
      }
  }, [csvReport]);
  let sessionContext = Session.useSessionContext();
  const exportReport = async () => {
    setExportCheck(true);
    await client
      .get(`${process.env.REACT_APP_API_HOST}/subscriptions/all?${query}`, {
        // headers: {
        //   "auth-token": auth.user.accessToken,
        // },
      })
      .then((res) => {
        setSCVReport({
          data: res?.data?.docs?.map((e) => e),
          headers: headers,
          filename: `Bookmap Licenses - ${new Date()}.csv`,
        });
        // csvReport = {
        //   data: res?.data?.map((e) => e),
        //   headers: headers,
        //   filename: `Bookmap Licenses - ${new Date()}.csv`,
        // if (res.data.length > 0)
        // if (csvReport?.data.length > 0) clickRef.current.link.click();
        // };
      });
    // <CSVLink {...csvReport}>
    // clickRef.current = null;
    // setExportCheck(false);
    //   <button>Export CSV</button>
    // </CSVLink>;

    // const csvExporter = new CSVExporter();
    // csvExporter.export(csvReport.data); // Pass your data to the exporter
    // csvExporter.download("data.csv"); //
  };

  let toast = useToast();
  document.title = "TTTSubscriptions | Subscriptions List";

  return (
    <Flex flexDir={"column"} justify={"space-evenly"}>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 70px)"
        color="gray.50"
        overflow="auto"
      >
        <Flex align="center" justify="space-between" pb="40px">
          <Box w="20%">
            <Heading size="lg" color="black">
              Subscriptions
            </Heading>
          </Box>
          <Flex w="60%" justify="space-evenly" align="center">
            <Box w="70%">
              <Input
                type="text"
                placeholder="Search by account name..."
                // borderColor={colors.blue}
                borderColor="#0177D9"
                // color={colors.lightBlue}
                // _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.black,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </Box>
            <Box w="10%">
              <Button
                borderRadius={50}
                fontSize="12px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="app_btn"
                boxShadow="lg"
                color="#ffff"
                // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                isDisabled={!!accountName ? false : true}
                onClick={() => {
                  setFilterSubscription({
                    ...filterSubscription,
                    accountName: accountName,
                    tradingSoftware: ``,
                  });
                }}
              >
                <BiSearch size="15px" />
              </Button>
            </Box>
            <Box w="10%">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiFilter size="20px" />}
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="app_btn"
                  boxShadow="lg"
                  color="#ffff"
                  _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                >
                  Filter by
                </MenuButton>
                <MenuList color={colors.ocean} fontSize="14px">
                  <MenuItem
                    onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware: ``,
                      });
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware: `Bookmap`,
                      });
                    }}
                  >
                    Bookmap
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware: `Jigsaw`,
                      });
                    }}
                  >
                    Jigsaw
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware: `Ninja Trader`,
                      });
                    }}
                  >
                    Ninja Trader
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterSubscription({
                        ...filterSubscription,
                        accountName: ``,
                        tradingSoftware: `Trading view`,
                      });
                    }}
                  >
                    Trading view
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
          {/* {subscriptions?.totalDocs > 0 && ( */}
          <Box>
            {/* {!!csvReport.data && ( */}
            <Button
              onClick={() => exportReport()}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, green.400, green.500)`}
              className="app_btn"
              boxShadow="lg"
              color="#ffff"
              _hover={{
                bgGradient: `linear(to-r, green.400, green.500)`,
              }}
            >
              Export All
            </Button>
            {!!csvReport?.data.length > 0 && (
              <CSVLink ref={clickRef} {...csvReport}></CSVLink>
            )}
          </Box>
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px"
        >
          {subscriptions?.totalDocs > 0 ? (
            <Table borderRadius={20} variant="striped">
              <Thead
                fontSize="15px"
                h="40px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              >
                <Tr
                  textAlign="center"
                  textTransform="capitalize"
                  className="table__head"
                  h="30px"
                  borderBottomWidth={1}
                  borderRadius="20px"
                >
                  <Th color="white">Account Name</Th>
                  <Th color="white">Trading software</Th>
                  <Th color="white">Date</Th>
                  <Th color="white">Delivery Date</Th>
                  {sessionContext?.accessTokenPayload["st-role"].v.includes(
                    "MASTER"
                  ) && (
                    <Th color="white" textAlign="center">
                      Action
                    </Th>
                  )}
                </Tr>
              </Thead>

              <Tbody
              // bgGradient={`linear(to-l, gray.100, gray.300)`}
              // color={colors.ocean}
              >
                {subscriptions?.docs?.map((subscription, i) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={i}
                    // borderBottom="1px"
                    // bg={colors.lightGreen}
                    // variant="striped"
                    // colorScheme="teal"
                  >
                    <Td py="7px">{subscription.accountName}</Td>
                    <Td>{subscription.tradingSoftware} </Td>
                    <Td>{subscription.systemDate.slice(0, 10)}</Td>
                    <Td>{subscription.date.slice(0, 10)}</Td>
                    {sessionContext?.accessTokenPayload["st-role"]?.v.includes(
                      "MASTER"
                    ) && (
                      <Td>
                        <Flex justify="space-evenly" align="center">
                          <EditSubscription subscription={subscription} />

                          <Popover>
                            <PopoverTrigger>
                              <Button
                                bgColor="transparent"
                                color="red.600"
                                align="center"
                                _hover={{
                                  cursor: "pointer",
                                  color: "red.500",
                                }}
                              >
                                <BiTrash size="20px" />
                              </Button>
                            </PopoverTrigger>

                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader
                                fontSize="16px"
                                color="red.500"
                                fontWeight={`bold`}
                              >
                                Confirmation!
                              </PopoverHeader>
                              <PopoverBody>
                                <Text size="12px" pb="10px">
                                  Are you sure you want to delete this
                                  subscription?
                                </Text>
                                <Button
                                  size="sm"
                                  bgGradient={`linear(to-b, red.300, red.500)`}
                                  color="gray.50"
                                  _hover={{
                                    bgGradient: `linear(to-t, red.300, red.500)`,
                                  }}
                                  boxShadow="lg"
                                  onClick={async () => {
                                    try {
                                      await deleteSubscription(
                                        subscription?._id
                                      );
                                      await refetchSubscriptions();
                                      toast({
                                        position: "bottom",
                                        duration: 5 * 1000,
                                        status: "success",
                                        render: () => (
                                          <Box
                                            bgColor="red.500"
                                            borderRadius="5px"
                                            color="#f5f5f5"
                                            p="10px"
                                            px="10px"
                                            textAlign="center"
                                          >
                                            <Heading fontSize="sm">
                                              Subscription deleted !!
                                            </Heading>
                                          </Box>
                                        ),
                                      });
                                    } catch (error) {
                                      console.dir("error", error);
                                    }
                                  }}
                                >
                                  Yes, I'm sure
                                </Button>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Flex>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}
            >
              No data to display !!
            </Flex>
          )}
        </Box>
      </Box>
      <Pagination1
        pagesQuantity={subscriptions?.totalPages}
        curPage={page}
        setCurPage={setPage}
      />
    </Flex>
  );
}
