import { useState, useRef, useEffect } from "react";
import {
  Flex,
  Button,
  Box,
  useToast,
  Heading,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { client } from "../../utils/axios";
import { CSVLink } from "react-csv";
import Papa from "papaparse";

const headers = [
  { label: "Account name", key: "name" },
  { label: "first Name", key: "user.name_f" },
  { label: "Last Name", key: "user.name_l" },
  { label: "Email", key: "user.email" },
  { label: "Phone", key: "user.phone" },
  { label: "UserId", key: "user.user_id" },
  { label: "Tradovate ID", key: "user.tradovate_id" },
];

const ActiveAccount = () => {
  const [csvReport, setSCVReport] = useState({ data: [] });
  // let csvReport = {};
  const [exportCheck, setExportCheck] = useState(false);
  const [importDone, setImportDone] = useState(false);
  const toast = useToast();
  //   const [exportCheck, setExportCheck] = useState(false);
  const clickRef = useRef();
  useEffect(() => {
    if (exportCheck)
      if (csvReport?.data.length > 0) {
        clickRef.current.link.click();
        setExportCheck(false);
      }
  }, [csvReport]);

  const exportReport = async () => {
    setExportCheck(true);
    await client
      .get(`${process.env.REACT_APP_TRADOVATE_URL}/getActiveAccounts/`, {
        headers: {
          "x-api-key": process.env.REACT_APP_TV_API_KEY,
        },
      })
      .then((res) => {
        setSCVReport({
          data: res?.data?.map((e) => e),
          headers: headers,
          filename: `Bookmap Licenses - ${new Date()}.csv`,
        });
        // csvReport = {
        //   data: res?.data?.map((e) => e),
        //   headers: headers,
        //   filename: `Bookmap Licenses - ${new Date()}.csv`,
        // if (res.data.length > 0)
        // if (csvReport?.data.length > 0) clickRef.current.link.click();
        // };
      });
    // <CSVLink {...csvReport}>
    // clickRef.current = null;
    // setExportCheck(false);
    //   <button>Export CSV</button>
    // </CSVLink>;

    // const csvExporter = new CSVExporter();
    // csvExporter.export(csvReport.data); // Pass your data to the exporter
    // csvExporter.download("data.csv"); //
  };

  const exportData = (data, filename, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const importData = async () => {
    setImportDone(true);
    toast({
      position: "top",
      duration: 5 * 1000,
      status: "info",
      render: () => (
        <Box
          bgColor="blue.400"
          borderRadius="5px"
          color="#f5f5f5"
          p="20px"
          textAlign="center">
          <Heading fontSize="sm" textTransform="uppercase">
            Active Account Data is being imported, Please Wait it will take some
            minutes
          </Heading>
        </Box>
      ),
    });

    client
      .get(`${process.env.REACT_APP_TRADOVATE_URL}/getActiveAccounts/`, {
        headers: {
          "x-api-key": process.env.REACT_APP_TV_API_KEY,
        },
      })
      .then((res) => {
        setImportDone(false);
        // const csvData = Papa.unparse(res.data.data);

        let csvData1 = res.data.data.map((data) => {
          return {
            "Account name": data.name,
            "first Name": data.user.name_f,
            "Last Name": data.user.name_l,
            Email: data.user.email,
            Phone: data.user.phone,
            UserId: data.user.user_id,
            "Tradovate ID": data.user.tradovate_id,
          };
        });
        let csvData2 = Papa.unparse(csvData1);
        exportData(csvData2, "ActiveAccount.csv", "text/csv;charset=utf-8;");
        toast({
          position: "top",
          duration: 5 * 1000,
          status: "success",
          render: () => (
            <Box
              bgColor="green.400"
              borderRadius="5px"
              color="#f5f5f5"
              p="20px"
              textAlign="center">
              <Heading fontSize="sm" textTransform="uppercase">
                Active Accounts Data is imported
              </Heading>
            </Box>
          ),
        });
      });

    // console.log("res import = ", res);
    // // refetchJounalytixList();
    // setSCVReport({
    //   data: res?.data?.data?.map((e) => e),
    //   headers: headers,
    //   filename: `Active Accounts - ${new Date()}.csv`,
    // });
    // setImportDone(false);
    // if (res.status === 200)
    //   toast({
    //     position: "top",
    //     duration: 5 * 1000,
    //     status: "success",
    //     render: () => (
    //       <Box
    //         bgColor="green.400"
    //         borderRadius="5px"
    //         color="#f5f5f5"
    //         p="20px"
    //         textAlign="center">
    //         <Heading fontSize="sm" textTransform="uppercase">
    //           Active Accounts Data is imported
    //         </Heading>
    //       </Box>
    //     ),
    //   });
  };
  return (
    <>
      <Flex
        w={"full"}
        h={"full"}
        flexDir={"column"}
        justifyContent={"start"}
        alignItems={"center"}>
        <Flex
          h={"10%"}
          w={"full"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Heading>Tradovate Active Accounts</Heading>
        </Flex>
        <Flex
          w={"full"}
          h={"90%"}
          px={"5%"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Button
            onClick={() => importData()}
            disabled={importDone}
            borderRadius={50}
            h={"5%"}
            fontSize="24px"
            // bgGradient={`linear(to-b, green.400, green.500)`}
            className="app_btn"
            boxShadow="lg"
            color="#ffff"
            // _hover={{ bgGradient: `linear(to-r, green.400, green.500)` }}
            w="100%">
            {importDone ? (
              <>
                <Flex alignItems={"center"} gap={3}>
                  <Text>Please wait</Text>
                  <Spinner />
                </Flex>
              </>
            ) : (
              <Text>Import Accounts </Text>
            )}
            {/* {!!csvReport?.data.length > 0 && (
              <CSVLink ref={clickRef} {...csvReport}></CSVLink>
            )} */}
          </Button>
        </Flex>
        {/* <Box>
         
          <Button
            onClick={() => exportReport()}
            borderRadius={50}
            fontSize="12px"
         
            className="app_btn"
            boxShadow="lg"
            color="#ffff"
            _hover={{
              bgGradient: `linear(to-r, green.400, green.500)`,
            }}>
            Export All
          </Button>
          {!!csvReport?.data.length > 0 && (
            <CSVLink ref={clickRef} {...csvReport}></CSVLink>
          )}
        </Box> */}
      </Flex>
    </>
  );
};

export default ActiveAccount;
